import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Store } from 'src/@types/store_forms';

interface StoreState {
  store: Store | null;
}

const initialState: StoreState = {
  store: null,
};

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStore: (state, action: PayloadAction<Store>) => {
      state.store = action.payload;
    },
  },
});

export const { setStore } = storeSlice.actions;

export default storeSlice.reducer;
