// ----------------------------------------------------------------------

import { title } from "process";

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to I.Dpro@gmail.com.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2: `menu level 2`,
  menu_level_3: `menu level 3`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  create_event: 'Create an event',
  label_Rows_Per_Page: 'Rows per page',
  created_At: 'Created at',
  location: 'Location',
  title: 'title',
  events: 'Events',
  archived: 'Archived',
  canceled: 'canceled',
  Start_Date: 'Event start date',
  End_Date: 'Event end date',
  organisation: 'Organisation',
  organisations: 'Organisations',
  published_events: 'Published events',
  Add_event: 'Add Event',
  event_name: 'Event Name',
  summary: 'Summary',
  cancel: 'Cancel',
  add: 'Add',
  view_event: 'View Event ',
  event_title: 'Event Title',
  cover: 'Cover',
  cover_image: 'Cover Image',
  category: 'Category',
  sub_category: 'Sub Category',
  status: 'Status',
  listed: 'Listed',
  online_event: 'Online Event',
  tags: 'Tags',
  address: 'Address',
  city: 'City',
  capacity: 'Capacity',
  save: 'Save',
  Start_Time: 'Event start Time',
  End_Time: 'Event end Time',
  timezone: 'Timezone',
  Save_Changes: 'Save Changes',
  Quantity: 'Quantity',
  Initial_Quantity: 'Available Quantity',
  Max_Quantity: 'Max per order',
  price: 'Price',
  free: 'Free',
  Sell_Time_Limit: 'Sell Time Limit',
  delete: 'Delete',
  Edit: 'Edit',
  Add_Ticket: 'Add Ticket',
  Sell_limit: 'Sell limit',
  start: 'Start',
  end: 'End',
  Access_Time_Limit: 'Event access time',
  Price_Quantity: 'Price and Quantity',
  Access_limit: 'Access limit',
  name: 'Name',
  Include_Platform_Fees: "Include Platform Fees",
  Final_Price: "Final Price",
  Platform_Fee_Info: "A 2.5% platform fee will be added to the ticket price",
  Your_event_is_already_published_on_this_link: 'Your event is already published on this link',

  archive: 'Archive',
  Event_Dashboard: 'Event Dashboard',
  Preview_Event: 'Preview Event',
  publish: 'Publish',
  Email_Message: 'Text of the email sent to your event participants',
  Ticket_Message: 'Ticket Message',
  include: 'Include',
  required: 'Required',
  Gender: 'Gender',
  Birthday: 'Birthday',
  HomePhone: 'Home Phone',
  yourEventIs: 'Your event is ',
  date: 'Date',
  events_catering: 'Event and Catering',
  events_catering_description:
    'ID is a platform that allows your customers to buy tickets to attend your events, as well as order their food and drinks digitally at your establishment',
  create_digital_exp: 'Create your digital experience',
  create_digital_exp_description:
    'Customers interact with your brand, your way; you control your digital presence to maximize revenue and profitability.',
  digital_ticketing: 'DIGITAL TICKETING',
  digital_ticketing_title: 'Add your events and sell tickets on our platform.',
  digital_ticketing_description:
    'Each of your clients wishing to participate can click on a link or scan a QR code on the digital or physical flyer of your event; this will direct them to the ID platform where they can select and pay for their ticket.',
  more_questions: 'Do you still have questions?',
  more_questions_description: 'Please describe your case to receive the most accurate advice.',
  who_are_we: {
    who: 'Who',
    are: 'are',
    we: 'we',
    work: 'Working together for',
    futur: 'a better future in events and catering',
  },
  what_is_id: {
    title: 'What is ID?',
    description:
      'ID is a platform that allows your customers to buy tickets to attend your events, as well as order their food and drinks digitally at your establishment.',
  },
  about: {
    vision: 'Our vision: To offer the perfect platform for a better future in events and catering.',
    dream_team: 'DREAM TEAM',
    dream_team_description:
      'D will provide you with assistance if you encounter any problems. Our support team will respond .A good team is the key. Within a day, and we also have detailed documentation.',
  },
  testimonials: {
    title: 'TESTIMONIALS',
    who: 'Who likes',
    service: ' our service?',
    description:
      "Our goal is to create the perfect platform that will satisfy all our customers in events and catering. That's why we are constantly working on our services to improve them every day by listening to the valuable suggestions that our users share with us.",
  },
  Min_Quantity: 'Min per order',
  save_continue: 'Save and continue',
  continue_later: "Don't save and continue later",
  Link_to_buy_tickets_for_your_event: 'Link to buy tickets for your event',
  back: 'Back',
  Complete_the_details_of_the_event_to_be_able_to_publish_it:
    'Complete the details of the event to be able to publish it',
  publish_the_event: 'Publish the event',
  view: 'View',
  ready_events: 'Ready events',
  drafted_events: 'Drafted events',
  archived_events: 'Archived events',
  canceled_events: 'canceled events',
  no_events: 'No events',
  my_events: 'My events',
  total_sold: 'Total revenue',
  sold_quantity: 'Number of tickets sold',
  event_link: 'Event link',
  sale_date: 'Sale date',
  validated: 'Validated',
  confirmed: 'Confirmed',
  reimbursed: 'Reimbursed',
  About: 'About',
  Name: 'Name',
  promoters: 'Promoboosters',
  add_promoter: 'Add PromoBooster',
  choose_promoter: 'Choose PromoBooster',
  remove: 'Remove',
  min_followers: 'Minimun followers',
  Dashboard: 'Dashboard',
  General: 'General',
  Preview: 'Preview',
  required_information: 'Required information',
  promo_code: 'Promo codes',
  overview: 'Overview',
  preview_your_event: 'Preview your event',
  Owner: 'Owner',
  create_org: 'Create an organisation',
  Account: 'Account',
  account_settings: 'Account settings',
  Allowed_format: 'Allowed formats',
  max_size: 'max size of',
  billing: 'billing',
  Email_Address: 'Email address',
  Phone_Number: 'Phone number',
  Country: 'Country',
  State_Region: 'State/Region',
  Zip_Code: 'Zip/Code',
  discount: 'Discount',
  maximum_number_of_sold_tickets: 'maximum number of sold tickets',
  Featured_tickets: 'Featured tickets',
  No_promo_code_available: 'No promo code available',
  add_promo_code: 'Add promo code',
  This_code_is_already_used: 'This code is already used!',
  discount_amount: 'Discount amount',
  advanced_options: 'Advanced options',
  all_tickets: 'All tickets',
  specific_tickets: 'Specific tickets',
  Legal_info: 'Legal information',
  Followers: 'Followers',
  Total_events: 'Total events',
  Recommended: 'Recommended',
  company_name: 'Company name',
  company_number: 'Company number',
  Website: 'Website',
  Cover: 'Cover',
  add_organisation: 'Add organisation',
  create_user: 'create user',
  users: 'users',
  firstname: 'First Name',
  lastname: 'Last Name',
  role: 'Role',
  access: 'access',
  events_permissions: 'Events permissions',
  organisations_permissions: 'Organisations permissions',
  Yes: 'Yes',
  No: 'No',
  Permissions: 'Permissions',
  Editing: 'Editing',
  Roles: 'Roles',
  Search: 'Search',
  Event_stats: 'Event stats',
  sales: 'sales',
  Number_of_purchase: 'Number of purchases',
  Link: 'Link',
  sold: 'sold',
  available: 'available',
  Tickets_status: 'Tickets status',
  There_is_no_tickets_status_for_this_event: 'There is no tickets status for this event',
  promoter: 'PromoBooster',
  invite_user: 'Invite user',
  published: 'Published',
  ready: 'Ready',
  draft: 'Draft',
  initial: 'initial',
  upcoming: 'upcoming',
  ongoing: 'ongoing',
  past: 'past',
  Organisation: 'Organisation',
  Bio: 'Bio',
  Type: 'Type',
  Evenementiel: 'Events',
  Restaurant: 'Restaurant',
  Email: 'Email',
  Rajoutez_une_image_de_couverture_pour_votre_événement: 'Add a cover image for your event',
  Upload_photo: 'Upload photo',
  Update_photo: 'Update photo',
  organisation_users: 'Organisation users',
  Agent_liés_à_lorganisation: 'Agents related to the organization',
  Agents_en_attente_de_confirmation: 'Agents awaiting confirmation',
  Instadrink_admin: 'I.D Admin',
  create_role: 'Create a role',
  Predefined_roles: 'Predefined roles',
  Custom_roles: 'Custom roles',
  Agents: 'Agents',
  Organizer: 'Organizer',
  Bouncer: 'Bouncer',
  Ambassador: 'Ambassador',
  promotion_groups: 'PromoBoosters groups',
  Dates: 'Dates',
  Tickets: 'Tickets',
  Messages: 'Messages',
  Promotion: 'Promotion',
  Participants: 'Participants',
  Client: 'Client',
  description_placeholder:
    'Describe to your future participants the exceptional atmosphere that will prevail at your event.',
  Tickets_collection: 'Tickets collection',
  Stop_sell_time_is_at: 'Stop sell time is at',
  drag_and_drop: 'drag and drop to change the order',
  delete_ticket_confirmation: 'Are you sure you want to delete this ticket?',
  Access_Time_Thresholdt: 'Event access end time',
  order: 'order',
  Créez_les_tickets_pour_votre_événement: 'Create tickets for your event',
  stop_sell_time_error_message:
    'End date must be later than start date and not exceed the stop sell date',
  Reset: 'Reset',
  send_invitation: 'Send invitation',
  Tickets_quantity_error_message: 'places are remaining',
  Write_something_awesome: 'Write something awesome...',
  Code: 'Code',
  delete_promocode_confirm_message: 'Are you sure you want to delete this code?',
  Activate_promotion: 'Activate promotion',
  Desactivate_promotion: 'Disable promotion',
  Configure_RP_offer: 'Configure the PromoBooster offer',
  promotion_status: 'promotion status',
  TooltipTextForPromotionGroups:
    'In this section you can create and manage Resource Persons (RPs) groups for your events',
  TooltipTextForEventLink: 'Publish your event to get your event link',
  TooltipTextForPromotionGroupsForReferral:
    'In this section, you will find the PromoBooster groups that you are a member of, and you can leave the group whenever you want.',
  confirm_delete_promoter: 'Are you sure you want to delete this PromoBooster?',
  Preview_promoter_link: "Preview PromoBooster's link",
  Locked: 'Locked',
  pending: 'pending',
  accepted: 'accepted',
  rejected: 'rejected',
  Publish_my_event_now: 'Publish my event now',
  publish_later: 'Publish later',
  Your_event_is_still_draft:
    'Your event is still a draft, complete the configuration to publish it',
  Are_you_sure_to_publish_the_event: 'Are you sure to publish the event  ',
  pourcentage: 'pourcentage',
  Rate: 'Rate',
  Message: 'Message',
  submit: 'submit',
  Create_group: 'Create group',
  Groups: 'Groups',
  Edit_group: 'Edit group',
  Search_by_Name: 'Search by name',
  Enter_group_name: 'Enter group name',
  No_promoters_found: 'No promoters found',
  Add_group: 'Add group',
  Number_of_sales: 'Number of sales',
  Turnover: 'Turnover',
  Number_of_clients: 'Number of clients',
  Promotion_requests: 'Promotion requests',
  Requests: 'Requests',
  Notifications: 'Notifications',
  Referral_requests: 'PromoBooster requests',
  no_requests: 'There is no requests',
  request_message: 'sent you a request to invite you to be the PromoBooster of',
  Accept: 'Accept',
  Reject: 'Reject',
  Your_invitation_to: 'Your invitation to',
  on: 'on',
  at: 'at',
  is: 'is',
  promotion_rate: 'promotion rate',
  consigne: 'order',
  Cancel_request: 'Cancel request',
  Send_request: 'Send request',
  No_events: 'Find an event you would like to collaborate as an PromoBooster',
  Search_for_new_events: 'Search for new events',
  Search_for_Events: 'Search for events',
  Sales_stats: 'Sales stats',
  Total_sales: 'Total sales',
  Total_reservation: 'Total reservations',
  Bienvenue_message: 'Welcome to I.D, choose your domain',
  second_bienvenu_message:
    'Welcome to I.D, select your industry between events and catering.',
  Continue: 'Continue',
  Event: 'Event',
  orga_message: 'Choose the organization under which you will create your event',
  Skip: 'Skip',
  All_steps_completed: 'All steps completed',
  you_have_finished: 'you have finished',
  limit_sell_message: 'Enter a limit date and time for the tickets sale',
  stopSellDate: 'Tickets stop sell date',
  status_tooltip:
    'This status field calculate automatically the the start date and the end date and give you the event status',
  Step: 'Step',
  correct_start_date: 'Correct the past start date',
  Finish: 'Finish',
  event_name_tooltip: 'What is the name of your event?',
  event_summary_tooltip:
    'Give a glimpse of the beautiful surprises awaiting future participants at your event.',
  event_description_tooltip:
    'This time, describe in detail the event that future participants will have the chance to attend.',
  event_cover_tooltip: 'Choose a photo for your event.',
  organisation_tooltip: 'Choose the name of the organization that will host your event.',
  category_tooltip: 'Select a category for your event.',
  subCategory_tooltip: 'Choose a sub-category for your event.',
  Rp_request: 'sent you request to be the PromoBooster of his event',
  warning_message: 'Please press "add" first to save your ticket and continue',
  correct_date_tooltip: 'The selected start date for your event has already passed.',
  step1: 'In this step, you will find the event sales stats and the attendees list for the event.',
  step2: 'In this step, you can edit the event details.',
  step3: 'In this step, you can edit the event dates and times.',
  step4: 'In this step, you can add new tickets for your event or edit the existing tickets.',
  step5: 'In this step, you can publish or archive your event.',
  step6: 'In this step, you find the event view for clients in the desktop view or mobile view.',
  step7: 'In this step, you will find the overview of your event details.',
  step8: 'In this step, you can information about the event.',
  stepCodePromo: 'In this step, you can add promo codes for the tickets.',
  step10:
    'In this step, you can manage the RPs for your event, create a group of RPs, or send requests for the PromoBooster to be a PromoBooster of your event.',
  step11: 'In this step, you will find the list of attendees.',
  total_sold_tooltip: 'This is the total sale price for the event tickets',
  sold_quantity_tooltip: 'This is the total sale quantity for event the tickets',
  link_tooltip: 'This is the event link to purchase your tickets.',
  table_tooltip: 'Here is the table where you find the list of the attendees for this event.',
  create_event_button_tooltip: 'Click here to create a new event.',
  events_table_tooltip: 'This is the events table where you can find your events details.',
  You_have_no_organisations: 'You have no organisations',
  alert_orga_title: 'You have to create an organisation so you can create an event after.',
  Configurate_here: 'Configurate here',
  legal_verification: 'Legal verification of your account',
  alert_stripe_configuration_title:
    'Please complete your legal information to activate your account and be able to sell your tickets.',
  Bank_balance: 'Bank balance',
  View_my_Stripe_account: 'View my Stripe account',
  Your_balance: 'Your balance',
  Add_new_card: 'Add new card',
  Name_on_card: 'Name on card',
  Card_number: 'Card number',
  Expiration_date: 'Expiration date',
  QRcode_tooltip: 'Click here to customize and download the QR code of your event',
  File_Type: 'File type',
  Size: 'Size',
  Color: 'Color',
  Background_Color: 'Background color',
  Download_QR_Code: 'Download QR Code',
  Download_Invoice: 'Download invoice',
  show_qr_code: 'Show QR code',
  Close: 'Close',
  Customize_QR_Code: 'Customize QR Code',
  no_users_yet: 'No users yet',
  plz_invite_users: 'Please invite users',
  invite_organizer: 'Invite organizer',
  invite: 'invite',
  settings: 'settings',
  invite_owners: 'invite owners',
  register: 'Register',
  password: 'Password',
  confirm_password: 'Confirm Password',
  email_adress: 'Email Address',
  division_agent: 'Agent',
  division_promoter: 'Promoter',
  iam_owner: 'I am an Owner',
  iam_owner_desc: 'Manage your organizations, restaurants, and events',
  iam_amb: 'I am an Ambassador',
  iam_amb_desc: 'Promote organizations and earn commissions',
  iam_ref: 'I am an PromoBooster',
  iam_ref_desc: 'Refer events and earn commissions',
  start_free: 'Start for Free',
  no_credit_card: 'No Credit Card Required',
  have_account: 'Already have an account?',
  by_register_1: 'By registering, I agree to I.D ',
  by_register_2: 'Terms of Service',
  by_register_3: ' and ',
  by_register_4: 'Privacy Policy.',
  manager_better_register: 'Manage events more efficiently with I.D',
  referral: 'PromoBooster',
  first_name_required: 'First name required',
  first_name_short: 'First name is too short - should be 3 chars minimum.',
  first_name_long: 'First name is too long - should be 50 chars maximum.',
  first_name_alpha: 'First name can only contain alphabetic characters.',
  last_name_required: 'Last name required',
  last_name_short: 'Last name is too short - should be 3 chars minimum.',
  last_name_long: 'Last name is too long - should be 50 chars maximum.',
  last_name_alpha: 'Last name can only contain alphabetic characters.',
  email_invalid: 'Email must be a valid email address',
  email_required: 'Email is required',
  password_required: 'Password is required',
  password_short: 'Password is too short - should be 8 chars minimum.',
  password_alpha: 'Password can only contain Latin letters.',
  password_uppercase: 'Password must contain at least one uppercase letter.',
  password_number: 'Password must contain a number.',
  confirm_password_match: 'Passwords must match',
  confirm_password_required: 'Confirm Password is required',
  division_required: 'Division is required',
  division_invalid: 'Division must be either Promoter or Agent',
  role_required: 'Role is required',
  role_invalid_promoter: 'Invalid role for Promoter',
  role_invalid_agent: 'Invalid role for Agent',
  login: 'Login',
  fr: 'Français',
  en: 'English',
  es: 'Espagnol',
  pt: 'Portugais',
  lang: 'Language',
  creating_account: 'Account Creation in Progress',
  creating_account_desc: 'Finalizing Your Account Setup',
  creating_account_info:
    'Your account is currently being set up. Please remain on this page until the process is complete. We appreciate your patience!',
  accept_invitation_info:
    'Please note, after accepting the invitation below, you will receive a verification email. Following the verification, you will be able to complete your account setup, including the options to change your name and preferred language.',
  invitation_already_processed: "This invitation has already been processed. Please check your events dashboard for more information.",
  verify_email: 'Verify Your Email',
  verify_email_desc: 'Account Successfully Created',
  account_created_check_email:
    'Account created successfully. Please check your email to verify your account and gain full access.',
  thanks_join_invite: 'Thank you for joining our community!',
  verify_before_expire: 'Verify your mail before it expires',
  verify_email_info:
    'Congratulations on setting up your account! Please check your email inbox to verify your account and gain full access.',
  wait_verify: 'Please wait a moment while we verify your email.',
  wait_redirect: 'Do not close this window until you have been redirected to the dashboard.',
  initialAccountHeader: 'Finalize Your I.D Account',
  initialAccountDescription:
    'Welcome back! Just set your password below to finalize your account setup.',
  initialAccountButton: 'Complete Setup',
  processingAccountHeader: 'Setting Up Your Account...',
  processingAccountDescription:
    'Please wait a moment while we confirm your details and secure your account.',
  processingAccountButton: 'Completing...',
  successAccountHeader: 'Success! Account Ready',
  successAccountDescription:
    "Your account has been successfully set up, and you're being signed in. You will be redirected to the dashboard shortly.",
  successAccountButton: 'Redirecting...',
  errorAccountHeader: 'Something Went Wrong',
  errorAccountDescription:
    'There was an issue with your account setup. The link may be invalid or expired, or the email address may be incorrect.',
  errorAccountButton: 'Try Again',
  linkexpiredAccountHeader: 'Check Your Email',
  linkexpiredAccountDescription: "There was a problem with setting up your account. The link might be invalid or expired, or the email address could be incorrect.",
  verification_expired: "Account setup failed! The verification link for your account has expired.",
  new_verification_link_sent: "A new verification link has been sent to your email address.",
  login_welcome: 'Hi, Welcome',
  login_connect: 'Log in to I.D',
  login_type: 'Enter your details below.',
  login_no_account: "Don't have an account?",
  login_register: 'Sign Up',
  owner_total_part: 'This is the owner shares for the event tickets sales.',
  my_share: 'My share',
  Remember_password: 'Remember password',
  forgot_password: 'Forgot your password?',
  create_your_first_organization: 'Create your first organization',
  create_organization_step_description:
    'To start managing your events. Create your first organization. You can create as many organizations as you want later.',
  create_your_first_event: 'Create your first event',
  create_event_step_description:
    "Now that you've created your first organization, it's time to briefly create your first event. You can edit it in detail later.",
  congratulations: 'Congratulations !',
  last_step_description: 'You can now navigate to the events list and continue creating your event',
  create_your_first_restaurant: 'Create your first restaurant',
  create_restaurant_step_description:
    'To start managing your tables. Create your first restaurant. You can create as many restaurants as you want later.',
  create_restaurant_last_step_description:
    'You can now navigate to the restaurant list and continue creating your tables',
  Logout: 'Logout',
  Settings: 'Settings',
  create_store: 'Create store',
  add_category: 'Add Category',
  add_new_product: 'Add new product',
  add_new_category: 'Add new category',
  products: 'Products',
  categories: 'Categoies',
  unavailable: 'Unavailable',
  composite: 'Composite',
  alcoholic: 'Alcoholic',
  Available: 'Available',
  Description: 'Description',
  simple: 'Simple',
  search_product: 'Search product...',
  min_price: 'Minimum price',
  max_price: 'Maximum price',
  add_product: 'Add product',
  edit_product: 'Edit product',
  product_details: 'Product details',
  composition_items: 'Composition items',
  caption: 'Caption',
  extra: 'Extra',
  max_quantity: 'Max quantity',
  add_composition: 'Add composition',
  items: 'Items',
  item_name: 'item name',
  add_item: 'Add item',
  preparation_time: 'Preparation Time',
  Orders: 'Orders',
  current_orders: 'Current Orders',
  store: 'Store',
  no_stores_message: 'You have no stores to see orders, please create one first',
  Pending: 'Pending',
  in_preparation: 'In Preparation',
  Preparation: 'Preparation',
  accept_order: 'Accept order',
  order_is_ready: 'Order is ready',
  finish_order: 'Finish order',
  total: 'Total',
  order_of: 'Order of',
  Contact: 'Contact',
  reservations_shares: 'Reservations shares',
  client_name: 'Client name',
  total_amount: 'Total amount',
  owner_share: 'Owner share',
  instadrink_share: 'I.D share',
  rp_share: 'PromoBooster share',
  ambassador_share: 'Ambassador share',
  shares_distribution: 'Shares distribution',
  group_name: 'Group name',
  group_members: 'Group members',
  created_by: 'Created by',
  leave_group: 'Leave group',
  with_product: 'With product',
  all_categories: 'All categories',
  inclusives: 'Inclusives',
  closing_Time: 'closing time',
  open: 'Open',
  Rush_hour: 'Rush hour',
  preview_store: 'Preview store',
  Delete_account: 'Delete account',
  Are_you_sure_you_want_to_delete_your_account: 'Are you sure you want to delete your account',
  delete_account_page_message:
    ' By choosing to delete your account, you are opting for the permanent removal of all your data from our system.',
  restaurants: 'Restaurants',
  network: 'Network',
  stores: 'Stores',
  commandes: 'Commandes',
  applications: 'Applications',
  inviteUsers: 'Invite Users',
  inviteAgents: 'Invite Agents',
  inviteStaff: 'Invite Staff',
  invitationsList: 'Invitations list',
  promotersTree: 'Invitations tree',
  promotionGroups: 'PromoBoosters Groups',
  onboarding: 'Onboarding',
  if_mistake_contact_instadrink: 'If you have a mistake, please contact I.D',
  invitation_rejected: 'Invitation rejected',
  invite_owner: 'I want to invite a owner',
  invite_referrer: 'I want to invite a PromoBooster',
  invite_ambassador: 'I want to invite an ambassador',
  your_organisation_staff: 'Your organisation staff',
  inv_org_desc: 'The organizer of your event',
  inv_bnc_desc: 'The access controller of your event',
  invite_more_staff: 'Invite more staff',
  since: 'Since',
  delete_event_modal: 'Are you sure to delete the event',
  publish_event_modal: 'Are you sure to publish the event',
  cancel_event_modal: 'Are you sure to cancel the event',
  archive_event_modal: 'Are you sure to archive the event',
  duplicate_event: 'Duplicate event',
  duplicate: 'Duplicate',
  reservations: 'Reservations',
  multiple_persons: 'Multiple persons',
  persons_number: 'Number of persons',
  vatRate: 'VAT rate',
  price_TTC: 'Price including tax',
  Occupation: 'Occupation',
  priceHt: 'Price excluding tax',
  promobooster_mode: 'Promobooster mode',
  promo_mode: 'Promo mode',
  Amount: 'Amount',
  Percentage: 'Percentage',
  include_owners: 'Include owners',
  add_anonymous_promoter: 'Add SpyBooster', 
  Confirm_Name: 'Confirm name',
  Add_Anonymous_Name: 'Add SpyBooster', 
  ask_for_invoice: 'Ask for invoice',
  add_invoice_button: 'Add  ',
  enter_valid_invoice: 'Please enter a valid invoice',   
  Compan_Name_Last_Name :'Company/Name/Last Name',
  tva :'TVA',
  adresse :'address',
  postal_code :'Postal Code ', 
  country :'Country',
  additional_information :'Additional information', 
  invoice_information :'Billing',
  survey_information :'Survey',
  know_from_invoice :'What information do you require from your invoice?',
  know_from_attendees :'What do you need to know from your attendees?', 
  add_invoice: 'Add new field',
  active_invoice: 'Active the Invoice',
  actived_invoice: ' The Invoice is Activated',
  preview_your_share: 'Preview  distribution ',
  Number_of_event: 'Number of event', 
  time_of_creation: 'Creat at', 
  Owner_details: 'Owner details', 
  greetings: 'Greetings,',
  you_have_been_invited: 'You have been invited to join I.D 🎉',
  plz_wait_a_moment: 'Pleas wait a moment',
  thanks_time: 'Thanks time',
  averagePreparationTime: 'Average preparation time',
  minutes: 'minutes',
  seconds: 'seconds',
  select_menu: 'Select a menu',
  capacity_warning_message: 'The capacities are depleted in comparison to the total capacity.',
  add_product_alert: 'To add product to your tickets please link a store to your event',
  remaining_places: 'remaining places',
  navigate_to_event_menu: 'Navigate to event menu',
  you_have_no_store: 'You have no store',
  reject_order: 'Reject order',
  add_Waiter: 'Add Waiter',
  add_room: "Add Room",
  room_name: "Room Name",
  "campaigns": {
    "title": "Mail Campaigns",
    segment: "Segment",
    "audience": "Audience",
    "createNew": "Create New Campaign",
    "name": "Name",
    "template": "Template",
    "scheduledTime": "Scheduled Time",
    "status": "Status",
    "actions": "Actions",
    "backToList": "Back to Campaigns List",
    "info": "Campaign Info",
    "mails": "Mails",
    "email": "Email",
    "emailStatus": "Email Status",
    "confirmDeletion": "Confirm Deletion",
    "deleteConfirmationMessage": "Are you sure you want to delete this mail campaign? This action cannot be undone.",
    "cancel": "Cancel",
    "delete": "Delete",
    "edit": "Edit Campaign",
    "create": "Create New Campaign",
    "campaignName": "Campaign Name",
    "selectTemplate": "Select a template",
    "createNewTemplate": "Create new template",
    "save": "Save",
    "audienceType": {
      "title": "Audience",
      "all": "All Contacts",
      "segment": "Specific Segment",
      "eventBuyers": "Event Ticket Buyers"
    },  
    "emailstatus": {
      "email.sent": "Sent",
      "email.delivered": "Delivered",
      "email.delivery_delayed": "Delivery Delayed",
      "email.complained": "Complaint Received",
      "email.bounced": "Bounced",
      "email.opened": "Opened",
      "email.clicked": "Clicked",
      "email.unsubscribed": "Unsubscribed",
      "email.failed": "Failed to Send",
      "email.blocked": "Blocked",
      "email.dropped": "Dropped",
      "email.deferred": "Deferred",
      "email.rejected": "Rejected"
    
  },  
    "drawer": {
      "create": "Create Campaign",
      "edit": "Edit Campaign",
      "name": "Campaign Name",
      "audienceType": "Audience Type",
      "segment": "Select Segment",
      "draftInfo": "Campaign will be saved as draft"
    },
    campaignStatus: {
      "pending": "Pending",
      "scheduled": "Scheduled",
      "sending": "Sending",
      "launched": "Sent",
      "failed": "Failed",
      "template-ready": "Template Ready"
    },
    "stats": {
      "title": "Mail Statistics",
      "delivered": "Delivered",
      "opened": "Opened",
      "clicked": "Clicked",
      "bounced": "Bounced"
    },
    "subject": "Email Subject",
    "subjectHelperText": "You can customize the template subject for this campaign",
    "launchType": {
      title: "Launch Type",
      send: "Send Now",
      "immediate": "Launch immediately",
      "scheduled": "Schedule for later"
    },
    "schedule": {
      action: "Schedule",
      "title": "Schedule Campaign",
      "info":  "Schedule sending for campaign: {{name}}",
      scheduledTimeHelper: "Choose a date and time in the future to send your campaign",
      "errors": {
        "scheduleTimeRequired": "Please select a schedule time",
        "testEmailRequired": "Please enter a test email address",
        "scheduleFailed": "Failed to schedule campaign",
        "testSendFailed": "Failed to send test email",
      },
      "success": {
        "testSent": "Test email sent successfully",
        "launched":  "Campaign launched successfully",
        "scheduled": "Campaign scheduled successfully",
      }
    }
  },  
  "Breakdown_Price": "Breakdown Price",
  "Ticket_Price": "Ticket Price",
  "Platform_Fee": "Platform Fee",
  "Service_Fees": "Service Fees",
  "Per_Ticket": "per ticket",  
  "Total": "Total",
  Cost_to_buyers: "Cost to buyers",
  "contacts": {
    "import": "Import contacts",
    "export": "Export to CSV",
    "deleteSelected": "Delete selected",
    "search": "Search",
    "noContactsToExport": "No contacts to export.",
    "errorDeletingContacts": "Error deleting contacts",
    "errorLoadingContacts": "Error loading contacts",
    "confirmDeletion": "Confirm deletion",
    "confirmDeletionMessage": "Are you sure you want to delete the {count} selected contacts?",
    "cancel": "Cancel",
    "delete": "Delete",
    "dragAndDropInstruction": "Drag and drop a CSV or XLSX file here, or click to select",
    "file": "File: {name}",
    "removeFile": "Remove file",
    "informationMapping": "Information mapping",
    "select": "Select",
    "additionalFields": "Additional fields",
    "errorParsingFile": "Error parsing file. Please try again.",
    "contactsImportedSuccessfully": "Contacts imported successfully",
    "errorImportingContacts": "Error importing contacts. Please try again.",
    "fields": {
      "email": "Email",
      "firstName": "First Name",
      "lastName": "Last Name",
      "phone": "Phone",
      "age": "Age",
      "gender": "Gender",
      "country": "Country",
      "zone": "Zone",
      "postalCode": "Postal Code"
    }
  },
  "no_confirmed_tickets_to_export": "No confirmed tickets to export",
  "export_confirmed_ticket_ids_tooltip": "Export confirmed ticket IDs",
  "loading": "Loading...",
  "export_confirmed_tickets": "Export Confirmed Tickets ({{count}})",
  check_invitation_list: "Check invitation list",
  "segments": {
      "create": {
        engagement: "Engagement",
        "title": "Create New Segment",
        "selectType": "Select a segment type",
        "dynamicSegment": {
          "title": "Dynamic Filter Segment",
          "description": "Apply a list of attributes to select a portion of your contacts. Your contacts will be automatically added or removed from this segment based on the chosen filters."
        },
        "manualSegment": {
          "title": "Manual Segment via CSV",
          "description": "You can import a list of contacts from a CSV file to group them into a segment. You can use this feature to re-import existing contacts as a new segment."
        },
        "step": "Step",
        "of": "of",
        removeFile: "Remove file",
        "newsletterContacts": "{{count}} / 2000 contacts subscribed to your newsletter already imported.",
        "selectAgeRange": "Select the age range you want to filter:",
        "averageAge": "Average: {{age}} years",
        "infoKnownFor": "This information is known for {{count}} contacts",
        "selectCountries": "Select one or more countries",
        "selectZones": "Select zones",
        "addPostalCodes": "Add postal codes (separated by comma)",
        "selectLanguage": "Select language",
        "whenAdded": "When this contact was added to your community",
        "contactsImported": "contacts subscribed to your newsletter already imported.",
        "dropFileHere": "Drop your CSV, XLS or XLSX file here",
        "fileRequirements": "Your file must have at least one column with the email address of the contacts. And to send them newsletters, it must have a newsletter subscription column (yes/no).",
        "removeFilters": "Remove filters",
        "contactInformation": "CONTACT INFORMATION",
        "marketing": "MARKETING",
        "history": "HISTORY",
        "fieldMapping": "Field Mapping",
        "additionalFields": "Additional Fields",
        "segmentName": "Segment Name",
        "description": "Description",
        "matchingContacts": "matching contacts",
        "back": "Back",
        "cancel": "Cancel",
        "next": "Next",
        "createSegment": "Create Segment",
        "importContacts": "Import contacts as segment"
      },
      "fieldsMapping": {
          "name": "Name",
          "email": "Email",
          "firstName": "First name",
          "lastName": "Last name",
          "phone": "Phone",
          "age": "Age",
          "gender": "Gender",
          "country": "Country",
          "zone": "Zone",
          "postalCode": "Postal code"
        },
      "filters": {
        activeFilters: "Active filters",
        "hasEmail": "Has an email",
        "emailVerified": "Email verified",
        "hasPhone": "Has a phone number",
        "age": {
          "rangeInfo": "Age between {{min}} and {{max}} years",
          title: "Age",
        },
        "gender": "Gender",
        "genderSelection": "Gender: {{genders}}",
        "location": "Location",
        "language": "Language",
        "dateAdded": "Date Added",
        "selectCountries": "Select one or more countries",
        "selectZones": "Select zones",
        "postalCodes": "Add postal codes (separated by commas)",
        "selectLanguage": "Select language",
        "allTime": "All time",
        "today": "Today",
        "yesterday": "Yesterday",
        "last7days": "Last 7 days",
        "last14days": "Last 14 days",
        "last30days": "Last 30 days",
        "last90days": "Last 90 days",
        "customDates": "Custom dates",
        "from": "From",
        "to": "To",
        "events": {
        "title": "Events & Tickets",
        "hasBoughtTicket": "Has bought tickets",
        "hasAttendedEvents": "Has attended events",
        "minEvents": "Minimum events attended",
        "ticketInfo": "Has bought at least one ticket",
        "attendanceInfo": "Has attended at least {{count}} events",
        "attendanceRange": "Events attended",
        "selectRange": "Select the minimum number of events attended"
      }
      }
    },
    "stripe_connect": {
    "setup_stripe_account": "Set up Stripe Account",
    "complete_stripe_setup": "Complete Stripe Setup",
    "alert_stripe_incomplete_setup": "Your Stripe account setup is incomplete. Please complete the verification process to start receiving payments for your events.",
    "error_creating_stripe_account": "Error creating Stripe account. Please try again later.",
    "error_creating_stripe_link": "Error generating Stripe setup link. Please try again later.",
    "legal_verification": "Legal Verification Required",
    "alert_stripe_configuration_title": "To receive payments for your events, you need to set up your Stripe Connect account. This is a mandatory step for legal compliance and secure payment processing."
  },
  "segment": {
      "Create": {
        "matchingContacts": "matching contacts",
        "back": "Back",
        "cancel": "Cancel",
        "next": "Next",
        "importContacts": "Import contacts",
        "title": "Create segment"
      }
    },
  
  "survey": {
    "title": "Event Survey Creator",
    "enable": "Enable Survey",
    "noResponses": "No responses yet",
    "totalResponses": "Total Responses: {{count}}",
    "noSurveyData": "No survey data available",
    "errorLoading": "Error loading survey data",
    "question": {
      "title": "Question {{number}}",
      "required": "Required",
      "text": "Question Text",
      "type": "Question Type",
      "types": {
        "shortText": "Short Text",
        "longText": "Long Text",
        "radio": "Radio Buttons",
        "checkbox": "Checkboxes"
      },
      "options": "Options:",
      "addOption": "Add Option",
      "submit": "Submit Question",
      "validation": {
        "emptyText": "Question text cannot be empty.",
        "radioOptions": "Radio question must have at least one option.",
        "checkboxOptions": "Checkbox question must have at least one option."
      }
    },
    "addQuestion": "Add Question",
    "answersSummary": "Answers Summary"
  },
  guests: {
    guestLists: 'Guest Lists',
    addList: 'Add List',
    addGuest: 'Add Guest',
    searchGuests: 'Search guests',
    addNewList: 'Add New List',
    listName: 'List Name',
    guestList: 'Guest List',
    sendEmailsToNewGuests: 'Send emails to new guests',
    single: 'Single',
    import: 'Import',
    text: 'Text',
    name: 'Name',
    email: 'Email',
    totalTickets: 'Total Tickets',
    freeTickets: 'Free Tickets',
    dragDropCsv: 'Drag and drop a CSV file here, or click to select a file',
    guestDetailsPlaceholder: 'Enter guest details (one per line): Name, TotalTickets+FreeTickets, Email',
    importGuests: 'Import Guests',
    editGuest: 'Edit Guest',
    update: 'Update',
    delete: 'Delete',
    confirmDelete: 'Confirm Delete',
    confirmDeleteMessage: 'Are you sure you want to delete this guest? This action cannot be undone.',
    cancel: 'Cancel',
    mapColumns: 'Map Columns',
    emailLabel: 'Email',
    ticketsInfo: 'Tickets',
    deleteList: "Delete Guest List",
    deleteListConfirmation: "Are you sure you want to delete the guest list: ",
    deleteListWarning: "This will also delete all guests in this list and cannot be undone.",
    "invalidFileType": "Invalid file type. Please upload a CSV or Excel file (.csv, .xlsx, .xls)"
  },  
  promoboosters: {
    "referral_sales": "Referral Sales",
    "other_sales": "Other Sales",
    "available": "Available",
    "No_tickets_sold_yet": "No tickets sold yet",
    "ticket_category": "Ticket Category",
    "total_sold": "Total Sold",
    "initial_quantity": "Initial Quantity"
  },
  "templates": {
    "createNew": "Create New Template",
    "name": "Template Name",
    "subject": "Email Subject",
    "nameAndSubjectRequired": "Template name and subject are required",
    "createSuccess": "Template created successfully",
    "createError": "Failed to create template"
  },  
  "confirm_refund": "Confirm Refund",
  "refund_confirmation_message": "Are you sure you want to refund this ticket? This action cannot be undone.",
  "refund_amount": "Refund Amount",
  "processing": "Processing...",
  "only_confirmed_tickets_can_be_refunded": "Only confirmed tickets can be refunded",
  "refund_ticket": "Refund ticket",
  send_confirmation_email: "Send Confirmation Email",
  can_only_send_email_for_confirmed_or_validated: "Can only send email for confirmed or validated tickets",
  "deduct_platform_fee": "Deduct Platform Fee",
    "platform_fee_toggle_description": "When enabled, the platform fee will be deducted from the refund amount",
  "original_price": "Original Price",
  "platform_fee": "Platform Fee",
  "platform_fee_deduction": "Platform Fee Deduction",
  "final_refund_amount": "Final Refund Amount",
  'Price Deformations': 'Price Deformations',
  'Active Price Deformations': 'Active Price Deformations',
  'Add Deformation': 'Add Deformation',
  'Remove Deformation': 'Remove Deformation',
  'Deformed Price': 'Deformed Price',
  'Final Price': 'Final Price',
  'No deformations available': 'No price deformations available',
  'Price deformation added': 'Price deformation added successfully',
  'Price deformation removed': 'Price deformation removed',
  'Price Deformations Management': 'Price Deformations Management',
  'Add Price Deformation': 'Add Price Deformation',
  'No price deformations defined yet': 'No price deformations defined yet',
  'Inactive': 'Inactive',
  'off': 'off',
  'Priority': 'Priority',
  'Domains': 'Domains',
  'Are you sure you want to delete this price deformation?': 'Are you sure you want to delete this price deformation?',
  'Price deformation deleted successfully': 'Price deformation deleted successfully',
  'Failed to delete price deformation': 'Failed to delete price deformation',
  "Ticket Restrictions": "Ticket Restrictions",
    "Add Access Restriction": "Add Access Restriction",
    "Access Restriction": "Access Restriction",
    "Allowed Domains": "Allowed Domains",
    "Restriction Rules": "Restriction Rules",
    "A ticket can have either multiple price deformations OR one access restriction, but not both.": "A ticket can have either multiple price deformations OR one access restriction, but not both.",
    "Adding an access restriction will remove all price deformations. Continue?": "Adding an access restriction will remove all price deformations. Continue?",
    "Access_Restriction_Description": "Access to tickets can be limited to specific email domains.",
    total_tickets_sold: 'Total tickets sold',
    total_revenue: 'Total revenue',
    eventTicketStatistics: 'Event Ticket Statistics',
    eventCapacity: 'Event Capacity',
    totalTickets: 'Total Tickets',
    totalReservations: 'Total Reservations',
    ticketName: 'Ticket Name',
    initialQuantity: 'Initial Quantity',
    soldQuantity: 'Sold Quantity',
    noTicketData: 'No ticket data available',
    errorLoadingTickets: 'Error loading ticket data',
    na: 'N/A',
    "confirm_send_email": "Confirm Email Send",
    "send_email_confirmation_message": "Are you sure you want to send the confirmation email to this recipient?",
    "recipient": "Recipient",
    "sending": "Sending...",
    "send": "Send",
    "ticketInvitation": {
    "import": {
      csvMode: "Import CSV",
      singleMode: "Import Individual",
      "title": "Import Invitations",
      select_ticket_category: "Select Ticket Category",
      "progress": "Processing {{current}}/{{total}} ({{success}} successful, {{failed}} failed)",
      "dragOrSelect": "Drop your CSV file here or click to select",
      "dropHere": "Drop the CSV file here",
      "mapColumns": "Map CSV Columns",
      "previewTitle": "Preview",
      "records": "records",
      "firstThreeRecords": "First three records",
      "importButton": "Import {{count}} invitations",
      "errors": {
        "unknown": "Unknown error occurred",
        "csvEmpty": "The CSV file is empty",
        "invalidFormat": "Invalid CSV format",
        "parseError": "Error parsing CSV file",
        "fileTooLarge": "File is too large (max 5MB)",
        "invalidFileType": "Only CSV files are allowed",
        "invalidDataRows": "{{count}} rows contain invalid data",
        "missingMapping": "Please map all required columns",
        "creationFailed": "Failed to create invitations"
      },
      "success": {
        "partial": "Import completed: {{success}} successful, {{failed}} failed.\nDetails:\n{{details}}",
        "complete": "Successfully created {{count}} invitations"
      },
      "steps": {
        "setup": "Setup",
        "preview": "Preview & Import"
      },
      "next": "Next",
      "back": "Back",
      "summary": "Import Summary",
      "totalRecords": "Total Records",
      "selectedTicket": "Selected Ticket Category",
      "explanation": {
        "main": "Import tickets for multiple attendees using a CSV file. Perfect for migrating from other ticketing systems or creating tickets for a pre-defined guest list.",
        "features": "This import tool will:",
        "feature1": "Create digital tickets with unique QR codes for each attendee",
        "feature2": "Send confirmation emails to attendees with their ticket QR codes",
        "feature3": "Allow you to assign specific ticket categories to all imported guests"
      }
    }
  },
  "tickets": {
    "validation": {
      "titleRequired": "Title is required",
      "descriptionRequired": "Description is required",
      "priceZero": "Price cannot be zero",
      "priceRequired": "Price is required",
      "initialQuantityZero": "Initial quantity cannot be zero",
      "maxQuantityLimit": "Max quantity must be smaller than initial quantity",
      "maxQuantityZero": "Max quantity cannot be zero",
      "minQuantityLimit": "Min quantity must be smaller than max quantity and initial quantity",
      "minQuantityZero": "Min quantity cannot be zero",
      "categoryRequired": "Category is required",
      "productRequired": "Product is required",
      "quantityRequired": "Quantity is required",
      "quantityGreaterThanZero": "Quantity must be greater than zero",
      "withProductRequired": "With product is required when iscomposite is true",
      "fieldRequired": "This field is required",
      "vatRateRange": "VAT rate must be between 1 and 100"
    }
  },
  "accessRequests": {
    "request_access": "Request Access",
    "request_access_title": "Request Admin Access",
    "full_name": "Full Name",
    "email": "Email",
    "company_name": "Company Name",
    "position": "Position",
    "reason": "Why do you need access?",
    "cancel": "Cancel",
    "submit": "Submit Request",
    "submitting": "Submitting...",
    "please_fill_all_fields": "Please fill in all required fields",
    "invalid_email": "Please enter a valid email address",
    "error_submitting_request": "Error submitting request. Please try again.",
    "phone": "Phone Number",
    "phone_optional": "Optional - Include country code",
    "company_optional": "Optional",
    "position_optional": "Optional",
    "source": "How did you find us?",
    "invalid_phone": "Please enter a valid phone number",
    "source_options": {
      "search_engine": "Search Engine (Google, Bing, etc.)",
      "social_media": "Social Media",
      "friend": "Friend or Colleague",
      "advertisement": "Advertisement",
      "other": "Other"
    }
  }
};

export default en;
