import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';
import useSettings from 'src/hooks/useSettings';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const { themeMode } = useSettings();

  const logo = (
    <Box sx={{ width: 70, height: 70, ...sx }}>
      <img
        src={
          themeMode === 'light'
            ? '/assets/icons/home/BLUE_LOGO_INSTADRINK_WITHOUT_D.png'
            : '/assets/icons/home/LOGO_INSTADRINK_WITHOUT_D.png'
        }
        alt="logo"
      />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
