import { codesToPermissions } from "./constants";
import { Pcode, Permission } from "./types";

export const checkExistingRoles = (role: string, existingRoles: string[]): boolean =>
{
  const lowercaseRole = role.toLowerCase();
  return existingRoles.some((existingRole) => existingRole.toLowerCase() === lowercaseRole);
};

// function to get the list of permissions from the list of pcodes
export const getPermissionsFromPcodes = (pcodes: Pcode[]): Permission[] => pcodes.map((pcode) => codesToPermissions[pcode]);
