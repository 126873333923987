import { configureStore, Middleware } from '@reduxjs/toolkit';
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { EventsApi } from './api/events.api';
import { AdminApi } from './api/admin.api';
import { rootPersistConfig, rootReducer } from './rootReducer';
import { MenusApi } from './api/menus.api';
import { UsersApi } from './api/users.api';
import { ContactsApi } from './api/contacts.api';
import { ReservationsApi } from './api/reservations.api';
import { SurveysApi } from './api/surveys.api';
import { EventCategoriesApi } from './api/eventCatygory.api';

// ----------------------------------------------------------------------

const middlewares: Middleware[] = [
  EventsApi.middleware,
  AdminApi.middleware,
  MenusApi.middleware,
  UsersApi.middleware,
  ContactsApi.middleware,
  SurveysApi.middleware,
  ReservationsApi.middleware,
  EventCategoriesApi.middleware,
];

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV !== 'production',
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();

const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { store, persistor, dispatch, useSelector, useDispatch };
