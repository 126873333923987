// @mui
import { alpha } from '@mui/material/styles';
// components
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
import useSettings from 'src/hooks/useSettings';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ThemePopover() {
  const { themeMode, onToggleMode } = useSettings();

  return (
    <>
      <IconButtonAnimate
        color="default"
        onClick={onToggleMode}
        sx={{
          width: 40,
          height: 40,
          ...{
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          },
        }}
      >
        <Iconify
          icon={themeMode === 'light' ? 'eva:sun-fill' : 'eva:moon-fill'}
          width={20}
          height={20}
        />
      </IconButtonAnimate>
    </>
  );
}
