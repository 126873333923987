import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { doc, getDoc, setDoc, getDocs, query, where, collection } from 'firebase/firestore';
import { DB, FUNCTIONS } from 'src/contexts/FirebaseContext';
import { httpsCallable } from 'firebase/functions';

import { Survey, SurveyAnswer } from 'src/@types/survey';

export const SurveysApi = createApi({
  reducerPath: 'SurveysApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Survey', 'SurveyAnswers'],
  endpoints: (builder) => ({
    getSurvey: builder.query<Survey, string>({
      async queryFn(eventId) {
        try {
          const surveyDoc = await getDoc(doc(DB, 'events', eventId));
          const surveyData = surveyDoc.data()?.survey as Survey | undefined;
          console.log(surveyData)
          return { data: surveyData || { enabled: false, questions: [] } };
        } catch (error: any) {
          console.log(error);
          return { error: error.message };
        }
      },
      providesTags: ['Survey'],
    }),
    updateSurvey: builder.mutation<void, { eventId: string; survey: Survey }>({
      async queryFn({ eventId, survey }) {
        try {
          await setDoc(doc(DB, 'events', eventId), { survey }, { merge: true });
          return { data: undefined };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['Survey'],
    }),
    getSurveyAnswers: builder.query<SurveyAnswer[], string>({
      queryFn: async (eventId) => {
        try {
          const reservationsRef = collection(DB, 'reservations');
          const q = query(reservationsRef, where('event.id', '==', eventId));
          const querySnapshot = await getDocs(q);
          console.log("eventid", eventId)
          
          const surveyAnswers: SurveyAnswer[] = [];
          querySnapshot.forEach((doc) => {
            const reservation = doc.data();
            if (reservation.surveyAnswers) {
              // Convert map to array of SurveyAnswer objects
              Object.entries(reservation.surveyAnswers).forEach(([index, answer]) => {
                surveyAnswers.push({
                  questionIndex: parseInt(index, 10),
                  answer: answer as string | string[]
                });
              });
            }
          });
          console.log("surveyAnswers", surveyAnswers);
          
          // Sort the answers by questionIndex
          surveyAnswers.sort((a, b) => a.questionIndex - b.questionIndex);
          
          
          return { data: surveyAnswers };
        } catch (error) {
          return { error: { message: 'Failed to fetch survey answers' } };
        }
      },
      providesTags: ['SurveyAnswers'],
    }),
  }),
});

export const { useGetSurveyQuery, useUpdateSurveyMutation, useGetSurveyAnswersQuery } = SurveysApi;