import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Button, Radio } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH, PATH_PAGE } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { t } from 'i18next';
import { useNavigate } from 'react-router';
import { Organisation } from 'src/@types/events_org';
import Avatar from 'src/components/Avatar';
import { Icon } from '@iconify/react';
import useGlobalOrg from 'src/hooks/useGlobalOrg';

// ----------------------------------------------------------------------

type Props = {
  orgsData: Organisation[] | undefined;
};

export default function AccountPopover({ orgsData }: Props) {
  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const { orgId, onChangeOrgId, onRemoveOrgId } = useGlobalOrg(orgsData);

  const [showAllOrgs, setShowAllOrgs] = useState(false);

  const MENU_OPTIONS = [
    {
      label: t('Settings'),
      linkTo: PATH_DASHBOARD.user.account,
    },
  ];

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });
      // onRemoveOrgId();

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleDeleteAccount = () => {
    navigate(PATH_PAGE.deleteAccount);
  };

  const sortedOrgsData = [...(orgsData || [])].sort((a, b) => {
    if (a.id === orgId) return -1;
    if (b.id === orgId) return 1;
    return 0;
  });

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
          width: 250,
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Box
          mb={2}
          sx={{
            maxHeight: 350,
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              borderRadius: '4px',
              border: '2px solid transparent',
              backgroundClip: 'content-box',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            },
            scrollbarWidth: 'thin',
            scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
          }}
        >
          {sortedOrgsData.slice(0, showAllOrgs ? sortedOrgsData.length : 2).map((org) => (
            <Box
              key={org.id}
              m={1}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Box display={'flex'} alignItems={'center'} gap={1}>
                <Avatar alt={org.name} src={org.logoImage} sx={{ width: 24, height: 24 }} />
                <Typography>{org.name}</Typography>
              </Box>
              <Radio
                checked={orgId === org.id}
                onChange={(e) => {
                  onChangeOrgId(e);
                  handleClose();
                }}
                value={org.id}
              />
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
            mb: 2,
          }}
        >
          {orgsData && orgsData.length > 2 && (
            <Button variant="outlined" color="inherit" onClick={() => setShowAllOrgs(!showAllOrgs)}>
              <span>
                {showAllOrgs ? t('Show Less') : t('Show More')}
                <Icon
                  icon={showAllOrgs ? 'bxs:up-arrow' : 'bxs:down-arrow'}
                  style={{ marginLeft: 10 }}
                />
              </span>
            </Button>
          )}
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            handleLogout();
            onRemoveOrgId();
          }}
          sx={{ m: 1 }}
        >
          {t('Logout')}
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleDeleteAccount} sx={{ m: 1, color: '#FF4842' }}>
          {t('Delete_account')}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
