// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Iconify from '../../../components/Iconify';
import { Box } from '@mui/material';
import { Pcode } from 'src/guards/admin/types';
import { navConfigProps } from 'src/components/nav-section/type';
import Contact from 'src/pages/Contact';

// ----------------------------------------------------------------------

const getIcon = (name: string, color?: string) => (
  <Iconify icon={name} sx={{ width: 1, height: 1, color }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  contacts: getIcon('typcn:contacts'),
  user: getIcon('icomoon-free:users'),
  campaigns: getIcon('iconoir:send-mail-solid'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('gg:organisation'),
  booking: getIcon('ic:baseline-event'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('uil:calender'),
  events: getIcon('ic_events'),
  event: getIcon('bi:calendar-event-fill'),
  ecommerce: getIcon('icons8:buy'),
  analytics: getIcon('ic_analytics'),
  analytics2: getIcon('grommet-icons:analytics'),
  dashboard: getIcon('clarity:dashboard-line'),  
  menuItem: getIcon('ic_menu_item'),
  Organisations: getIcon('ic_organisations'),
  stores: getIcon('map:store'),
  apps: getIcon('icon-park-outline:all-application'),
  myEvents: getIcon('mdi:favorite-box-outline'),
  billing: getIcon('ic:round-receipt'),
  bill: getIcon('ph:invoice-bold'),
  menu: getIcon('medical-icon:restaurant'),
  restaurant: getIcon('carbon:restaurant-fine'),
  restaurantb: getIcon('heroicons:building-storefront-solid'),
  shares: getIcon(
    'streamline:money-graph-arrow-increase-ascend-growth-up-arrow-stats-graph-right-grow'
  ),
  network: getIcon('ph:user-square-fill'),
  network2: getIcon('clarity:network-globe-solid-badged'),
  account: getIcon('mdi:account-group'),
  profile: getIcon('ic:round-account-box'),
  settings: getIcon('eva:settings-2-fill'),
  invite: getIcon('mingcute:invite-fill'),
  invitelist: getIcon('fluent:task-list-square-person-20-filled'),
  staff: getIcon('clarity:employee-group-solid'),
  promoters: getIcon('ph:network-fill'),
  onboarding: getIcon('streamline:startup-solid'),
  mails: getIcon('lucide:mails'),
  menus: getIcon('mdi:restaurant-menu'),
  tables: getIcon('material-symbols:table-restaurant-sharp'),
};

const navConfigEvent: navConfigProps[] = [
  {
    subheader: 'Evenementiel',
    subheaderIcon: ICONS.event,
    items: [
      {
        title: 'organisations',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: ICONS.banking,
        allRequiredPermissionList: ['C01'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'events',
        path: PATH_DASHBOARD.evenementiel.events,
        icon: ICONS.booking,
        allRequiredPermissionList: ['E01'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'my_events',
        path: PATH_DASHBOARD.evenementiel.ambassadorEvents,
        icon: ICONS.myEvents,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P02'],
        greenPassRoleList: ['Ambassador'],
      },
      {
        title: 'my_events',
        path: PATH_DASHBOARD.evenementiel.eventsList,
        icon: ICONS.myEvents,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['A01'],
        greenPassRoleList: ['Referral', 'Owner'],
      },
    ],
    allRequiredPermissionList: [],
    atLeastOneRequiredPermissionList: ['P00', 'A00', 'E01'],
    greenPassPermissionList: [],
    greenPassRoleList: [],
  },
  // {
  //   subheader: 'Restauration',
  //   subheaderIcon: ICONS.restaurant,
  //   items: [
  //     {
  //       title: 'restaurants',
  //       path: PATH_DASHBOARD.restauration.restaurants,
  //       icon: ICONS.restaurantb,
  //       allRequiredPermissionList: ['C01'],
  //       atLeastOneRequiredPermissionList: [],
  //       greenPassPermissionList: ['P01'],
  //       greenPassRoleList: ['Owner'],
  //     },
  //     {
  //       title: 'Menus',
  //       path: PATH_DASHBOARD.restauration.menusList,
  //       icon: ICONS.menus,
  //       allRequiredPermissionList: [],
  //       atLeastOneRequiredPermissionList: [],
  //       greenPassPermissionList: ['P01'],
  //       greenPassRoleList: ['Owner'],
  //     },
  //     {
  //       title: 'commandes',
  //       path: PATH_DASHBOARD.restauration.commandes,
  //       icon: ICONS.ecommerce,
  //       allRequiredPermissionList: [],
  //       atLeastOneRequiredPermissionList: [],
  //       greenPassPermissionList: ['P01'],
  //       greenPassRoleList: ['Owner'],
  //     },
  //     {
  //       title: 'applications',
  //       path: PATH_DASHBOARD.restauration.applications,
  //       icon: ICONS.apps,
  //       allRequiredPermissionList: [],
  //       atLeastOneRequiredPermissionList: [],
  //       greenPassPermissionList: ['P01'],
  //       greenPassRoleList: ['Owner'],
  //     },
  //     {
  //       title: 'Tables',
  //       path: PATH_DASHBOARD.restauration.tablesList,
  //       icon: ICONS.tables,
  //       allRequiredPermissionList: ['C01'],
  //       atLeastOneRequiredPermissionList: [],
  //       greenPassPermissionList: ['P01'],
  //       greenPassRoleList: ['Waiter'],
  //     },
  //     /*     {
  //           title: 'calendar',
  //           path: PATH_DASHBOARD.calendar,
  //           icon: ICONS.calendar,
  //           allRequiredPermissionList: [],
  //           atLeastOneRequiredPermissionList: [],
  //           greenPassPermissionList: ['P01'],
  //           greenPassRoleList: ['Owner'],
  //         }, */
  //   ],
  //   allRequiredPermissionList: [],
  //   atLeastOneRequiredPermissionList: ['P01', 'E01'],
  //   greenPassPermissionList: [],
  //   greenPassRoleList: [],
  // },
  {
    subheader: 'Network',
    subheaderIcon: ICONS.network2,
    items: [
      {
        title: 'inviteUsers',
        path: PATH_DASHBOARD.network.inviteUsers,
        icon: ICONS.invite,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P00', 'C08'],
        greenPassRoleList: ['Owner', 'Ambassador'],
      },
      {
        title: 'inviteAgents',
        path: PATH_DASHBOARD.network.inviteAgents,
        icon: ICONS.invite,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['A00'],
        greenPassRoleList: ['Referral'],
      },
      {
        title: 'inviteStaff',
        path: PATH_DASHBOARD.network.inviteStaff,
        icon: ICONS.staff,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01', 'C08'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'invitationsList',
        path: PATH_DASHBOARD.network.invitationsList,
        icon: ICONS.invitelist,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01', 'P02', 'A01', 'C08'],
        greenPassRoleList: ['Owner', 'Ambassador', 'Referral'],
      },
      {
        title: 'promotersTree',
        path: PATH_DASHBOARD.network.promotersTree,
        icon: ICONS.promoters,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01', 'P02'],
        greenPassRoleList: ['Owner', 'Ambassador'],
      },
      {
        title: 'promotionGroups',
        path: PATH_DASHBOARD.network.promotionGroups,
        icon: ICONS.account,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01', 'A01'],
        greenPassRoleList: ['Owner', 'Referral'],
      },
    ],
    allRequiredPermissionList: [],
    atLeastOneRequiredPermissionList: [],
    greenPassPermissionList: [],
    greenPassRoleList: ['anyone'],
  },
  {
    subheader: 'Account',
    subheaderIcon: ICONS.profile,
    items: [
      {
        title: 'Onboarding',
        path: PATH_DASHBOARD.user.onboarding,
        icon: ICONS.onboarding,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: [],
        greenPassRoleList: ['anyone'],
      },
      {
        title: 'Mail Template',
        path: PATH_DASHBOARD.user.mails,
        icon: ICONS.mails,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: [],
        greenPassRoleList: ['anyone'],
      },
      {
        title: 'Contacts',
        path: PATH_DASHBOARD.user.contacts,
        icon: ICONS.contacts,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: [],
        greenPassRoleList: ['anyone'],
      },
      {
        title: 'billing',
        path: PATH_DASHBOARD.user.billing,
        icon: ICONS.bill,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01', 'P02', 'A01'],
        greenPassRoleList: ['Owner', 'Ambassador', 'Referral'],
      },
      // {
      //   title: 'Seat Mapper',
      //   path: PATH_DASHBOARD.user.seatmapper,
      //   icon: ICONS.onboarding,
      //   allRequiredPermissionList: [],
      //   atLeastOneRequiredPermissionList: [],
      //   greenPassPermissionList: [],
      //   greenPassRoleList: ['anyone'],
      // },
      {
        title: 'settings',
        path: PATH_DASHBOARD.user.account,
        icon: ICONS.settings,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01', 'P02', 'A01'],
        greenPassRoleList: ['Owner', 'Ambassador', 'Referral'],
      },
    ],
    allRequiredPermissionList: [],
    atLeastOneRequiredPermissionList: [],
    greenPassPermissionList: [],
    greenPassRoleList: ['anyone'],
  },
];

const navConfigRestaurant: navConfigProps[] = [
  {
    subheader: 'Restauration',
    subheaderIcon: ICONS.restaurant,
    items: [
      {
        title: 'restaurants',
        path: PATH_DASHBOARD.restauration.restaurants,
        icon: ICONS.restaurantb,
        allRequiredPermissionList: ['C01'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'commandes',
        path: PATH_DASHBOARD.restauration.commandes,
        icon: ICONS.ecommerce,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      /*     {
            title: 'calendar',
            path: PATH_DASHBOARD.calendar,
            icon: ICONS.calendar,
            allRequiredPermissionList: [],
            atLeastOneRequiredPermissionList: [],
            greenPassPermissionList: ['P01'],
            greenPassRoleList: ['Owner'],
          },*/ 
    ],
    allRequiredPermissionList: [],
    atLeastOneRequiredPermissionList: ['P01', 'E01'],
    greenPassPermissionList: [],
    greenPassRoleList: [],
  },
  {
    subheader: 'Network',
    subheaderIcon: ICONS.network2,
    items: [
      {
        title: 'inviteUsers',
        path: PATH_DASHBOARD.network.inviteUsers,
        icon: ICONS.invite,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P00', 'C08'],
        greenPassRoleList: ['Owner', 'Ambassador'],
      },
      {
        title: 'inviteAgents',
        path: PATH_DASHBOARD.network.inviteAgents,
        icon: ICONS.invite,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['A00'],
        greenPassRoleList: ['Referral'],
      },
      {
        title: 'inviteStaff',
        path: PATH_DASHBOARD.network.inviteStaff,
        icon: ICONS.staff,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01', 'C08'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'invitationsList',
        path: PATH_DASHBOARD.network.invitationsList,
        icon: ICONS.invitelist,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01', 'P02', 'A01', 'C08'],
        greenPassRoleList: ['Owner', 'Ambassador', 'Referral'],
      },
      {
        title: 'promotersTree',
        path: PATH_DASHBOARD.network.promotersTree,
        icon: ICONS.promoters,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01', 'P02'],
        greenPassRoleList: ['Owner', 'Ambassador'],
      },
      {
        title: 'promotionGroups',
        path: PATH_DASHBOARD.network.promotionGroups,
        icon: ICONS.account,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01', 'A01'],
        greenPassRoleList: ['Owner', 'Referral'],
      },
    ],
    allRequiredPermissionList: [],
    atLeastOneRequiredPermissionList: [],
    greenPassPermissionList: [],
    greenPassRoleList: ['anyone'],
  },
  {
    subheader: 'Account',
    subheaderIcon: ICONS.profile,
    items: [
      {
        title: 'Onboarding',
        path: PATH_DASHBOARD.user.onboarding,
        icon: ICONS.onboarding,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: [],
        greenPassRoleList: ['anyone'],
      },
      {
        title: 'Mails',
        path: PATH_DASHBOARD.user.mails,
        icon: ICONS.onboarding,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: [],
        greenPassRoleList: ['anyone'],
      },
      {
        title: 'billing',
        path: PATH_DASHBOARD.user.billing,
        icon: ICONS.bill,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01', 'P02', 'A01'],
        greenPassRoleList: ['Owner', 'Ambassador', 'Referral'],
      },
      {
        title: 'settings',
        path: PATH_DASHBOARD.user.account,
        icon: ICONS.settings,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01', 'P02', 'A01'],
        greenPassRoleList: ['Owner', 'Ambassador', 'Referral'],
      },
    ],
    allRequiredPermissionList: [],
    atLeastOneRequiredPermissionList: [],
    greenPassPermissionList: [],
    greenPassRoleList: ['anyone'],
  },
];

/* const navConfigAgent: navConfigProps[] = [
  {
    subheader: 'general',
    items: [
      {
        title: 'my_events',
        path: PATH_DASHBOARD.evenementiel.eventsList,
        icon: ICONS.myEvents,
        allRequiredPermissionList: [] as Pcode[],
        atLeastOneRequiredPermissionList: [] as Pcode[],
        greenPassPermissionList: ['A01'] as Pcode[],
        greenPassRoleList: ['Referral'] as string[],
      },
      {
        title: 'billing',
        path: PATH_DASHBOARD.evenementiel.billing,
        icon: ICONS.billing,
        allRequiredPermissionList: [] as Pcode[],
        atLeastOneRequiredPermissionList: [] as Pcode[],
        greenPassPermissionList: ['A01'] as Pcode[],
        greenPassRoleList: ['Referral'] as string[],
      },
    ],
  },
];

const navConfigAmbassador: navConfigProps[] = [
  {
    subheader: 'general',
    items: [
      {
        title: 'invite_owners',
        path: PATH_DASHBOARD.evenementiel.ambassadorInvites,
        icon: ICONS.user,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P02'],
        greenPassRoleList: ['Ambassador'],
      },

      {
        title: 'billing',
        path: PATH_DASHBOARD.evenementiel.billing,
        icon: ICONS.billing,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P02'],
        greenPassRoleList: ['Ambassador'],
      },
    ],
  },
]; */

/* const navConfigResto: navConfigProps[] = [
  {
    subheader: 'general',
    items: [
      // { title: 'Tableau de bord', path: PATH_DASHBOARD.evenementiel.app, icon: ICONS.dashboard },
      {
        title: 'Commandes',
        path: PATH_DASHBOARD.restauration.commandes,
        icon: ICONS.ecommerce,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Organisations',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: ICONS.banking,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Evénements',
        path: PATH_DASHBOARD.evenementiel.events,
        icon: ICONS.booking,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Stores',
        path: PATH_DASHBOARD.restauration.stores,
        icon: ICONS.stores,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Applications',
        path: PATH_DASHBOARD.restauration.applications,
        icon: ICONS.apps,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'calendar',
        path: PATH_DASHBOARD.calendar,
        icon: ICONS.calendar,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      // { title: 'Comptabilité', path: PATH_DASHBOARD.evenementiel.banking, icon: ICONS.banking },
    ],
  },
]; */
interface DashboardItem {
  title: string;
  path: string;
  icon: JSX.Element;
  subItems?: DashboardItem[];
  roles: string[];
  type?: 'dashboard' | 'sidebar';
  allRequiredPermissionList: Pcode[];
  atLeastOneRequiredPermissionList: Pcode[];
  greenPassPermissionList: Pcode[];
  greenPassRoleList: string[];
}

const navEventTabs = [
  {
    subheader: 'general',
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: ICONS.dashboard,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: ['E05', 'E06'],
        greenPassPermissionList: ['P00'],
        greenPassRoleList: ['Owner', 'Bouncer', 'Ambassador'],
      },
      {
        title: 'Tickets Data',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: ICONS.analytics2,
        allRequiredPermissionList: [],
        atLeastOneRequiredPermissionList: ['E05', 'E06'],
        greenPassPermissionList: ['P00'],
        greenPassRoleList: ['Owner', 'Bouncer', 'Ambassador'],
      },
      {
        title: 'General',
        path: PATH_DASHBOARD.evenementiel.events,
        icon: (
          <Box
            sx={{
              borderRadius: '50%',
              width: '22px',
              height: '22px',
              background: '#333d48',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            1
          </Box>
        ),
        allRequiredPermissionList: ['E04', 'E07'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Dates',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: (
          <Box
            sx={{
              borderRadius: '50%',
              width: '22px',
              height: '22px',
              background: '#333d48',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            2
          </Box>
        ),
        allRequiredPermissionList: ['E04', 'E07'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Tickets',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: (
          <Box
            sx={{
              borderRadius: '50%',
              width: '22px',
              height: '22px',
              background: '#333d48',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            3
          </Box>
        ),
        allRequiredPermissionList: ['E04', 'E07'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'publish',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: (
          <Box
            sx={{
              borderRadius: '50%',
              width: '22px',
              height: '22px',
              background: '#333d48',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            4
          </Box>
        ),
        allRequiredPermissionList: ['E04', 'E08'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Guests',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: <Iconify icon={'fluent:guest-12-filled'} width={14} height={14} color="#919eab" />,
        allRequiredPermissionList: ['E11'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Promobooster',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: <Iconify icon={'mdi:tag-check'} width={14} height={14} color="#919eab" />,
        allRequiredPermissionList: ['E11'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'DJs',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: (
          <Iconify
            icon={'pepicons-pencil:music-note-single-circle-filled'}
            width={14}
            height={14}
            color="#919eab"
          />
        ),
        allRequiredPermissionList: ['E11'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Preview',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: <Iconify icon={'material-symbols:preview'} width={14} height={14} color="#919eab" />,

        allRequiredPermissionList: ['E04'],
        atLeastOneRequiredPermissionList: ['E07', 'E08'],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Bouncer', 'Owner'],
      },
      {
        title: 'overview',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: <Iconify icon={'mdi:global-search'} width={14} height={14} color="#919eab" />,
        allRequiredPermissionList: ['E04'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Bouncer', 'Owner'],
      },
      {
        title: 'information',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: <Iconify icon={'fluent:form-20-filled'} width={14} height={14} color="#919eab" />,
        subItems: [
          {
            title: 'Messages',
            path: PATH_DASHBOARD.evenementiel.organisations,
            icon: <Iconify icon={'tabler:messages'} width={14} height={14} color="#919eab" />,
          },
          {
            title: 'required_information',
            path: PATH_DASHBOARD.evenementiel.organisations,
            icon: <Iconify icon={'fluent:form-20-filled'} width={14} height={14} color="#919eab" />,
          },
          {
            title: 'invoice_information',
            path: PATH_DASHBOARD.evenementiel.organisations,
            icon: <Iconify icon={'mdi:invoice-add'} width={14} height={14} color="#919eab" />,
          },
          {
            title: 'survey_information',
            path: PATH_DASHBOARD.evenementiel.organisations,
            icon: <Iconify icon={'wpf:survey'} width={14} height={14} color="#919eab" />,
          },
        ],
        allRequiredPermissionList: ['E09'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },

      {
        title: 'promo_code',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: <Iconify icon={'ps:promo'} width={14} height={14} color="#919eab" />,
        allRequiredPermissionList: ['E10'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },

      {
        title: 'Participants',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: ICONS.user,
        allRequiredPermissionList: ['E12'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Campaigns',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: ICONS.campaigns,
        allRequiredPermissionList: ['E12'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Goodies',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: ICONS.menu,
        allRequiredPermissionList: ['E12'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'shares_distribution',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: ICONS.shares,
        allRequiredPermissionList: ['E12'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
    ] as DashboardItem[],
  },
];
const navOrgTabs = [
  {
    subheader: 'general',
    items: [
      {
        title: 'General',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: <Iconify icon={'mdi:home-city-outline'} width={14} height={14} color="#919eab" />,
        roles: [],
        type: 'dashboard',
        allRequiredPermissionList: ['C02'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Editing',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: (
          <Iconify icon={'mdi:file-document-edit-outline'} width={14} height={14} color="#919eab" />
        ),
        allRequiredPermissionList: ['C02', 'C03'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Grouped tables',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: <Iconify icon={'game-icons:round-table'} width={14} height={14} color="#919eab" />,
        allRequiredPermissionList: ['C02', 'C03'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Tables',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: (
          <Iconify
            icon={'material-symbols:table-restaurant-sharp'}
            width={14}
            height={14}
            color="#919eab"
          />
        ),
        allRequiredPermissionList: ['C02', 'C03'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },

      {
        title: 'users',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: <Iconify icon={'mdi:account-group-outline'} width={14} height={14} color="#919eab" />,
        allRequiredPermissionList: ['C07'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Roles',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: (
          <Iconify icon={'mdi:shield-account-outline'} width={14} height={14} color="#919eab" />
        ),
        allRequiredPermissionList: ['C04'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
    ] as DashboardItem[],
  },
];
const navResTabs = [
  {
    subheader: 'general',
    items: [
      {
        title: 'General',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: <Iconify icon={'mdi:home-city-outline'} width={14} height={14} color="#919eab" />,
        roles: [],
        type: 'dashboard',
        allRequiredPermissionList: ['C02'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Editing',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: (
          <Iconify icon={'mdi:file-document-edit-outline'} width={14} height={14} color="#919eab" />
        ),
        allRequiredPermissionList: ['C02', 'C03'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Menu',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: (
          <Iconify icon={'mdi:file-document-edit-outline'} width={14} height={14} color="#919eab" />
        ),
        allRequiredPermissionList: ['C02', 'C03'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Grouped tables',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: <Iconify icon={'game-icons:round-table'} width={14} height={14} color="#919eab" />,
        allRequiredPermissionList: ['C02', 'C03'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Tables',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: (
          <Iconify
            icon={'material-symbols:table-restaurant-sharp'}
            width={14}
            height={14}
            color="#919eab"
          />
        ),
        allRequiredPermissionList: ['C02', 'C03'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'waiters',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: (
          <Iconify icon={'material-symbols:room-service'} width={14} height={14} color="#919eab" />
        ),
        allRequiredPermissionList: ['C02', 'C03'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'users',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: <Iconify icon={'mdi:account-group-outline'} width={14} height={14} color="#919eab" />,
        allRequiredPermissionList: ['C07'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Roles',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: (
          <Iconify icon={'mdi:shield-account-outline'} width={14} height={14} color="#919eab" />
        ),
        allRequiredPermissionList: ['C04'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
    ] as DashboardItem[],
  },
];
const navEventOrgTabs = [
  {
    subheader: 'general',
    items: [
      {
        title: 'General',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: <Iconify icon={'mdi:home-city-outline'} width={14} height={14} color="#919eab" />,
        roles: [],
        type: 'dashboard',
        allRequiredPermissionList: ['C02'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Editing',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: (
          <Iconify icon={'mdi:file-document-edit-outline'} width={14} height={14} color="#919eab" />
        ),
        allRequiredPermissionList: ['C02', 'C03'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },

      {
        title: 'users',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: <Iconify icon={'mdi:account-group-outline'} width={14} height={14} color="#919eab" />,
        allRequiredPermissionList: ['C07'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
      {
        title: 'Roles',
        path: PATH_DASHBOARD.evenementiel.organisations,
        icon: (
          <Iconify icon={'mdi:shield-account-outline'} width={14} height={14} color="#919eab" />
        ),
        allRequiredPermissionList: ['C04'],
        atLeastOneRequiredPermissionList: [],
        greenPassPermissionList: ['P01'],
        greenPassRoleList: ['Owner'],
      },
    ] as DashboardItem[],
  },
];
const navConfig = {
  navConfigEvent,
  navConfigRestaurant,
  navEventTabs,
  navOrgTabs,
  navResTabs,
};

export default navConfig;
