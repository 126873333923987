import { SYSTEM_KEYWORDS } from "./constants";
// FRONTEND
import { UserData, PromoterData, StaffData, AgentData, SignupPromoterOwner, InvitedPromoterOwner, PredefinedStaffRolesEvent, PredefinedStaffRolesRestaurant, OrgType, CustomRoleName, AgentRoles, Invitation, PromoterToPromoter, PromoterToAgent, AgentToAgent, PromoterOwnerToStaffEvent, InviteDataClientArgs, PromoterRoles, SignUpArgs } from "./types";

// user accounts guards from userdData
export const isPromoterUserData = (data: UserData): data is UserData & PromoterData => data?.division === "Promoter";
export const isStaffUserData = (data: UserData): data is UserData & StaffData => data?.division === "Staff";
export const isAgentUserData = (data: UserData): data is UserData & AgentData => data?.division === "Agent";
// the 4 promoter user accounts guards
export const isSignupPromoterAmbassador = (data: any): data is { type: 'SignupPromoterAmbassador', code: 'SPA', division: "Promoter"; role: "Ambassador"; isInvite: false; invitationId: null; inviterId: null; mainAncestorId: null; level: 0; ancestors: string[]; orgs: null; } => data?.division === "Promoter" && data?.role === "Ambassador" && data?.isInvite === false;
export const isSignupPromoterOwner = (data: any): data is { type: 'SignupPromoterOwner', code: 'SPO', division: "Promoter"; role: "Owner"; isInvite: false; invitationId: null; inviterId: null; mainAncestorId: null; level: 0; ancestors: string[]; orgs: string[]; } => data?.division === "Promoter" && data?.role === "Owner" && data?.isInvite === false;
export const isInvitedPromoterAmbassador = (data: any): data is { type: 'InvitedPromoterAmbassador', code: 'IPA', division: "Promoter"; role: "Ambassador"; isInvite: true; invitationId: string; inviterId: string; mainAncestorId: string; level: number; ancestors: string[]; orgs: null; } => data?.division === "Promoter" && data?.role === "Ambassador" && data?.isInvite === true;
export const isInvitedPromoterOwner = (data: any): data is { type: 'InvitedPromoterOwner', code: 'IPO', division: "Promoter"; role: "Owner"; isInvite: true; invitationId: string; inviterId: string; mainAncestorId: string; level: number; ancestors: string[]; orgs: string[]; } => data?.division === "Promoter" && data?.role === "Owner" && data?.isInvite === true;
// the 2 owner user accounts guard (signup or invited)
export const isOwnerData = (data: any): data is SignupPromoterOwner | InvitedPromoterOwner => isSignupPromoterOwner(data) || isInvitedPromoterOwner(data);
// global promoter user account guard
export const isPromoterData = (data: any): data is PromoterData => isSignupPromoterAmbassador(data) || isSignupPromoterOwner(data) || isInvitedPromoterAmbassador(data) || isInvitedPromoterOwner(data);
// the 3 staff user accounts guards
export const isInvitedStaffEvent = (data: any): data is { type: 'InvitedStaffEvent', code: 'ISE', division: "Staff"; orgType: "Evenementiel"; orgName: string; role: PredefinedStaffRolesEvent; isCustom: false; ownerId: string; orgId: string; roleId: null; } => data?.division === "Staff" && data?.orgType === "Evenementiel" && data?.orgName !== '' && data?.isCustom === false;
export const isInvitedStaffRestaurant = (data: any): data is { type: 'InvitedStaffRestaurant', code: 'ISR', division: "Staff"; orgType: "Restaurant"; orgName: string; role: PredefinedStaffRolesRestaurant; isCustom: false; ownerId: string; orgId: string; roleId: null; } => data?.division === "Staff" && data?.orgType === "Restaurant" && data?.orgName !== '' && data?.isCustom === false;
export const isInvitedStaffCustom = (data: any): data is { type: 'InvitedStaffCustom', code: 'ISC', division: "Staff"; orgType: OrgType; orgName: string; role: CustomRoleName; isCustom: true; ownerId: string; orgId: string; roleId: string; } => data?.division === "Staff" && data?.isCustom === true;
// the global staff user account guard
export const isStaffData = (data: any): data is StaffData => isInvitedStaffEvent(data) || isInvitedStaffRestaurant(data) || isInvitedStaffCustom(data);
// the 2 agent user accounts guards
export const isSignupAgent = (data: AgentData): data is { type: 'SignupAgent', code: 'SAG', division: "Agent"; role: AgentRoles; isInvite: false; invitationId: null; inviterId: null; } => data?.division === "Agent" && data?.isInvite === false;
export const isInvitedAgent = (data: AgentData): data is { type: 'InvitedAgent', code: 'IAG', division: "Agent"; role: AgentRoles; isInvite: true; invitationId: string; inviterId: string; } => data?.division === "Agent" && data?.isInvite === true;
// global agent user account guard
export const isAgentData = (data: any): data is AgentData => isSignupAgent(data) || isInvitedAgent(data);
// the 6 invitation guards
export const isPromoterToPromoter = (invitation: Invitation): invitation is PromoterToPromoter => invitation?.inviterDivision === "Promoter" && invitation?.invitedDivision === "Promoter";
export const isPromoterToAgent = (invitation: Invitation): invitation is PromoterToAgent => invitation?.inviterDivision === "Promoter" && invitation?.invitedDivision === "Agent";
export const isAgentToAgent = (invitation: Invitation): invitation is AgentToAgent => invitation?.inviterDivision === "Agent" && invitation?.invitedDivision === "Agent";
export const isPromoterOwnerToStaffEvent = (invitation: Invitation): invitation is PromoterOwnerToStaffEvent => invitation?.inviterDivision === "Promoter" && invitation?.invitedDivision === "Staff" && ["Organizer", "Bouncer"].includes(invitation?.invitedRole) && 'orgId' in invitation && invitation?.invitedFirstName !== "" && invitation?.invitedLastName !== "" && invitation?.invitedEmail !== "" && invitation?.invitedLang !== "" && invitation?.orgName !== "" && invitation?.type === 'PromoterOwnerToStaffEvent';
export const isPromoterOwnerToStaffRestaurant = (invitation: Invitation): invitation is PromoterOwnerToStaffEvent => invitation?.inviterDivision === "Promoter" && invitation?.invitedDivision === "Staff" && ["Manager", "Waiter", "Cashier"].includes(invitation?.invitedRole) && 'orgId' in invitation && invitation?.invitedFirstName !== "" && invitation?.invitedLastName !== "" && invitation?.invitedEmail !== "" && invitation?.invitedLang !== "" && invitation?.orgName !== "" && invitation?.type === 'PromoterOwnerToStaffRestaurant';
export const isPromoterOwnerToStaffCustom = (invitation: Invitation): invitation is PromoterOwnerToStaffEvent => invitation?.inviterDivision === "Promoter" && invitation?.invitedDivision === "Staff" && 'roleId' in invitation && !SYSTEM_KEYWORDS.includes(invitation?.invitedRole) && 'orgId' in invitation && invitation?.invitedFirstName !== "" && invitation?.invitedLastName !== "" && invitation?.invitedEmail !== "" && invitation?.invitedLang !== "" && invitation?.orgName !== "" && invitation?.type === 'PromoterOwnerToStaffCustom';
export const isPromoterOwnerToStaff = (invitation: Invitation): invitation is PromoterOwnerToStaffEvent | PromoterOwnerToStaffEvent | PromoterOwnerToStaffEvent => isPromoterOwnerToStaffEvent(invitation) || isPromoterOwnerToStaffRestaurant(invitation) || isPromoterOwnerToStaffCustom(invitation);
// Type Guards args
// invite data client args
export const isPromoterInviteArgs = (data: InviteDataClientArgs): data is { email: string, firstName: string, lastName: string, invitedDivision: 'Promoter', invitedRole: PromoterRoles, lang?: string } => data?.invitedDivision === 'Promoter' && data?.firstName !== "" && data?.lastName !== "" && data?.email !== "";
export const isOwnerInviteArgs = (data: InviteDataClientArgs): data is { email: string, firstName: string, lastName: string, invitedDivision: 'Promoter', invitedRole: 'Owner', lang?: string } => data?.invitedDivision === 'Promoter' && data?.invitedRole === 'Owner' && data?.firstName !== "" && data?.lastName !== "" && data?.email !== "";
export const isAmbassadorInviteArgs = (data: InviteDataClientArgs): data is { email: string, firstName: string, lastName: string, invitedDivision: 'Promoter', invitedRole: 'Ambassador', lang?: string } => data?.invitedDivision === 'Promoter' && data?.invitedRole === 'Ambassador' && data?.firstName !== "" && data?.lastName !== "" && data?.email !== "";
export const isStaffEventInviteArgs = (data: InviteDataClientArgs): data is { email: string, firstName: string, lastName: string, invitedDivision: 'Staff', invitedRole: PredefinedStaffRolesEvent, orgId: string, lang?: string } => data.invitedDivision === 'Staff' && ["Organizer", "Bouncer"].includes(data?.invitedRole) && 'orgId' in data && data?.firstName !== "" && data?.lastName !== "" && data?.email !== "";
export const isStaffRestaurantInviteArgs = (data: InviteDataClientArgs): data is { email: string, firstName: string, lastName: string, invitedDivision: 'Staff', invitedRole: PredefinedStaffRolesRestaurant, orgId: string, lang?: string } => data?.invitedDivision === 'Staff' && ["Manager", "Waiter", "Cashier"].includes(data?.invitedRole) && 'orgId' in data && data?.firstName !== "" && data?.lastName !== "" && data?.email !== "";
export const isStaffCustomRoleInviteArgs = (data: InviteDataClientArgs): data is { email: string, firstName: string, lastName: string, invitedDivision: 'Staff', invitedRole: CustomRoleName, roleId: string, orgId: string, lang?: string } => data?.invitedDivision === 'Staff' && 'roleId' in data && !SYSTEM_KEYWORDS.includes(data?.invitedRole) && 'orgId' in data && data?.firstName !== "" && data?.lastName !== "" && data?.email !== "";
export const isAgentInviteArgs = (data: InviteDataClientArgs): data is { email: string, firstName: string, lastName: string, invitedDivision: 'Agent', invitedRole: AgentRoles, lang?: string } => data.invitedDivision === 'Agent' && data?.firstName !== "" && data?.lastName !== "" && data?.email !== "";
// SignUp Data client Args
export const isPromoterSignUpArgs = (data: SignUpArgs): data is { division: "Promoter", role: PromoterRoles, email: string, firstName: string, lastName: string, password: string, lang?: string } => data?.division === "Promoter";
export const isAgentSignUpArgs = (data: SignUpArgs): data is { division: "Agent", role: AgentRoles, email: string, firstName: string, lastName: string, password: string, lang?: string } => data?.division === "Agent";
