// @mui
import { alpha } from '@mui/material/styles';
// components
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import { useNavigate } from 'react-router';
import { Badge } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { useEffect, useMemo, useState } from 'react';
import { collection, collectionGroup, onSnapshot, query, where } from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { PATH_DASHBOARD } from 'src/routes/paths';

export default function Requests() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [data, setData] = useState<any>();

  const [promoterData, setPromoterData] = useState<any>();

  const [promoterInvitations, setPromoterInvitation] = useState<any>();

  useEffect(() => {
    if (!user?.uid) return;
    const userRef = collection(DB, 'users', user.uid, 'referralrequests');
    const q = query(userRef);
    const unsubscribe = onSnapshot(q, (snapshot: any) => {
      setData(snapshot.docs.map((doc: any) => ({ uid: doc.id, ...doc.data() })));
    });

    return () => unsubscribe();
  }, [user.uid]);

  useEffect(() => {
    const userRef = query(
      collectionGroup(DB, 'referralrequests'),
      where('userID', '==', user?.uid)
    );
    // const q = query(userRef, where('storeid', '==', 'vWWVAXMFaNTz1PcL0LIu'));
    const unsubscribe = onSnapshot(userRef, (snapshot: any) => {
      setPromoterData(snapshot.docs.map((doc: any) => ({ uid: doc.id, ...doc.data() })));
    });

    return () => unsubscribe();
  }, [user.uid]);

  const requests = useMemo(() => {
    if (user?.role === 'Owner' && data) {
      return data?.filter((request: any) => request.status === 'sent');
    } else if (promoterData) {
      return promoterData?.filter((request: any) => request.status === 'sent');
    }
  }, [promoterData, data, user?.role]);

  useEffect(() => {
    if (!user?.uid) return;
    let groupsRef = collection(DB, 'users', user.uid, 'referralInvitation');
    const q = query(groupsRef);
    const unsubscribe = onSnapshot(q, (snapshot: any) => {
      setPromoterInvitation(snapshot.docs.map((doc: any) => ({ id: doc.id, ...doc.data() })));
    });

    return () => unsubscribe();
  }, [user.uid]);

  const pendingGroupRequests = useMemo(
    () => promoterInvitations?.filter((group: any) => group.status === 'pending'),
    [promoterInvitations]
  );
  const reqNumberExist =
    (requests?.length ?? 0 + pendingGroupRequests?.length ?? 0) > 0 ? true : false;
  // if (reqNumberExist)
  return (
    <Badge
      badgeContent={
        requests && pendingGroupRequests && requests?.length + pendingGroupRequests?.length
      }
      color="error"
    >
      <IconButtonAnimate
        color="default"
        onClick={() => navigate(PATH_DASHBOARD.general.notifications)}
        sx={{
          width: 40,
          height: 40,
          ...{
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          },
        }}
      >
        <Iconify icon={'mdi:bell'} width={20} height={20} />
      </IconButtonAnimate>
    </Badge>
  );
  // return (
  //   <IconButtonAnimate
  //     color="default"
  //     onClick={() => navigate('/dashboard/invitations/list')}
  //     sx={{
  //       width: 40,
  //       height: 40,
  //       ...{
  //         bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
  //       },
  //     }}
  //   >
  //     <Iconify icon={'mdi:bell'} width={20} height={20} />
  //   </IconButtonAnimate>
  // );
}
