import { m } from 'framer-motion';
// @mui
import { Container, Typography } from '@mui/material';
// hooks
import useAuth from '../hooks/useAuth';
// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { ForbiddenIllustration } from '../assets';
import { Pcode } from './admin/types';

// ----------------------------------------------------------------------

type PermissionBasedGuardProp = {
  hasContent?: boolean;
  allRequiredPermissionList: Pcode[];
  atLeastOneRequiredPermissionList: Pcode[];
  greenPassPermissionList: Pcode[];
  greenPassRoleList: string[];
  children: React.ReactNode;

};

export default function PermissionBasedGuard({
  hasContent,
  allRequiredPermissionList,
  atLeastOneRequiredPermissionList,
  greenPassPermissionList,
  greenPassRoleList,
  children,
}: PermissionBasedGuardProp)
{
  const { user } = useAuth();

  const currentUserPermissions = user?.pcodes ?? [];
  const currentUserRole = user?.role;

  // all of these permissions are required
  const hasRequiredPermissions =
    allRequiredPermissionList.length === 0 ||
    allRequiredPermissionList.every((pcode) => currentUserPermissions.includes(pcode));

  // one of these permissions is required
  const hasAtLeastOneRequiredPermission =
    atLeastOneRequiredPermissionList.length === 0 ||
    atLeastOneRequiredPermissionList.some((pcode) => currentUserPermissions.includes(pcode));

  // if the user has one of the green pass roles then they have access
  const hasRoleAccess =
    (greenPassRoleList.includes(currentUserRole) && greenPassRoleList.length > 0);

  // if the user has one of the green pass permissions then they have access
  const hasPermissionAccess = (greenPassPermissionList.length > 0) &&
    (greenPassPermissionList.some((pcode) => currentUserPermissions.includes(pcode)));

  // check if the permissions requested list is not empty
  const hasAtLeastOnePermission =
    allRequiredPermissionList.length > 0 || atLeastOneRequiredPermissionList.length > 0;

  // if the user has all the required permissions and at least one of the one of the required permissions
  const hasRequestedPermissions = hasRequiredPermissions && hasAtLeastOneRequiredPermission && hasAtLeastOnePermission;
  
  const anyoneHasAccess =  greenPassRoleList.length === 1 && greenPassRoleList[0] === 'anyone';

  const hasAccess = hasRequestedPermissions || hasRoleAccess || hasPermissionAccess || anyoneHasAccess;

  if (!hasAccess)
  {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            You do not have permission to access this page
          </Typography>sup
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    ) : null;
  }

  return <>{children}</>;
}
