import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useState, ChangeEvent } from 'react';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Stack, Alert, MenuItem } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import cssStyles from '../../utils/cssStyles';
// config
import { HEADER } from '../../config/config';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import { useTranslation } from 'react-i18next';
import { useSubmitAccessRequestMutation } from 'src/redux/api/accessRequests.api';
// ----------------------------------------------------------------------

interface FormData {
  fullName: string;
  email: string;
  phone: string;
  companyName: string;
  position: string;
  reason: string;
  source: string;
}

const FIND_US_OPTIONS = [
  'search_engine',
  'social_media',
  'friend',
  'advertisement',
  'other'
] as const;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function MainHeader() {
  const { t } = useTranslation();
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);
  const theme = useTheme();
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'md');
  const isHome = pathname === '/';

  const [submitRequest, { isLoading }] = useSubmitAccessRequestMutation();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>({
    fullName: '',
    email: '',
    phone: '',
    companyName: '',
    position: '',
    reason: '',
    source: ''
  });

  const handleOpenDialog = (): void => {
    setError(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = (): void => {
    setError(null);
    setOpenDialog(false);
    setFormData({
      fullName: '',
      email: '',
      phone: '',
      companyName: '',
      position: '',
      reason: '',
      source: ''
    });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const validateForm = (): boolean => {
    if (!formData.fullName || !formData.email || !formData.source) {
      setError(t('accessRequests.please_fill_all_fields'));
      return false;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      setError(t('accessRequests.invalid_email'));
      return false;
    }
    if (formData.phone && !/^\+?[\d\s-]+$/.test(formData.phone)) {
      setError(t('accessRequests.invalid_phone'));
      return false;
    }
    return true;
  };

  const handleSubmit = async (): Promise<void> => {
    if (!validateForm()) return;

    try {
      await submitRequest(formData).unwrap();
      handleCloseDialog();
    } catch (err) {
      console.error(err);
      setError(t('accessRequests.error_submitting_request'));
    }
  };

  return (
    <>
      <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
        <ToolbarStyle
          disableGutters
          sx={{
            ...(isOffset && {
              ...cssStyles(theme).bgBlur(),
              height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
            }),
          }}
        >
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Logo />

            <Label color="info" sx={{ ml: 1 }}>
              I.D
            </Label>

            <Box sx={{ flexGrow: 1 }} />

            {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}

            <Button
              variant="contained"
              onClick={handleOpenDialog}
            >
              {t('accessRequests.request_access')}
            </Button>

            {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}
          </Container>
        </ToolbarStyle>

        {isOffset && <ToolbarShadowStyle />}
      </AppBar>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>{t('accessRequests.request_access_title')}</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mt: 2, mb: 1 }}>
              {error}
            </Alert>
          )}
          <Stack spacing={2} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label={t('accessRequests.full_name')}
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
              helperText={t('accessRequests.reason_optional')}
            />
            <TextField
              fullWidth
              label={t('accessRequests.email')}
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <TextField
              fullWidth
              label={t('accessRequests.phone')}
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              helperText={t('accessRequests.phone_optional')}
            />
            <TextField
              fullWidth
              label={t('accessRequests.company_name')}
              name="companyName"
              value={formData.companyName}
              onChange={handleInputChange}
              helperText={t('accessRequests.company_optional')}
            />
            <TextField
              fullWidth
              label={t('accessRequests.position')}
              name="position"
              value={formData.position}
              onChange={handleInputChange}
              helperText={t('accessRequests.position_optional')}
            />
            <TextField
              select
              fullWidth
              label={t('accessRequests.source')}
              name="source"
              value={formData.source}
              onChange={handleInputChange}
              required
            >
              {FIND_US_OPTIONS.map((option) => (
                <MenuItem key={option} value={option}>
                  {t(`accessRequests.source_options.${option}`)}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              label={t('accessRequests.reason')}
              name="reason"
              multiline
              rows={4}
              value={formData.reason}
              onChange={handleInputChange}
              helperText={t('accessRequests.reason_optional')}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t('accessRequests.cancel')}</Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained"
            disabled={isLoading}
          >
            {isLoading ? t('accessRequests.submitting') : t('accessRequests.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}