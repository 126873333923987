import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSettings from 'src/hooks/useSettings';
import { Organisation } from 'src/@types/events_org';
import { OrgType } from 'src/guards/admin/types';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useAuth from './useAuth';

const LOCAL_STORAGE_KEY = 'orgId';

export default function useGlobalOrg(orgsData: Organisation[] | undefined) {
  const navigate = useNavigate();
  const { onToggleField } = useSettings();

  const { user } = useAuth();

  const localStorageOrgId = localStorage.getItem(LOCAL_STORAGE_KEY);

  const [orgId, setOrgId] = useState(localStorageOrgId);
  //console.log('orgId', orgId);
  //console.log('orgsData', orgsData);

  useEffect(() => {
    if (!localStorageOrgId && user.uid && orgsData) {
      setOrgId(orgsData[0]?.id);
    }
  }, [orgsData, localStorageOrgId, user.uid]);

  // console.log('orgId', orgId);

  const onChangeOrgId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const orgId = event.target.value;
    const orgType = orgsData?.find((org) => org.id === orgId)?.type || null;
    setOrgId(orgId);

    onToggleField(orgType as OrgType);

    if (orgType === 'Evenementiel') {
      navigate(PATH_DASHBOARD.evenementiel.events);
    } else {
      navigate( PATH_DASHBOARD.restauration.viewRestaurant(orgId));
    }
    localStorage.setItem(LOCAL_STORAGE_KEY, orgId);
  };

  const onRemoveOrgId = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    setOrgId(null);
    if (orgsData && orgsData.length > 0) {
      const firstOrgType = orgsData[0].type;
      onToggleField(firstOrgType as OrgType);
    }
  };

  return {
    orgId,
    onChangeOrgId,
    onRemoveOrgId,
  };
}
