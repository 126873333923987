import { Access, DivisionDataInvitetypesCodes, DivisionDataSignuptypesCodes, DivisionDatatypesCodes, DivisionInvitedUserDataLiterals, DivisionSignupUserDataLiterals, DivisionUserDataLiterals, IRoleShortNames, InstaModules, InvCode, InvitationType, Pcode, Permission, PreRoles, PredefinedStaffRolesEvent, PredefinedStaffRolesRestaurant, Role, StaffEventRole, StaffRestaurantRole, Step } from "./types";
// BACKENEND
// Email configuration
const EMAIL_CONFIGURATION_KEYS: string[] = ["EMAIL_HOST", "EMAIL_PORT", "EMAIL_USER", "EMAIL_PASS"];
// Existing
const EXISTING_ROLES: string[] = ["Owner", "Ambassador", "Referral", "Organizer", "Bouncer", "Manager", "Waiter", "Cashier"];
const EXISTING_DIVISIONS: string[] = ["Promoter", "Staff", "Agent"];
const SYSTEM_KEYWORDS: string[] = EXISTING_ROLES.concat(EXISTING_DIVISIONS);
// division data
const Divisions = ["Promoter", "Staff", "Agent"];
const promoterRoleNames = ["Owner", "Ambassador"];
const agentRoleNames = ["Referral"];
const predefinedRestaurantStaffRoleNames: PredefinedStaffRolesRestaurant[] = ["Manager", "Waiter", "Cashier"];
const predefinedEventStaffRoleNames: PredefinedStaffRolesEvent[] = ["Organizer", "Bouncer"];
const predefinedStaffRoleNames = ["Organizer", "Bouncer", "Manager", "Waiter", "Cashier"];
const signUpRoles = ["Owner", "Ambassador", "Referral"];
const signUpDivisions = ["Promoter", "Agent"];
const inviteDivisions = ["Promoter", "Agent"];
const inviteRoles = ["Owner", "Ambassador", "Referral"];

const divisionUserDataTypes: DivisionUserDataLiterals[] = ["SignupPromoterOwner", "SignupPromoterAmbassador", "InvitedPromoterOwner", "InvitedPromoterAmbassador", "InvitedStaffEvent", "InvitedStaffRestaurant", "InvitedStaffCustom", "SignupAgent", "InvitedAgent"];
const divisionInvitedUserDataTypes: DivisionInvitedUserDataLiterals[] = ["InvitedPromoterAmbassador", "InvitedPromoterOwner", "InvitedStaffEvent", "InvitedStaffRestaurant", "InvitedStaffCustom", "InvitedAgent"];
const divisionSignupUserDataTypes: DivisionSignupUserDataLiterals[] = ["SignupPromoterAmbassador", "SignupPromoterOwner", "SignupAgent"];
const divisionDataTypesCodes: DivisionDatatypesCodes[] = ["SPA", "SPO", "IPA", "IPO", "ISE", "ISR", "ISC", "SAG", "IAG"];
const divisionDataInviteTypesCodes: DivisionDataInvitetypesCodes[] = ["IPA", "IPO", "ISE", "ISR", "ISC", "IAG"];
const divisionDataSignupTypesCodes: DivisionDataSignuptypesCodes[] = ["SPA", "SPO", "SAG"];

const INVITATION_TYPES: InvitationType[] = [
  "PromoterToPromoter",
  "PromoterToAgent",
  "AgentToAgent",
  "PromoterOwnerToStaffEvent",
  "PromoterOwnerToStaffRestaurant",
  "PromoterOwnerToStaffCustom",
];

const INV_TYPES = [
  "P2P",
  "P2A",
  "O2E",
  "O2R",
  "O2C",
  "O2ED",
  "O2RD",
  "O2CD",
  "A2A",
];

const INVITATION_TYPE_MAP: { [key in InvitationType]: InvCode } = {
  "PromoterToPromoter": "P2P",
  "PromoterToAgent": "P2A",
  "PromoterOwnerToStaffEvent": "O2E",
  "PromoterOwnerToStaffRestaurant": "O2R",
  "PromoterOwnerToStaffCustom": "O2C",
  "AgentToAgent": "A2A",
};

// user types
const userTypeAbbreviations: { [key in DivisionUserDataLiterals]: DivisionDatatypesCodes } = {
  SignupPromoterOwner: "SPO",
  SignupPromoterAmbassador: "SPA",
  InvitedPromoterOwner: "IPO",
  InvitedPromoterAmbassador: "IPA",
  InvitedStaffEvent: "ISE",
  InvitedStaffRestaurant: "ISR",
  InvitedStaffCustom: "ISC",
  SignupAgent: "SAG",
  InvitedAgent: "IAG",
};

const StaffRoles: Role[] = [
  {
    division: "Staff",
    role: "Organizer",
    orgId: "orgId",
    orgType: "Evenementiel",
    isCustom: false,
    description:
      "Organizer a tout les permissions d'un propriétaire mais seulement pour l'organisation qui lui est assigné",
    permissions: [
      "events_viewlist",
      "events_viewlist_unp",
      "events_add",
      "events_view",
      "events_dashboard_stats",
      "events_dashboard_table",
      "events_edit",
      "events_publish",
      "events_req_info",
      "events_code_promo",
      "events_promotion",
      "events_participant",
      "orgs_viewlist",
      "orgs_view",
      "orgs_edit",
      "orgs_roles_viewlist",
      "orgs_roles_add",
      "orgs_roles_edit",
      "orgs_users_viewlist",
      "orgs_users_add",
      "orgs_users_edit",
    ],
    pcodes: [
      "E01",
      "E02",
      "E03",
      "E04",
      "E05",
      "E06",
      "E07",
      "E08",
      "E09",
      "E10",
      "E11",
      "E12",
      "C01",
      "C02",
      "C03",
      "C04",
      "C05",
      "C06",
      "C07",
      "C08",
      "C09",
    ],
    access: {
      access_insta: true,
      access_insta_pro: true,
    },

  },
  {
    division: "Staff",
    role: "Bouncer",
    orgId: "org",
    orgType: "Evenementiel",
    isCustom: false,
    description: "le videur vérifie les billets à l'entrée de l'événement",
    permissions: ["events_viewlist", "events_view", "events_dashboard_table"],
    pcodes: ["E01", "E04", "E06"],
    access: {
      access_insta: true,
      access_insta_pro: true,
    },
  },
  {
    division: "Staff",
    role: "Manager",
    orgId: "org",
    orgType: "Restaurant",
    isCustom: false,
    description:
      "Manager a tout les permissions d'un propriétaire mais seulement pour le restaurant qui lui est assigné",
    permissions: [],
    pcodes: [],
    access: {
      access_insta: true,
      access_insta_pro: true,
    },
  },
  {
    division: "Staff",
    role: "Waiter",
    orgId: "org",
    orgType: "Restaurant",
    isCustom: false,
    description:
      "Le serveur a la capacité de consulter et livrer les commandes, tout en ayant aussi l'option de vérifier ses pourboires dans le tableau de bord",
    permissions: [],
    pcodes: [],
    access: {
      access_insta: true,
      access_insta_pro: false,
    },
  },
  {
    division: "Staff",
    role: "Cashier",
    orgId: "org",
    orgType: "Restaurant",
    isCustom: false,
    description: "Le caissier supervise et transmet les commandes, et peut consulter l'état du stock.",
    permissions: [],
    pcodes: [],
    access: {
      access_insta: true,
      access_insta_pro: false,
    },
  },
];
const StaffRolesEvent: StaffEventRole[] = [
  {
    division: "Staff",
    role: "Organizer",
    orgId: "orgId",
    orgType: "Evenementiel",
    isCustom: false,
    description:
      "Organizer a tout les permissions d'un propriétaire mais seulement pour l'organisation qui lui est assigné",
    permissions: [
      "events_viewlist",
      "events_viewlist_unp",
      "events_add",
      "events_view",
      "events_dashboard_stats",
      "events_dashboard_table",
      "events_edit",
      "events_publish",
      "events_req_info",
      "events_code_promo",
      "events_promotion",
      "events_participant",
      "orgs_viewlist",
      "orgs_view",
      "orgs_edit",
      "orgs_roles_viewlist",
      "orgs_roles_add",
      "orgs_roles_edit",
      "orgs_users_viewlist",
      "orgs_users_add",
      "orgs_users_edit",
    ],
    pcodes: [
      "E01",
      "E02",
      "E03",
      "E04",
      "E05",
      "E06",
      "E07",
      "E08",
      "E09",
      "E10",
      "E11",
      "E12",
      "C01",
      "C02",
      "C03",
      "C04",
      "C05",
      "C06",
      "C07",
      "C08",
      "C09",
    ],
    access: {
      access_insta: true,
      access_insta_pro: true,
    },

  },
  {
    division: "Staff",
    role: "Bouncer",
    orgId: "org",
    orgType: "Evenementiel",
    isCustom: false,
    description: "le videur vérifie les billets à l'entrée de l'événement",
    permissions: ["events_viewlist", "events_view", "events_dashboard_table"],
    pcodes: ["E01", "E04", "E06"],
    access: {
      access_insta: true,
      access_insta_pro: true,
    },
  },
];
const StaffRolesRestaurant: StaffRestaurantRole[] = [
  {
    division: "Staff",
    role: "Manager",
    orgId: "org",
    orgType: "Restaurant",
    isCustom: false,
    description:
      "Manager a tout les permissions d'un propriétaire mais seulement pour le restaurant qui lui est assigné",
    permissions: [],
    pcodes: [],
    access: {
      access_insta: true,
      access_insta_pro: true,
    },
  },
  {
    division: "Staff",
    role: "Waiter",
    orgId: "org",
    orgType: "Restaurant",
    isCustom: false,
    description:
      "Le serveur a la capacité de consulter et livrer les commandes, tout en ayant aussi l'option de vérifier ses pourboires dans le tableau de bord",
    permissions: [],
    pcodes: [],
    access: {
      access_insta: true,
      access_insta_pro: false,
    },
  },
  {
    division: "Staff",
    role: "Cashier",
    orgId: "org",
    orgType: "Restaurant",
    isCustom: false,
    description: "Le caissier supervise et transmet les commandes, et peut consulter l'état du stock.",
    permissions: [],
    pcodes: [],
    access: {
      access_insta: true,
      access_insta_pro: false,
    },
  },
];

const agentsRoles: Role[] = [
  {
    division: "Agent",
    role: "Referral",
    description: "Le PromoBooster peut consulter les événements et les promouvoir sur les réseaux sociaux",
    access: {
      access_insta: true,
      access_insta_pro: true,
    },
    permissions: ["agent_access", "agent_ref_access"],
    pcodes: ["A00", "A01"],
  },
];

const promotersRoles: Role[] = [
  {
    division: "Promoter",
    role: "Ambassador",
    description: "L'ambassadeur peut consulter les organisations et les promouvoir sur les réseaux sociaux",
    access: {
      access_insta: true,
      access_insta_pro: true,
    },
    pcodes: ["P00", "P02"],
    permissions: ["promoter_access", "ambas_access"],
  },
  {
    division: "Promoter",
    role: "Owner",
    description: "propriétaire de l'organisation",
    access: {
      access_insta: true,
      access_insta_pro: true,
    },
    pcodes: ["P00", "P01"],
    permissions: ["promoter_access", "owner_access"],
  },
];

const roleShortNames: IRoleShortNames = {
  Owner: "OW",
  Ambassador: "AM",
  Referral: "RP",
  Organizer: "OR",
  Bouncer: "BC",
  Manager: "MN",
  Waiter: "WT",
  Cashier: "CA",
  Custom: "CS",
  User: "US",
};

// onBoarding
const onBoardingStepsIdsByRole: { [key in PreRoles]: number[] } = {
  Owner: [1, 2, 3, 4],
  Ambassador: [1],
  Referral: [1],
  Organizer: [1],
  Bouncer: [1],
  Manager: [1],
  Waiter: [1],
  Cashier: [1],
};

const stepIdName: { [key: number]: string } = {
  1: "verify_payment",
  2: "create_org",
  3: "create_event",
  4: "publish_event",
};

const onBoardingStepsByRole: {
  [key in PreRoles]: Step[];
} = {
  Owner: [
    { stepId: 1, required: false, status: "current" },
    { stepId: 2, required: true, status: "pending" },
    { stepId: 3, required: false, status: "pending", blocks: [4] },
    { stepId: 4, required: false, status: "pending" },
  ],
  Ambassador: [
    { stepId: 1, required: false, status: "current" },
  ],
  Referral: [
    { stepId: 1, required: false, status: "current" },
  ],
  Organizer: [
    { stepId: 1, required: false, status: "current" },
  ],
  Bouncer: [
    { stepId: 1, required: false, status: "current" },
  ],
  Manager: [
    { stepId: 1, required: false, status: "current" },
  ],
  Waiter: [
    { stepId: 1, required: false, status: "current" },
  ],
  Cashier: [
    { stepId: 1, required: false, status: "current" },
  ],
};

// permissions
const permissions: Permission[] = ["promoter_access", "owner_access", "ambas_access", "agent_access", "agent_ref_access", "agent_ref_group_access", "staff_access", "events_viewlist", "events_viewlist_unp", "events_add", "events_view", "events_dashboard_stats", "events_dashboard_table", "events_edit", "events_publish", "events_req_info", "events_code_promo", "events_promotion", "events_participant", "orgs_viewlist", "orgs_view", "orgs_edit", "orgs_roles_viewlist", "orgs_roles_add", "orgs_roles_edit", "orgs_users_viewlist", "orgs_users_add", "orgs_users_edit"];
const promoterPermissions: Permission[] = ["promoter_access", "owner_access", "ambas_access"];
const agentPermissions: Permission[] = ["agent_access", "agent_ref_access", "agent_ref_group_access"];
const staffPermissions: Permission[] = ["staff_access"];
const eventPermission = ["events_viewlist", "events_viewlist_unp", "events_add", "events_view", "events_dashboard_stats", "events_dashboard_table", "events_edit", "events_publish", "events_req_info", "events_code_promo", "events_promotion", "events_participant"];
const orgPermission = ["orgs_viewlist", "orgs_view", "orgs_edit", "orgs_roles_viewlist", "orgs_roles_add", "orgs_roles_edit", "orgs_users_viewlist", "orgs_users_add", "orgs_users_edit"];
// PERISSIONS BY ROLES
const permissionsByRole: { [key in PreRoles]: Permission[] } = {
  Ambassador: ["promoter_access", "ambas_access"],
  Owner: ["promoter_access", "owner_access"],
  Referral: ["agent_access", "agent_ref_access"],
  Organizer: ["staff_access", "events_viewlist", "events_viewlist_unp", "events_add", "events_view", "events_dashboard_stats", "events_dashboard_table", "events_edit", "events_publish", "events_req_info", "events_code_promo", "events_promotion", "events_participant", "orgs_viewlist", "orgs_view", "orgs_edit", "orgs_roles_viewlist", "orgs_roles_add", "orgs_roles_edit", "orgs_users_viewlist", "orgs_users_add", "orgs_users_edit"],
  Bouncer: ["staff_access", "events_viewlist", "events_view", "events_dashboard_table"],
  Manager: ["staff_access"],
  Waiter: ["staff_access"],
  Cashier: ["staff_access"],
};

const pcodeByRole: { [key in PreRoles]: Pcode[] } = {
  Ambassador: ["P00", "P02"],
  Owner: ["P00", "P01"],
  Referral: ["A00", "A01"],
  Organizer: ["S00", "E01", "E02", "E03", "E04", "E05", "E06", "E07", "E08", "E09", "E10", "E11", "E12", "C01", "C02", "C03", "C04", "C05", "C06", "C07", "C08", "C09"],
  Bouncer: ["S00", "E01", "E04", "E06"],
  Manager: ["S00"],
  Waiter: ["S00"],
  Cashier: ["S00"],
};

const accessByRole: { [key in PreRoles]: Access } = {
  Ambassador: {
    access_insta: true,
    access_insta_pro: true,
  },
  Owner: {
    access_insta: true,
    access_insta_pro: true,
  },
  Referral: {
    access_insta: true,
    access_insta_pro: true,
  },
  Organizer: {
    access_insta: true,
    access_insta_pro: true,
  },
  Bouncer: {
    access_insta: true,
    access_insta_pro: true,
  },
  Manager: {
    access_insta: true,
    access_insta_pro: true,
  },
  Waiter: {
    access_insta: true,
    access_insta_pro: true,
  },
  Cashier: {
    access_insta: true,
    access_insta_pro: true,
  },
};

const codesToPermissions: { [key in Pcode]: Permission } = {
  // promoters
  P00: "promoter_access",
  P01: "owner_access",
  P02: "ambas_access",
  // agents
  A00: "agent_access",
  A01: "agent_ref_access",
  A02: "agent_ref_group_access",
  // Staff
  S00: "staff_access",
  // events
  E01: "events_viewlist",
  E02: "events_viewlist_unp",
  E03: "events_add",
  E04: "events_view",
  E05: "events_dashboard_stats",
  E06: "events_dashboard_table",
  E07: "events_edit",
  E08: "events_publish",
  E09: "events_req_info",
  E10: "events_code_promo",
  E11: "events_promotion",
  E12: "events_participant",
  // organizations
  C01: "orgs_viewlist",
  C02: "orgs_view",
  C03: "orgs_edit",
  C04: "orgs_roles_viewlist",
  C05: "orgs_roles_add",
  C06: "orgs_roles_edit",
  C07: "orgs_users_viewlist",
  C08: "orgs_users_add",
  C09: "orgs_users_edit",
};

const PermissionsToCodes = {
  // promoters
  promoter_access: "P00",
  owner_access: "P01",
  ambas_access: "P02",
  // agents
  agent_access: "A00",
  agent_ref_access: "A01",
  agent_ref_group_access: "A02",
  // Staff
  staff_access: "S00",
  // events
  events_viewlist: "E01",
  events_viewlist_unp: "E02",
  events_add: "E03",
  events_view: "E04",
  events_dashboard_stats: "E05",
  events_dashboard_table: "E06",
  events_edit: "E07",
  events_publish: "E08",
  events_req_info: "E09",
  events_code_promo: "E10",
  events_promotion: "E11",
  events_participant: "E12",
  // organizations
  orgs_viewlist: "C01",
  orgs_view: "C02",
  orgs_edit: "C03",
  orgs_roles_viewlist: "C04",
  orgs_roles_add: "C05",
  orgs_roles_edit: "C06",
  orgs_users_viewlist: "C07",
  orgs_users_add: "C08",
  orgs_users_edit: "C09",
};

const PermissionsByModule: { [key in InstaModules]: Permission[] } = {
  promoters: [
    "promoter_access",
    "owner_access",
    "ambas_access",
  ],
  agents: ["agent_access", "agent_ref_access", "agent_ref_group_access"],
  staff: ["staff_access"],
  events: [
    "events_viewlist",
    "events_viewlist_unp",
    "events_add",
    "events_view",
    "events_dashboard_stats",
    "events_dashboard_table",
    "events_edit",
    "events_publish",
    "events_req_info",
    "events_code_promo",
    "events_promotion",
    "events_participant",
  ],
  organizations: [
    "orgs_viewlist",
    "orgs_view",
    "orgs_edit",
    "orgs_roles_viewlist",
    "orgs_roles_add",
    "orgs_roles_edit",
    "orgs_users_viewlist",
    "orgs_users_add",
    "orgs_users_edit",
  ],
  dashboard: [
  ],
  instapro: [],
};

export
{
  // required fields
  SYSTEM_KEYWORDS,
  EMAIL_CONFIGURATION_KEYS,
  // Invitation types
  INVITATION_TYPES,
  INV_TYPES,
  INVITATION_TYPE_MAP,
  // Divisions and roles
  Divisions,
  promoterRoleNames,
  predefinedEventStaffRoleNames,
  predefinedRestaurantStaffRoleNames,
  predefinedStaffRoleNames,
  agentsRoles,
  promotersRoles,
  StaffRoles,
  StaffRolesEvent,
  StaffRolesRestaurant,
  EXISTING_ROLES,
  divisionUserDataTypes,
  divisionInvitedUserDataTypes,
  divisionSignupUserDataTypes,
  divisionDataTypesCodes,
  divisionDataInviteTypesCodes,
  divisionDataSignupTypesCodes,
  // signup
  signUpDivisions,
  inviteDivisions,
  inviteRoles,
  // user types
  userTypeAbbreviations,

  // onBoarding
  onBoardingStepsIdsByRole,
  stepIdName,
  onBoardingStepsByRole,
  // permissions
  permissions,
  promoterPermissions,
  agentPermissions,
  staffPermissions,
  eventPermission,
  orgPermission,
  codesToPermissions,
  PermissionsToCodes,
  PermissionsByModule,
  permissionsByRole,
  pcodeByRole,
  accessByRole,
  // roles
  agentRoleNames,
  roleShortNames,
  signUpRoles,
};
