import { createContext, ReactNode, Dispatch, SetStateAction, useState, useEffect } from 'react';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

type OrdersContextType = {
  selectedOrgId: string;
  setSelectedOrgId: Dispatch<SetStateAction<string>>;
};

const OrdersContext = createContext<OrdersContextType>({
  selectedOrgId: '',
  setSelectedOrgId: () => {},
});

// ----------------------------------------------------------------------

type OrdersProviderProps = {
  children: ReactNode;
};

function OrdersProvider({ children }: OrdersProviderProps) {
  const [selectedOrgId, setSelectedOrgId] = useState<string>('');
  const { ...state } = useAuth();

  const { isAuthenticated } = state;

  useEffect(() => {
    if (!isAuthenticated) {
      setSelectedOrgId('');
    }
  }, [isAuthenticated]);

  return (
    <OrdersContext.Provider value={{ selectedOrgId, setSelectedOrgId }}>
      {children}
    </OrdersContext.Provider>
  );
}

export { OrdersContext, OrdersProvider };
