import { useState, ChangeEvent } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Container, Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Alert, MenuItem } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
import { useTranslation } from 'react-i18next';
import { useSubmitAccessRequestMutation } from 'src/redux/api/accessRequests.api';

// ----------------------------------------------------------------------

interface FormData {
  fullName: string;
  email: string;
  phone: string;
  companyName: string;
  position: string;
  reason: string;
  source: string;
}

const FIND_US_OPTIONS = [
  'search_engine',
  'social_media',
  'friend',
  'advertisement',
  'other'
] as const;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { t } = useTranslation();
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');

  const [submitRequest, { isLoading }] = useSubmitAccessRequestMutation();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>({
    fullName: '',
    email: '',
    phone: '',
    companyName: '',
    position: '',
    reason: '',
    source: ''
  });

  const handleOpenDialog = (): void => {
    setError(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = (): void => {
    setError(null);
    setOpenDialog(false);
    setFormData({
      fullName: '',
      email: '',
      phone: '',
      companyName: '',
      position: '',
      reason: '',
      source: ''
    });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const validateForm = (): boolean => {
    if (!formData.fullName || !formData.email || !formData.source) {
      setError(t('accessRequests.please_fill_all_fields'));
      return false;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
      setError(t('accessRequests.invalid_email'));
      return false;
    }
    if (formData.phone && !/^\+?[\d\s-]+$/.test(formData.phone)) {
      setError(t('accessRequests.invalid_phone'));
      return false;
    }
    return true;
  };

  const handleSubmit = async (): Promise<void> => {
    if (!validateForm()) return;

    try {
      await submitRequest(formData).unwrap();
      handleCloseDialog();
    } catch (err) {
      setError(t('accessRequests.error_submitting_request'));
    }
  };

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              {t('login_no_account')}{' '}
              <Button
                variant="text"
                onClick={handleOpenDialog}
                sx={{ textTransform: 'none', p: 0, ml: 1 }}
              >
                {t('accessRequests.request_access')}
              </Button>
            </Typography>
          )}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h4" sx={{ px: 5, mt: 15, mb: 2 }}>
              {t('login_welcome')}
            </Typography>
            <Image
              visibleByDefault
              disabledEffect
              src="/assets/illustrations/illustration_login.png"
              alt="login"
            />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {t('login_connect')}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>{t('login_type')}</Typography>
              </Box>
            </Stack>

            <LoginForm />

            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                {t('login_no_account')}{' '}
                <Button
                  variant="text"
                  onClick={handleOpenDialog}
                  sx={{ textTransform: 'none', p: 0, ml: 1 }}
                >
                  {t('accessRequests.request_access')}
                </Button>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>{t('accessRequests.request_access_title')}</DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mt: 2, mb: 1 }}>
              {error}
            </Alert>
          )}
          <Stack spacing={2} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label={t('accessRequests.full_name')}
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
              required
            />
            <TextField
              fullWidth
              label={t('accessRequests.email')}
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <TextField
              fullWidth
              label={t('accessRequests.phone')}
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              helperText={t('accessRequests.phone_optional')}
            />
            <TextField
              fullWidth
              label={t('accessRequests.company_name')}
              name="companyName"
              value={formData.companyName}
              onChange={handleInputChange}
              helperText={t('accessRequests.company_optional')}
            />
            <TextField
              fullWidth
              label={t('accessRequests.position')}
              name="position"
              value={formData.position}
              onChange={handleInputChange}
              helperText={t('accessRequests.position_optional')}
            />
            <TextField
              select
              fullWidth
              label={t('accessRequests.source')}
              name="source"
              value={formData.source}
              onChange={handleInputChange}
              required
            >
              {FIND_US_OPTIONS.map((option) => (
                <MenuItem key={option} value={option}>
                  {t(`accessRequests.source_options.${option}`)}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              label={t('accessRequests.reason')}
              name="reason"
              multiline
              rows={4}
              value={formData.reason}
              onChange={handleInputChange}
              helperText={t('accessRequests.reason_optional')}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t('accessRequests.cancel')}</Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained"
            disabled={isLoading}
          >
            {isLoading ? t('accessRequests.submitting') : t('accessRequests.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}