import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { 
  collection, 
  getDocs, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  doc,
  query,
  where
} from 'firebase/firestore';
import { DB } from 'src/contexts/FirebaseContext';
import { 
  PriceDeformation, 
  AccessRestriction,
  isPriceDeformation 
} from 'src/@types/ticket';

export const TicketsApi = createApi({
  reducerPath: 'ticketsApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['PriceDeformations', 'AccessRestrictions'],
  endpoints: (builder) => ({
    // Price Deformations
    fetchPriceDeformations: builder.query<PriceDeformation[], string>({
      async queryFn(eventId) {
        try {
          const deformationsRef = collection(DB, 'events', eventId, 'priceDeformations');
          const querySnapshot = await getDocs(deformationsRef);
          const deformations: PriceDeformation[] = [];
          
          querySnapshot.forEach((doc) => {
            const data = { id: doc.id, ...doc.data() } as PriceDeformation;
            deformations.push(data);
          });
          
          return { 
            data: deformations.sort((a, b) => a.priority - b.priority) 
          };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      providesTags: ['PriceDeformations'],
    }),

    addPriceDeformation: builder.mutation<void, { 
      eventId: string, 
      deformation: Omit<PriceDeformation, 'id'> 
    }>({
      async queryFn({ eventId, deformation }) {
        try {
          const deformationsRef = collection(DB, 'events', eventId, 'priceDeformations');
          await addDoc(deformationsRef, deformation);
          return { data: undefined };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['PriceDeformations'],
    }),

    updatePriceDeformation: builder.mutation<void, {
      eventId: string,
      deformation: PriceDeformation
    }>({
      async queryFn({ eventId, deformation }) {
        try {
          const { id, ...updateData } = deformation;
          const deformationRef = doc(DB, 'events', eventId, 'priceDeformations', id);
          await updateDoc(deformationRef, updateData);
          return { data: undefined };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['PriceDeformations'],
    }),

    deletePriceDeformation: builder.mutation<void, {
      eventId: string,
      deformationId: string
    }>({
      async queryFn({ eventId, deformationId }) {
        try {
          const deformationRef = doc(DB, 'events', eventId, 'priceDeformations', deformationId);
          await deleteDoc(deformationRef);
          return { data: undefined };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['PriceDeformations'],
    }),

    // Access Restrictions
    fetchAccessRestrictions: builder.query<AccessRestriction[], string>({
      async queryFn(eventId) {
        try {
          const restrictionsRef = collection(DB, 'events', eventId, 'accessRestrictions');
          const querySnapshot = await getDocs(restrictionsRef);
          const restrictions: AccessRestriction[] = [];
          
          querySnapshot.forEach((doc) => {
            const data = { id: doc.id, ...doc.data() } as AccessRestriction;
            restrictions.push(data);
          });
          
          return { 
            data: restrictions.sort((a, b) => a.priority - b.priority) 
          };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      providesTags: ['AccessRestrictions'],
    }),

    addAccessRestriction: builder.mutation<void, { 
      eventId: string, 
      restriction: Omit<AccessRestriction, 'id'> 
    }>({
      async queryFn({ eventId, restriction }) {
        try {
          console.log(restriction);
          console.log(eventId);
          const restrictionsRef = collection(DB, 'events', eventId, 'accessRestrictions');
          await addDoc(restrictionsRef, restriction);
          return { data: undefined };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['AccessRestrictions'],
    }),

    updateAccessRestriction: builder.mutation<void, {
      eventId: string,
      restriction: AccessRestriction
    }>({
      async queryFn({ eventId, restriction }) {
        try {
          const { id, ...updateData } = restriction;
          const restrictionRef = doc(DB, 'events', eventId, 'accessRestrictions', id);
          await updateDoc(restrictionRef, updateData);
          return { data: undefined };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['AccessRestrictions'],
    }),

    deleteAccessRestriction: builder.mutation<void, {
      eventId: string,
      restrictionId: string
    }>({
      async queryFn({ eventId, restrictionId }) {
        try {
          const restrictionRef = doc(DB, 'events', eventId, 'accessRestrictions', restrictionId);
          await deleteDoc(restrictionRef);
          return { data: undefined };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['AccessRestrictions'],
    }),
  }),
});

export const {
  // Price Deformations exports
  useFetchPriceDeformationsQuery,
  useAddPriceDeformationMutation,
  useUpdatePriceDeformationMutation,
  useDeletePriceDeformationMutation,
  // Access Restrictions exports
  useFetchAccessRestrictionsQuery,
  useAddAccessRestrictionMutation,
  useUpdateAccessRestrictionMutation,
  useDeleteAccessRestrictionMutation,
} = TicketsApi;