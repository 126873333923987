import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

export type ColorSchema2 =
  | 'all'
  | 'published'
  | 'ready'
  | 'draft'
  | 'archived'
  | 'canceled'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
  | 'deleted'
  | 'default';
interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
}

interface ChartPaletteOptions {
  violet: string[];
  blue: string[];
  green: string[];
  yellow: string[];
  red: string[];
}

interface EventStatusPaletteOptions {
  all: string;
  published: string;
  ready: string;
  draft: string;
  archived: string;
  canceled: string;
  primary: string;
  secondary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
  deleted: string;
  default: string;
  black: string;
  crimsonie: string;
  lightBlue: string;
}

interface eventTimeStatusPaletteOptions {
  initial: string;
  upcoming: string;
  ongoing: string;
  past: string;
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
    eventStatus: EventStatusPaletteOptions;
    eventTimeStatus: eventTimeStatusPaletteOptions;
  }
  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
}

declare module '@mui/material' {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#C8FACD',
  light: '#5BE584',
  main: '#00AB55',
  dark: '#007B55',
  darker: '#005249',
};
const SECONDARY = {
  lighter: '#bd08ff',
  light: '#9d09ff',
  main: '#8d09fe',
  dark: '#7007cb',
  darker: '#45047e',
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
};

const VIOLET = {
  lighter: '##C4B5FD',
  light: '#A78BFA',
  main: '#8B5CF6',
  dark: '#6D28D9',
  darker: '#4C1D95',
};

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
  //violet : createGradient('#826AF9', '#9E86FF'),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const EVENTS_COLORS = {
  all: '#1890FF',
  published: '#00AB55',
  ready: '#FFC107',
  draft: '#FF4842',
  archived: '#8B5CF6',
  canceled: '#FFA48D',
  deleted: '#919EAB',
  primary: '#00AB55',
  secondary: '#8d09fe',
  info: '#1890FF',
  success: '#54D62C',
  warning: '#FFC107',
  error: '#FF4842',
  default: '#919EAB',
  black: '#000000',
  crimsonie: '#FA337F',
  lightBlue: '#5adaff',
};

const EVENTS_COLORS_DARK = {
  all: '#095399',
  published: '#005249',
  ready: '#DF9100',
  draft: '#7A0C2E',
  archived: '#4C1D95',
  canceled: '#B72136',
  deleted: '#637381',
  primary: '#005249',
  secondary: '#1939B7',
  info: '#095399',
  success: '#229A16',
  warning: '#DF9100',
  error: '#7A0C2E',
  default: '#637381',
  black: '#000000',
  crimsonie: '#C52E54',
  lightBlue: '#2D99FF',
};

const EVENTS_COLORS_LIGHT = {
  all: '#D0F2FF',
  published: '#C8FACD',
  ready: '#FFF7CD',
  draft: '#FFE7D9',
  archived: '#C4B5FD',
  canceled: '#FFD1C4',
  deleted: '#F4F6F8',
  primary: '#C8FACD',
  secondary: '#84A9FF',
  info: '#D0F2FF',
  success: '#E9FCD4',
  warning: '#FFF7CD',
  error: '#FFE7D9',
  default: '#F4F6F8',
  black: '#000000',
  crimsonie: '#EC85AD',
  lightBlue: '#A5F3FF',
};

const EVENT_TIME_STATUS_LIGHT = {
  initial: '#C4B5FD',
  upcoming: '#3366FF',
  ongoing: '#C8FACD',
  past: '#FFE7D9',
};

const EVENT_TIME_STATUS_DARK = {
  initial: '#D0F2FF',
  upcoming: '#095399',
  ongoing: '#54D62C',
  past: '#B72136',
};

const COMMON_LIGHT = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  violet: { ...VIOLET, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  eventStatus: EVENTS_COLORS_DARK,
  eventTimeStatus: EVENTS_COLORS_DARK,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const COMMON_DARK = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  violet: { ...VIOLET, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  eventStatus: EVENTS_COLORS,
  eventTimeStatus: EVENT_TIME_STATUS_DARK,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON_LIGHT,
    mode: 'light',
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: {
      paper: GREY[0],
      default: GREY[100],
      neutral: GREY[200],
      bg1: GREY[200],
      bg2: GREY[200],
    },
    action: { active: GREY[600], ...COMMON_LIGHT.action },
  },
  dark: {
    ...COMMON_DARK,
    mode: 'dark',
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: GREY[500_16],
      bg1: '#26313D',
      bg2: '#19212a',
    },
    action: { active: GREY[500], ...COMMON_DARK.action },
  },
} as const;

export default palette;
