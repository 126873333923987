import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
//
import BackgroundIllustration from './illustration_background';

// ----------------------------------------------------------------------

function PageDeleteAccountIllustration({ ...other }: BoxProps) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <Box {...other}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 650 512"
        width="100%"
        height="100%"
        id="deleted"
      >
        <BackgroundIllustration />

        <circle cx="327.279" cy="217.952" r="173.618" fill="#dbe8ec" />
        <path
          fill="#dbe8ec"
          d="M564.18011,245.7817l0-20.16587a14.49928,14.49928,0,0,0-14.49926-14.49928l-51.17749,0a14.49927,14.49927,0,0,1-14.49926-14.49927l0-20.16587a14.49927,14.49927,0,0,1,14.49927-14.49927h1.61587a14.49927,14.49927,0,0,0,14.49928-14.49926V127.287a14.49926,14.49926,0,0,0-14.49926-14.49927l-336.621-.00015A14.49927,14.49927,0,0,0,148.999,127.28684v20.16587A14.49925,14.49925,0,0,0,163.49829,161.952h0a14.49928,14.49928,0,0,1,14.49926,14.49928l0,20.16587a14.49926,14.49926,0,0,1-14.49927,14.49926l-56.07,0a14.49928,14.49928,0,0,0-14.49928,14.49926V245.7815a14.49928,14.49928,0,0,0,14.49926,14.49928h22.001a14.49928,14.49928,0,0,1,14.49927,14.49928l0,20.166a14.49927,14.49927,0,0,1-14.49927,14.49927h-.05317a14.49926,14.49926,0,0,0-14.49927,14.49926l0,20.16577a14.49928,14.49928,0,0,0,14.49926,14.49928h3.18309a14.49927,14.49927,0,0,1,14.49926,14.49927l0,20.166a14.49926,14.49926,0,0,1-14.49927,14.49926l-32.24,0a14.49926,14.49926,0,0,0-14.49927,14.49927l0,20.16587a14.49927,14.49927,0,0,0,14.49926,14.49927l407.58428.00018a14.49927,14.49927,0,0,0,14.49927-14.49927l0-20.16587a14.49927,14.49927,0,0,0-14.49927-14.49927H495.33186A14.49928,14.49928,0,0,1,480.83259,393.275l0-20.166a14.49926,14.49926,0,0,1,14.49927-14.49926l29.64256,0A14.49927,14.49927,0,0,0,539.4737,344.1105l0-20.16577a14.49928,14.49928,0,0,0-14.49927-14.49928h-8.99216A14.49927,14.49927,0,0,1,501.483,294.94618l0-20.166A14.49927,14.49927,0,0,1,515.98229,260.281l33.69855,0A14.49926,14.49926,0,0,0,564.18011,245.7817Z"
        />
        <line
          x1="263.789"
          x2="294.11"
          y1="261.065"
          y2="261.065"
          fill="none"
          stroke={PRIMARY_DARKER}
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <line
          x1="263.789"
          x2="294.11"
          y1="269.804"
          y2="269.804"
          fill="none"
          stroke={PRIMARY_DARKER}
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <line
          x1="263.789"
          x2="294.11"
          y1="278.543"
          y2="278.543"
          fill="none"
          stroke={PRIMARY_DARKER}
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <line
          x1="207.224"
          x2="237.545"
          y1="110.193"
          y2="110.193"
          fill="none"
          stroke="#b9d4db"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <line
          x1="207.224"
          x2="237.545"
          y1="118.932"
          y2="118.932"
          fill="none"
          stroke="#b9d4db"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <line
          x1="207.224"
          x2="237.545"
          y1="127.671"
          y2="127.671"
          fill="none"
          stroke="#b9d4db"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <line
          x1="480.243"
          x2="510.564"
          y1="250.374"
          y2="250.374"
          fill="none"
          stroke="#b9d4db"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <line
          x1="480.243"
          x2="510.564"
          y1="259.113"
          y2="259.113"
          fill="none"
          stroke="#b9d4db"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <line
          x1="480.243"
          x2="510.564"
          y1="267.852"
          y2="267.852"
          fill="none"
          stroke="#b9d4db"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <line
          x1="165.278"
          x2="195.598"
          y1="382.831"
          y2="382.831"
          fill="none"
          stroke="#b9d4db"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <line
          x1="165.278"
          x2="195.598"
          y1="391.57"
          y2="391.57"
          fill="none"
          stroke="#b9d4db"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <line
          x1="165.278"
          x2="195.598"
          y1="400.309"
          y2="400.309"
          fill="none"
          stroke="#b9d4db"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <circle cx="169.788" cy="247.596" r="15.42" fill="#b9d4db" />
        <circle cx="173.65" cy="216.388" r="8.032" fill="#b9d4db" />
        <circle cx="201.919" cy="217.243" r="4.633" fill="#b9d4db" />
        <circle cx="204.669" cy="242.953" r="11.107" fill="#b9d4db" />
        <circle cx="465.61" cy="418.656" r="15.42" fill="#b9d4db" />
        <circle cx="444.047" cy="395.768" r="8.032" fill="#b9d4db" />
        <circle cx="462.745" cy="374.548" r="4.633" fill="#b9d4db" />
        <circle cx="484.294" cy="388.837" r="11.107" fill="#b9d4db" />
        <circle cx="450.186" cy="158.049" r="15.42" fill="#b9d4db" />
        <circle cx="422.386" cy="135.161" r="8.032" fill="#b9d4db" />
        <circle cx="452.748" cy="127.127" r="11.107" fill="#b9d4db" />
        <circle cx="277.181" cy="353.023" r="15.42" fill={PRIMARY_DARKER} />
        <circle cx="255.617" cy="330.135" r="8.032" fill={PRIMARY_DARKER} />
        <circle cx="274.315" cy="308.915" r="4.633" fill={PRIMARY_DARKER} />
        <circle cx="295.864" cy="323.204" r="11.107" fill={PRIMARY_DARKER} />
        <path
          fill={PRIMARY_MAIN}
          d="M315.21739,153.87913c31.52794-44.42752,51.10091-84.69093,43.71722-89.93075l-8.4597-6.00342c-7.384-5.24-38.9281,26.52806-70.456,70.95558s-51.10069,84.69061-43.71669,89.93066l8.4597,6.00342C252.14557,230.07444,283.68946,198.30664,315.21739,153.87913Z"
        />
        <ellipse
          cx="293.388"
          cy="138.388"
          fill={PRIMARY_DARKER}
          rx="98.64"
          ry="16.394"
          transform="rotate(-54.639 293.388 138.388)"
        />
        <path
          fill={PRIMARY_MAIN}
          d="M299.71893,114.71322c8.77107,6.22438-17.06475,42.63087-25.83583,36.40649s-10.09788-19.42011-2.96352-29.47349S290.94785,108.48883,299.71893,114.71322Z"
        />
        <path
          fill={PRIMARY_DARKER}
          d="M237.545,252.60146,260.7,455.16169h.251c0,6.90581,33.68372,12.50387,75.23428,12.50387s75.23428-5.59806,75.23428-12.50387h.25137l23.155-202.56023Z"
        />
        <ellipse cx="336.185" cy="252.602" fill={PRIMARY_MAIN} rx="98.64" ry="16.394" />
        <rect width="8.428" height="147.137" x="334.764" y="298.096" fill={PRIMARY_MAIN} rx="2" />
        <path
          fill={PRIMARY_MAIN}
          d="M383.92143 445.42931l-5.32858-.73684c-.85055-.11762-1.49276-1.603-1.43442-3.3177L381.94156 300.792c.05834-1.7147.79514-3.00939 1.64569-2.89178l5.32857.73684c.85055.11761 1.49276 1.603 1.43442 3.3177l-4.78313 140.58274C385.50877 444.25222 384.772 445.54692 383.92143 445.42931zM290.96709 445.42931l5.32858-.73684c.85054-.11762 1.49275-1.603 1.43441-3.3177L292.947 300.792c-.05834-1.7147-.79514-3.00939-1.64569-2.89178l-5.32857.73684c-.85055.11761-1.49276 1.603-1.43442 3.3177l4.78313 140.58274C289.37975 444.25222 290.11655 445.54692 290.96709 445.42931z"
        />
        <path
          fill="#b9d4db"
          d="M379.49791,226.66982l-6.94,23.52921L312.5682,232.505a2.53423,2.53423,0,0,1-1.71377-3.14764L338.92967,134.171a2.53422,2.53422,0,0,1,3.14764-1.71376l81.1855,23.94568a2.53422,2.53422,0,0,1,1.71376,3.14764l-21.85222,74.08784Z"
        />
        <polygon
          fill="#8d09fe"
          points="372.558 250.199 379.498 226.67 403.124 233.638 372.558 250.199"
        />
        <rect
          width="31.171"
          height="14.23"
          x="344.771"
          y="157.876"
          fill="#8d09fe"
          transform="rotate(16.433 360.356 164.99)"
        />
        <line
          x1="382.743"
          x2="405.64"
          y1="171.593"
          y2="178.347"
          fill="none"
          stroke="#8d09fe"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <line
          x1="330.179"
          x2="353.077"
          y1="213.286"
          y2="220.039"
          fill="none"
          stroke="#8d09fe"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <line
          x1="340.255"
          x2="400.488"
          y1="178.052"
          y2="195.818"
          fill="none"
          stroke="#8d09fe"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <line
          x1="336.799"
          x2="397.032"
          y1="189.768"
          y2="207.534"
          fill="none"
          stroke="#8d09fe"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <line
          x1="333.343"
          x2="393.576"
          y1="201.484"
          y2="219.25"
          fill="none"
          stroke="#8d09fe"
          stroke-miterlimit="10"
          stroke-width="3"
        />
      </svg>
    </Box>
  );
}

export default memo(PageDeleteAccountIllustration);
