import { useContext } from 'react';
import { CollapseDrawerContext } from '../contexts/CollapseDrawerContext';

// ----------------------------------------------------------------------

const useCollapseDrawer = () => useContext(CollapseDrawerContext);

export const useCollapseEventDrawer = () => useContext(CollapseDrawerContext);

export const useCollapseOrgDrawer = () => useContext(CollapseDrawerContext);

export default useCollapseDrawer;
