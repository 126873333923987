// @mui
import { enUS, frFR, esES, ptPT, deDE } from '@mui/material/locale';
// components
import { SettingsValueProps } from '../components/settings/type';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../routes/paths';

// API
// ----------------------------------------------------------------------

export const PLATFORM_FEE_PERCENTAGE = 2.5;
export const FIXED_FEE = 0.25;
export const HOST_API = process.env.REACT_APP_HOST_API || '';

export const FIREBASE_API = {
  dev: {
    apiKey: 'AIzaSyBQ_sZbDCo2BPdXzL4Oj9ALlxMnXB_MMNk',
    authDomain: 'instadrink-47f0f.firebaseapp.com',
    projectId: 'instadrink-47f0f',
    storageBucket: 'instadrink-47f0f.appspot.com',
    messagingSenderId: '138073739996',
    appId: '1:138073739996:web:84d834292c5b2e8614da19',
    //measurementId: 'G-MVQ7F6WYWQ',
    ignoreUndefinedProperties: true,
  },
  hom: {
    apiKey: 'AIzaSyBBoMYDHQuqgmebORavxZD0fIPjtMDdE54',
    authDomain: 'instadrinkuat.firebaseapp.com',
    projectId: 'instadrinkuat',
    storageBucket: 'instadrinkuat.appspot.com',
    messagingSenderId: '946898901720',
    appId: '1:946898901720:web:5a09225ffb3dd90d3c95ad',
    ignoreUndefinedProperties: true,
  },
  prod: {
    apiKey: 'AIzaSyDfb1H_l5hEc_IY80ikV7Lwrrt5J4d6qx8',
    authDomain: 'instadrink-eecf1.firebaseapp.com',
    projectId: 'instadrink-eecf1',
    storageBucket: 'instadrink-eecf1.appspot.com',
    messagingSenderId: '256281800493',
    appId: '1:256281800493:web:0053e8e64de88e3625eda4',
    //measurementId: 'G-4WG2SB2LB6',
    ignoreUndefinedProperties: true,
  },
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.evenementiel.events; // as '/dashboard/app'

export const PATH_AFTER_REFERRAL_LOGIN = PATH_DASHBOARD.evenementiel.eventsList;

export const PATH_AFTER_AMBASSADOR_LOGIN = PATH_DASHBOARD.evenementiel.ambassadorEvents;

export const PATH_LOGIN = PATH_AUTH.login;

export const PATH_AFTER_WAITER_LOGIN = PATH_DASHBOARD.restauration.tablesList;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  DASHBOARD_COLLAPSE_WIDTH_EVENT: 100,

  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'dark',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'default',
  themeStretch: false,
  fieldType: 'event',
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'French',
    value: 'fr',
    systemValue: frFR,
    icon: '/assets/icons/flags/ic_flag_fr.svg',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'Spanish',
    value: 'es',
    systemValue: esES,
    icon: '/assets/icons/flags/ic_flag_es.svg',
  },
  {
    label: 'Portuguese',
    value: 'pt',
    systemValue: ptPT,
    icon: '/assets/icons/flags/ic_flag_pt.svg',
  },
  {
    label: 'German',
    value: 'gr',
    systemValue: deDE,
    icon: '/assets/icons/flags/ic_flag_de.svg',
  },
  /*   {
    label: 'Vietnamese',
    value: 'vn',
    systemValue: viVN,
    icon: '/assets/icons/flags/ic_flag_vn.svg',
  },
  {
    label: 'Chinese',
    value: 'cn',
    systemValue: zhCN,
    icon: '/assets/icons/flags/ic_flag_cn.svg',
  },
  {
    label: 'Arabic (Sudan)',
    value: 'ar',
    systemValue: arSD,
    icon: '/assets/icons/flags/ic_flag_sa.svg',
  }, */
];

export const defaultLang = allLangs[0]; // French

const configRes = {
  /*
    theme: 'jade' | 'redOrange' | 'blueberry'
   */
  dev: {
    curtheme: 'jade',
    api: {
      customers: {
        create:
          'https://europe-west1-instadrink-47f0f.cloudfunctions.net/app/stripe/customers/create',
      },
      pms: {
        get: 'https://europe-west1-instadrink-47f0f.cloudfunctions.net/app/stripe/customers/getpms',
      },
      accounts: {
        get: 'https://europe-west1-instadrink-47f0f.cloudfunctions.net/app/stripe/account/get',

        create:
          'https://europe-west1-instadrink-47f0f.cloudfunctions.net/app/stripe/account/create',
        createLink:
          'https://europe-west1-instadrink-47f0f.cloudfunctions.net/app/stripe/accountlink/create',
        createLoginLink:
          'https://europe-west1-instadrink-47f0f.cloudfunctions.net/app/stripe/accountloginlink/create',
      },
      checkout: {
        create:
          'http://localhost:5000/instadrink-47f0f/europe-west1/app/web/stripe/checkout/sessions/create',
        refund:
          'https://europe-west1-instadrink-47f0f.cloudfunctions.net/app/stripe/checkout/sessions/refund?sessionid=',
      },
    },
    paiement: {
      publishableKey:
        'pk_test_51Iss0qCTdpEbBR7uJaxn7WWXmfWVqliIjTE8zehaxGH0SCI2Q2AkpCGOOrLTjbzwfbHCVLrCxRo5xyuFALXd0MBm00Pfkab9Nj',
    },
  },
  hom: {
    curtheme: 'blueberry',
    api: {
      customers: {
        create: 'https://europe-west1-instadrinkuat.cloudfunctions.net/app/stripe/customers/create',
      },
      pms: {
        get: 'https://europe-west1-instadrinkuat.cloudfunctions.net/app/stripe/customers/getpms',
      },
      receipt: {
        get: 'https://europe-west1-instadrinkuat.cloudfunctions.net/app/stripe/receipt',
      },
      accounts: {
        get: 'http://localhost:5000/instadrinkuat/europe-west1/app/stripe/account/get',
        create: 'http://localhost:5000/instadrinkuat/europe-west1/app/stripe/account/create',
        createLink:
          'http://localhost:5000/instadrinkuat/europe-west1/app/stripe/accountlink/create',
        createLoginLink:
          'http://localhost:5000/instadrinkuat/europe-west1/app/stripe/accountloginlink/create',
      },
      checkout: {
        create:
          'https://europe-west1-instadrinkuat.cloudfunctions.net/app/stripe/checkout/sessions/create',
      },
    },
  },
  prod: {
    curtheme: 'jade',
    api: {
      customers: {
        create:
          'https://europe-west1-instadrink-eecf1.cloudfunctions.net/app/stripe/customers/create',
      },
      pms: {
        get: 'https://europe-west1-instadrink-eecf1.cloudfunctions.net/app/stripe/customers/getpms',
      },
      accounts: {
        get: 'https://europe-west1-instadrink-eecf1.cloudfunctions.net/app/stripe/account/get',

        create:
          'https://europe-west1-instadrink-eecf1.cloudfunctions.net/app/stripe/account/create',
        createLink:
          'https://europe-west1-instadrink-eecf1.cloudfunctions.net/app/stripe/accountlink/create',
        createLoginLink:
          'http://localhost:5000/instadrinkuat/europe-west1/app/stripe/accountloginlink/create',
      },
      checkout: {
        create:
          'https://europe-west1-instadrink-eecf1.cloudfunctions.net/app/web/stripe/checkout/sessions/create',
        refund:
          'https://europe-west1-instadrink-eecf1.cloudfunctions.net/app/stripe/checkout/sessions/refund?sessionid=',
      },
    },
    paiement: {
      publishableKey:
        'pk_live_51Iss0qCTdpEbBR7uyPtzbm4EAwGhlvLC6lv7xrh5HdEwnrkl0eLjcCwFUSkdM48Fz6A04gVyouqoDbyNykvK5ziW00XKEjYYP9',
    },
  },
};

export const getEnvVars = () => {
  // What is __DEV__ ?
  // This variable is set to true when react-native is running in Dev mode.
  // __DEV__ is true when run locally, but false when published.
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_FIREBASE_PROJECT === 'instadrinkuat'
  ) {
    return configRes.prod;
  } else if (process.env.REACT_APP_FIREBASE_PROJECT === 'instadrinkuat') {
    return configRes.hom;
  } else if (process.env.REACT_APP_FIREBASE_PROJECT === 'instadrink-47f0f') {
    return configRes.dev;
  } else {
    return configRes.prod;
  }
};
