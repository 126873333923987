// @mui
import { styled, useTheme } from '@mui/material/styles';
import { List, Box, ListSubheader, Typography, Collapse } from '@mui/material';
// type
import { NavSectionProps } from '../type';
//
import { NavListRoot } from './NavList';

import navConf from 'src/layouts/dashboard/navbar/NavConfig';
// import useAuth from 'src/hooks/useAuth';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { t } from 'i18next';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSettings from 'src/hooks/useSettings';

// ----------------------------------------------------------------------
// index.tsx (17-31)
interface ListSubheaderStyleProps extends React.ComponentProps<typeof ListSubheader> {
  onClick?: () => void;
}

export const ListSubheaderStyle = styled((props: ListSubheaderStyleProps) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme, onClick }) => ({
  ...theme.typography.overline,
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
  ...(onClick && {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
    // disable text selection
    userSelect: 'none',
  }),
}));

// ----------------------------------------------------------------------

export default function NavSectionVertical({
  navConfig,
  isCollapse = false,
  ...other
}: NavSectionProps) {
  // const { user } = useAuth();
  // const userRole = user?.role;
  const theme = useTheme();
  const { fieldType } = useSettings();

  let navConfigItems = [];
  navConfigItems = fieldType === 'event' ? navConf.navConfigEvent : navConf.navConfigRestaurant;
  const violet = theme.palette.chart.violet[theme.palette.mode === 'light' ? 2 : 1];

  // index.tsx (44-53)
  const location = useLocation();
  const isEventUrlActive = location.pathname.includes('evenementiel');
  const isRestaurantUrlActive = location.pathname.includes('restauration');
  const [openedGroups, setOpenedGroups] = useState<{ [key: string]: boolean }>({
    Evenementiel: true,
    Restauration: true,
    Network: true,
    Account: true,
  });

  const handleToggle = useCallback((groupKey: string) => {
    setOpenedGroups((prevState) => ({
      ...prevState,
      [groupKey]: !prevState[groupKey],
    }));
  }, []);

  return (
    <Box {...other}>
      {navConfigItems.map((group) => (
        <PermissionBasedGuard
          key={group.subheader}
          hasContent={false}
          allRequiredPermissionList={group.allRequiredPermissionList ?? []}
          atLeastOneRequiredPermissionList={group.atLeastOneRequiredPermissionList ?? []}
          greenPassPermissionList={group.greenPassPermissionList ?? []}
          greenPassRoleList={group.greenPassRoleList ?? []}
        >
          <List key={group.subheader} disablePadding sx={{ px: 2 }}>
            {group.items.length > 0 && (
              <ListSubheaderStyle
                onClick={() => handleToggle(group.subheader)}
                sx={{
                  // ...(isCollapse && {
                  //   opacity: 0,
                  // }),
                  // m: 1,
                  mt: 2,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box mr={1} display="flex" alignItems="center" color={violet} sx={{ fontSize: 20 }}>
                  {group.subheaderIcon}
                </Box>
                {!isCollapse && (
                  <Typography color={violet} variant="button">
                    {t(group.subheader)}
                  </Typography>
                )}
              </ListSubheaderStyle>
            )}
            <Collapse in={openedGroups[group.subheader]} timeout="auto" unmountOnExit>
              {group.items.map((item) => (
                <PermissionBasedGuard
                  key={item.title + item.path}
                  hasContent={false}
                  allRequiredPermissionList={item.allRequiredPermissionList ?? []}
                  atLeastOneRequiredPermissionList={item.atLeastOneRequiredPermissionList ?? []}
                  greenPassPermissionList={item.greenPassPermissionList ?? []}
                  greenPassRoleList={item.greenPassRoleList ?? []}
                >
                  <NavListRoot key={item.title + item.path} list={item} isCollapse={isCollapse} />
                </PermissionBasedGuard>
              ))}
            </Collapse>
          </List>
        </PermissionBasedGuard>
      ))}
    </Box>
  );
}
