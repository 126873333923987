// ----------------------------------------------------------------------

// FALLS DIESE ÜBERSETZUNG FALSCH IST, IGNORIEREN SIE DIES BITTE, DA DIESE ÜBERSETZUNG NUR FÜR DEMOZWECKE BESTIMMT IST
// Wir freuen uns, wenn Sie uns bei der Verbesserung der Übersetzung helfen können, indem Sie eine E-Mail an instadrinkpro@gmail.com senden.

// ----------------------------------------------------------------------

const gr = {
  demo: {
    title: `Deutsch`,
    introduction: `Lorem Ipsum ist einfach Dummy-Text der Druck- und Satzindustrie. Lorem Ipsum ist seit dem 16. Jahrhundert der Standard-Dummy-Text der Branche, als ein unbekannter Drucker eine Galeere mit Typen nahm und sie durcheinanderwürfelte, um ein Musterbuch zu erstellen. Es hat nicht nur fünf Jahrhunderte überlebt, sondern auch den Sprung in den elektronischen Satz überdauert und ist im Wesentlichen unverändert geblieben. Es wurde in den 1960er Jahren mit der Veröffentlichung von Letraset-Blättern mit Lorem Ipsum-Passagen populär und in jüngerer Zeit mit Desktop-Publishing-Software wie Aldus PageMaker, die Versionen von Lorem Ipsum enthält.`,
  },
  docs: {
    hi: `Hallo`,
    description: `Brauchen Sie Hilfe? \n Bitte schauen Sie in unsere Dokumentation.`,
    documentation: `Dokumentation`,
  },
  app: `App`,
  user: `Benutzer`,
  list: `Liste`,
  edit: `Bearbeiten`,
  shop: `Shop`,
  blog: `Blog`,
  post: `Beitrag`,
  mail: `E-Mail`,
  chat: `Chat`,
  cards: `Karten`,
  posts: `Beiträge`,
  create: `Erstellen`,
  kanban: `Kanban`,
  general: `Allgemein`,
  banking: `Banking`,
  booking: `Buchung`,
  profile: `Profil`,
  account: `Konto`,
  product: `Produkt`,
  invoice: `Rechnung`,
  details: `Details`,
  checkout: `Zur Kasse`,
  calendar: `Kalender`,
  analytics: `Analysen`,
  ecommerce: `E-Commerce`,
  management: `Verwaltung`,
  menu_level_1: `Menüebene 1`,
  menu_level_2: `Menüebene 2`,
  menu_level_3: `Menüebene 3`,
  item_disabled: `Element deaktiviert`,
  item_label: `Element-Label`,
  item_caption: `Element-Beschriftung`,
  description: `Beschreibung`,
  other_cases: `Andere Fälle`,
  item_by_roles: `Element nach Rollen`,
  only_admin_can_see_this_item: `Nur der Administrator kann dieses Element sehen`,
  create_event: 'Event erstellen',
  label_Rows_Per_Page: 'Zeilen pro Seite',
  created_At: 'Erstellt am',
  location: 'Ort',
  title: 'Titel',
  events: 'Events',
  archived: 'Archiviert',
  canceled: 'Abgesagt',
  Start_Date: 'Event-Startdatum',
  End_Date: 'Event-Enddatum',
  organisation: 'Organisation',
  organisations: 'Organisationen',
  published_events: 'Veröffentlichte Events',
  Add_event: 'Event hinzufügen',
  event_name: 'Event-Name',
  summary: 'Zusammenfassung',
  cancel: 'Abbrechen',
  add: 'Hinzufügen',
  view_event: 'Event anzeigen ',
  event_title: 'Event-Titel',
  cover: 'Cover',
  cover_image: 'Titelbild',
  category: 'Kategorie',
  sub_category: 'Unterkategorie',
  status: 'Status',
  listed: 'Gelistet',
  online_event: 'Online-Event',
  tags: 'Tags',
  address: 'Adresse',
  city: 'Stadt',
  capacity: 'Kapazität',
  save: 'Speichern',
  Start_Time: 'Event-Startzeit',
  End_Time: 'Event-Endzeit',
  timezone: 'Zeitzone',
  Save_Changes: 'Änderungen speichern',
  Quantity: 'Menge',
  Initial_Quantity: 'Verfügbare Menge',
  Max_Quantity: 'Max. pro Bestellung',
  price: 'Preis',
  free: 'Kostenlos',
  Sell_Time_Limit: 'Verkaufszeitlimit',
  delete: 'Löschen',
  Edit: 'Bearbeiten',
  Add_Ticket: 'Ticket hinzufügen',
  Sell_limit: 'Verkaufslimit',
  start: 'Start',
  end: 'Ende',
  Access_Time_Limit: 'Event-Zugriffszeit',
  Price_Quantity: 'Preis und Menge',
  Access_limit: 'Zugriffslimit',
  name: 'Name',
  Your_event_is_already_published_on_this_link: 'Ihr Event ist bereits unter diesem Link veröffentlicht',
  archive: 'Archivieren',
  Event_Dashboard: 'Event-Dashboard',
  Preview_Event: 'Event-Vorschau',
  publish: 'Veröffentlichen',
  Email_Message: 'Text der E-Mail, die an Ihre Event-Teilnehmer gesendet wird',
  Ticket_Message: 'Ticket-Nachricht',
  include: 'Einschließen',
  required: 'Erforderlich',
  Gender: 'Geschlecht',
  Birthday: 'Geburtstag',
  HomePhone: 'Festnetz',
  yourEventIs: 'Ihr Event ist ',
  date: 'Datum',
  events_catering: 'Event und Catering',
  events_catering_description:
    'ID ist eine Plattform, die es Ihren Kunden ermöglicht, Tickets für Ihre Events zu kaufen und ihre Speisen und Getränke in Ihrem Etablissement digital zu bestellen',
  create_digital_exp: 'Erstellen Sie Ihr digitales Erlebnis',
  create_digital_exp_description:
    'Kunden interagieren mit Ihrer Marke auf Ihre Weise; Sie kontrollieren Ihre digitale Präsenz, um Umsatz und Rentabilität zu maximieren.',
  digital_ticketing: 'DIGITALES TICKETING',
  digital_ticketing_title: 'Fügen Sie Ihre Events hinzu und verkaufen Sie Tickets auf unserer Plattform.',
  digital_ticketing_description:
    'Jeder Ihrer Kunden, der teilnehmen möchte, kann auf einen Link klicken oder einen QR-Code auf dem digitalen oder physischen Flyer Ihres Events scannen; dies leitet sie zur ID-Plattform, wo sie ihr Ticket auswählen und bezahlen können.',
  more_questions: 'Haben Sie noch Fragen?',
  more_questions_description: 'Bitte beschreiben Sie Ihren Fall, um die genaueste Beratung zu erhalten.',
  who_are_we: {
    who: 'Wer',
    are: 'sind',
    we: 'wir',
    work: 'Gemeinsam arbeiten für',
    futur: 'eine bessere Zukunft in Events und Catering',
  },
  what_is_id: {
    title: 'Was ist ID?',
    description:
      'ID ist eine Plattform, die es Ihren Kunden ermöglicht, Tickets für Ihre Events zu kaufen und ihre Speisen und Getränke in Ihrem Etablissement digital zu bestellen.',
  },
  about: {
    vision: 'Unsere Vision: Die perfekte Plattform für eine bessere Zukunft in Events und Catering anbieten.',
    dream_team: 'DREAM TEAM',
    dream_team_description:
      'D wird Ihnen helfen, wenn Sie auf Probleme stoßen. Unser Support-Team wird antworten. Ein gutes Team ist der Schlüssel. Innerhalb eines Tages, und wir haben auch eine detaillierte Dokumentation.',
  },
  testimonials: {
    title: 'TESTIMONIALS',
    who: 'Wer mag',
    service: ' unseren Service?',
    description:
      "Unser Ziel ist es, die perfekte Plattform zu schaffen, die alle unsere Kunden im Event- und Cateringbereich zufriedenstellt. Deshalb arbeiten wir ständig an unseren Dienstleistungen, um sie jeden Tag zu verbessern, indem wir auf die wertvollen Vorschläge hören, die unsere Nutzer mit uns teilen.",
  },
  Min_Quantity: 'Min. pro Bestellung',
  save_continue: 'Speichern und fortfahren',
  continue_later: "Nicht speichern und später fortfahren",
  Link_to_buy_tickets_for_your_event: 'Link zum Ticketkauf für Ihr Event',
  back: 'Zurück',
  Complete_the_details_of_the_event_to_be_able_to_publish_it:
    'Vervollständigen Sie die Details des Events, um es veröffentlichen zu können',
  publish_the_event: 'Event veröffentlichen',
  view: 'Ansehen',
  ready_events: 'Fertige Events',
  drafted_events: 'Entwürfe',
  archived_events: 'Archivierte Events',
  canceled_events: 'Abgesagte Events',
  no_events: 'Keine Events',
  my_events: 'Meine Events',
  total_sold: 'Gesamtumsatz',
  sold_quantity: 'Anzahl verkaufter Tickets',
  event_link: 'Event-Link',
  sale_date: 'Verkaufsdatum',
  validated: 'Validiert',
  confirmed: 'Bestätigt',
  reimbursed: 'Erstattet',
  About: 'Über',
  Name: 'Name',
  promoters: 'PromoBooster',
  add_promoter: 'PromoBooster hinzufügen',
  choose_promoter: 'PromoBooster auswählen',
  remove: 'Entfernen',
  min_followers: 'Mindestanzahl Follower',
  Dashboard: 'Dashboard',
  General: 'Allgemein',
  Preview: 'Vorschau',
  required_information: 'Erforderliche Informationen',
  promo_code: 'Promo-Codes',
  overview: 'Übersicht',
  preview_your_event: 'Event-Vorschau',
  Owner: 'Eigentümer',
  create_org: 'Organisation erstellen',
  Account: 'Konto',
  account_settings: 'Kontoeinstellungen',
  Allowed_format: 'Erlaubte Formate',
  max_size: 'max. Größe von',
  billing: 'Abrechnung',
  Email_Address: 'E-Mail-Adresse',
  Phone_Number: 'Telefonnummer',
  Country: 'Land',
  State_Region: 'Bundesland/Region',
  Zip_Code: 'PLZ',
  discount: 'Rabatt',
  maximum_number_of_sold_tickets: 'maximale Anzahl verkaufter Tickets',
  Featured_tickets: 'Hervorgehobene Tickets',
  No_promo_code_available: 'Kein Promo-Code verfügbar',
  add_promo_code: 'Promo-Code hinzufügen',
  This_code_is_already_used: 'Dieser Code wird bereits verwendet!',
  discount_amount: 'Rabattbetrag',
  advanced_options: 'Erweiterte Optionen',
  all_tickets: 'Alle Tickets',
  specific_tickets: 'Spezifische Tickets',
  Legal_info: 'Rechtliche Informationen',
  Followers: 'Follower',
  Total_events: 'Gesamtanzahl Events',
  Recommended: 'Empfohlen',
  company_name: 'Firmenname',
  company_number: 'Firmennummer',
  Website: 'Website',
  Cover: 'Titelbild',
  add_organisation: 'Organisation hinzufügen',
  create_user: 'Benutzer erstellen',
  users: 'Benutzer',
  firstname: 'Vorname',
  lastname: 'Nachname',
  role: 'Rolle',
  access: 'Zugriff',
  events_permissions: 'Event-Berechtigungen',
  organisations_permissions: 'Organisations-Berechtigungen',
  Yes: 'Ja',
  No: 'Nein',
  Permissions: 'Berechtigungen',
  Editing: 'Bearbeitung',
  Roles: 'Rollen',
  Search: 'Suchen',
  Event_stats: 'Event-Statistiken',
  sales: 'Verkäufe',
  Number_of_purchase: 'Anzahl der Käufe',
  Link: 'Link',
  sold: 'verkauft',
  available: 'verfügbar',
  Tickets_status: 'Ticket-Status',
  There_is_no_tickets_status_for_this_event: 'Es gibt keinen Ticket-Status für dieses Event',
  promoter: 'PromoBooster',
  invite_user: 'Benutzer einladen',
  published: 'Veröffentlicht',
  ready: 'Bereit',
  draft: 'Entwurf',
  initial: 'Initial',
  upcoming: 'Bevorstehend',
  ongoing: 'Laufend',
  past: 'Vergangen',
  Organisation: 'Organisation',
  Bio: 'Bio',
  Type: 'Typ',
  Evenementiel: 'Events',
  Restaurant: 'Restaurant',
  Email: 'E-Mail',
  Rajoutez_une_image_de_couverture_pour_votre_événement: 'Fügen Sie ein Titelbild für Ihr Event hinzu',
  Upload_photo: 'Foto hochladen',
  Update_photo: 'Foto aktualisieren',
  organisation_users: 'Organisationsbenutzer',
  Agent_liés_à_lorganisation: 'Mit der Organisation verbundene Agenten',
  Agents_en_attente_de_confirmation: 'Agenten warten auf Bestätigung',
  Instadrink_admin: 'I.D-Administrator',
  create_role: 'Rolle erstellen',
  Predefined_roles: 'Vordefinierte Rollen',
  Custom_roles: 'Benutzerdefinierte Rollen',
  Agents: 'Agenten',
  Organizer: 'Organisator',
  Bouncer: 'Türsteher',
  Ambassador: 'Botschafter',
  promotion_groups: 'PromoBooster-Gruppen',
  Dates: 'Termine',
  Tickets: 'Tickets',
  Messages: 'Nachrichten',
  Promotion: 'Werbung',
  Participants: 'Teilnehmer',
  Client: 'Kunde',
  description_placeholder:
    'Beschreiben Sie Ihren zukünftigen Teilnehmern die außergewöhnliche Atmosphäre, die bei Ihrer Veranstaltung herrschen wird.',
  Tickets_collection: 'Ticketsammlung',
  Stop_sell_time_is_at: 'Verkaufsstopp ist um',
  drag_and_drop: 'Ziehen und ablegen, um die Reihenfolge zu ändern',
  delete_ticket_confirmation: 'Sind Sie sicher, dass Sie dieses Ticket löschen möchten?',
  Access_Time_Thresholdt: 'Endzeitpunkt für den Veranstaltungszugang',
  order: 'Bestellung',
  Créez_les_tickets_pour_votre_événement: 'Erstellen Sie Tickets für Ihre Veranstaltung',
  stop_sell_time_error_message:
    'Das Enddatum muss nach dem Startdatum liegen und darf das Verkaufsstopp-Datum nicht überschreiten',
  Reset: 'Zurücksetzen',
  send_invitation: 'Einladung senden',
  Tickets_quantity_error_message: 'Plätze sind noch verfügbar',
  Write_something_awesome: 'Schreiben Sie etwas Großartiges...',
  Code: 'Code',
  delete_promocode_confirm_message: 'Sind Sie sicher, dass Sie diesen Code löschen möchten?',
  Activate_promotion: 'Werbung aktivieren',
  Desactivate_promotion: 'Werbung deaktivieren',
  Configure_RP_offer: 'PromoBooster-Angebot konfigurieren',
  promotion_status: 'Werbungsstatus',
  TooltipTextForPromotionGroups:
    'In diesem Abschnitt können Sie Ressourcenpersonen-Gruppen (RPs) für Ihre Veranstaltungen erstellen und verwalten',
  TooltipTextForEventLink: 'Veröffentlichen Sie Ihre Veranstaltung, um den Veranstaltungslink zu erhalten',
  TooltipTextForPromotionGroupsForReferral:
    'In diesem Abschnitt finden Sie die PromoBooster-Gruppen, denen Sie angehören, und Sie können die Gruppe jederzeit verlassen.',
  confirm_delete_promoter: 'Sind Sie sicher, dass Sie diesen PromoBooster löschen möchten?',
  Preview_promoter_link: 'Vorschau des PromoBooster-Links',
  Locked: 'Gesperrt',
  pending: 'ausstehend',
  accepted: 'akzeptiert',
  rejected: 'abgelehnt',
  Publish_my_event_now: 'Meine Veranstaltung jetzt veröffentlichen',
  publish_later: 'Später veröffentlichen',
  Your_event_is_still_draft:
    'Ihre Veranstaltung ist noch ein Entwurf, vervollständigen Sie die Konfiguration, um sie zu veröffentlichen',
  Are_you_sure_to_publish_the_event: 'Sind Sie sicher, dass Sie die Veranstaltung veröffentlichen möchten ',
  pourcentage: 'Prozentsatz',
  Rate: 'Rate',
  Message: 'Nachricht',
  submit: 'Absenden',
  Create_group: 'Gruppe erstellen',
  Groups: 'Gruppen',
  Edit_group: 'Gruppe bearbeiten',
  Search_by_Name: 'Nach Namen suchen',
  Enter_group_name: 'Gruppennamen eingeben',
  No_promoters_found: 'Keine Promoter gefunden',
  Add_group: 'Gruppe hinzufügen',
  Number_of_sales: 'Anzahl der Verkäufe',
  Turnover: 'Umsatz',
  Number_of_clients: 'Anzahl der Kunden',
  Promotion_requests: 'Werbe-Anfragen',
  Requests: 'Anfragen',
  Notifications: 'Benachrichtigungen',
  Referral_requests: 'PromoBooster-Anfragen',
  no_requests: 'Es gibt keine Anfragen',
  request_message: 'hat Ihnen eine Anfrage gesendet, um Sie als PromoBooster einzuladen für',
  Accept: 'Akzeptieren',
  Reject: 'Ablehnen',
  Your_invitation_to: 'Ihre Einladung zu',
  on: 'am',
  at: 'um',
  is: 'ist',
  promotion_rate: 'Werbesatz',
  consigne: 'Anweisung',
  Cancel_request: 'Anfrage abbrechen',
  Send_request: 'Anfrage senden',
  No_events: 'Finden Sie eine Veranstaltung, bei der Sie als PromoBooster zusammenarbeiten möchten',
  Search_for_new_events: 'Nach neuen Veranstaltungen suchen',
  Search_for_Events: 'Nach Veranstaltungen suchen',
  Sales_stats: 'Verkaufsstatistiken',
  Total_sales: 'Gesamtverkäufe',
  Total_reservation: 'Gesamtreservierungen',
  Bienvenue_message: 'Willkommen bei I.D, wählen Sie Ihren Bereich',
  second_bienvenu_message:
    'Willkommen bei I.D, wählen Sie Ihre Branche zwischen Veranstaltungen und Catering.',
  Continue: 'Fortfahren',
  Event: 'Veranstaltung',
  orga_message: 'Wählen Sie die Organisation, unter der Sie Ihre Veranstaltung erstellen werden',
  Skip: 'Überspringen',
  All_steps_completed: 'Alle Schritte abgeschlossen',
  you_have_finished: 'Sie haben abgeschlossen',
  limit_sell_message: 'Geben Sie ein Limit-Datum und -Uhrzeit für den Ticketverkauf ein',
  stopSellDate: 'Verkaufsstopp-Datum für Tickets',
  status_tooltip:
    'Dieses Statusfeld berechnet automatisch das Start- und Enddatum und gibt Ihnen den Veranstaltungsstatus',
  Step: 'Schritt',
  correct_start_date: 'Korrigieren Sie das vergangene Startdatum',
  Finish: 'Abschließen',
  event_name_tooltip: 'Wie lautet der Name Ihrer Veranstaltung?',
  event_summary_tooltip:
    'Geben Sie einen Einblick in die schönen Überraschungen, die zukünftige Teilnehmer bei Ihrer Veranstaltung erwarten.',
  event_description_tooltip:
    'Beschreiben Sie diesmal detailliert die Veranstaltung, an der zukünftige Teilnehmer teilnehmen werden.',
  event_cover_tooltip: 'Wählen Sie ein Foto für Ihre Veranstaltung.',
  organisation_tooltip: 'Wählen Sie den Namen der Organisation, die Ihre Veranstaltung ausrichten wird.',
  category_tooltip: 'Wählen Sie eine Kategorie für Ihre Veranstaltung.',
  subCategory_tooltip: 'Wählen Sie eine Unterkategorie für Ihre Veranstaltung.',
  Rp_request: 'hat Ihnen eine Anfrage gesendet, um PromoBooster seiner Veranstaltung zu werden',
  warning_message: 'Bitte drücken Sie zuerst "Hinzufügen", um Ihr Ticket zu speichern und fortzufahren',
  correct_date_tooltip: 'Das ausgewählte Startdatum für Ihre Veranstaltung liegt bereits in der Vergangenheit.',
  step1: 'In diesem Schritt finden Sie die Verkaufsstatistiken der Veranstaltung und die Teilnehmerliste für die Veranstaltung.',
  step2: 'In diesem Schritt können Sie die Details der Veranstaltung bearbeiten.',
  step3: 'In diesem Schritt können Sie die Daten und Zeiten der Veranstaltung bearbeiten.',
  step4: 'In diesem Schritt können Sie neue Tickets für Ihre Veranstaltung hinzufügen oder bestehende Tickets bearbeiten.',
  step5: 'In diesem Schritt können Sie Ihre Veranstaltung veröffentlichen oder archivieren.',
  step6: 'In diesem Schritt finden Sie die Veranstaltungsansicht für Kunden in der Desktop- oder Mobilansicht.',
  step7: 'In diesem Schritt finden Sie die Übersicht über Ihre Veranstaltungsdetails.',
  step8: 'In diesem Schritt können Sie Informationen über die Veranstaltung hinzufügen.',
  stepCodePromo: 'In diesem Schritt können Sie Promo-Codes für die Tickets hinzufügen.',
  step10:
    'In diesem Schritt können Sie die RPs für Ihre Veranstaltung verwalten, eine Gruppe von RPs erstellen oder Anfragen an den PromoBooster senden, um PromoBooster Ihrer Veranstaltung zu werden.',
  step11: 'In diesem Schritt finden Sie die Liste der Teilnehmer.',
  total_sold_tooltip: 'Dies ist der Gesamtverkaufspreis für die Veranstaltungstickets',
  sold_quantity_tooltip: 'Dies ist die Gesamtverkaufsmenge für die Veranstaltungstickets',
  link_tooltip: 'Dies ist der Veranstaltungslink zum Kauf Ihrer Tickets.',
  table_tooltip: 'Hier ist die Tabelle, in der Sie die Liste der Teilnehmer für diese Veranstaltung finden.',
  create_event_button_tooltip: 'Klicken Sie hier, um eine neue Veranstaltung zu erstellen.',
  events_table_tooltip: 'Dies ist die Veranstaltungstabelle, in der Sie Ihre Veranstaltungsdetails finden können.',
  You_have_no_organisations: 'Sie haben keine Organisationen',
  alert_orga_title: 'Sie müssen eine Organisation erstellen, damit Sie danach eine Veranstaltung erstellen können.',
  Configurate_here: 'Hier konfigurieren',
  legal_verification: 'Rechtliche Überprüfung Ihres Kontos',
  alert_stripe_configuration_title:
    'Bitte vervollständigen Sie Ihre rechtlichen Informationen, um Ihr Konto zu aktivieren und Ihre Tickets verkaufen zu können.',
  Bank_balance: 'Bankguthaben',
  View_my_Stripe_account: 'Mein Stripe-Konto anzeigen',
  Your_balance: 'Ihr Guthaben',
  Add_new_card: 'Neue Karte hinzufügen',
  Name_on_card: 'Name auf der Karte',
  Card_number: 'Kartennummer',
  Expiration_date: 'Ablaufdatum',
  QRcode_tooltip: 'Klicken Sie hier, um den QR-Code Ihrer Veranstaltung anzupassen und herunterzuladen',
  File_Type: 'Dateityp',
  Size: 'Größe',
  Color: 'Farbe',
  Background_Color: 'Hintergrundfarbe',
  Download_QR_Code: 'QR-Code herunterladen',
  Download_Invoice: 'Rechnung herunterladen',
  show_qr_code: 'QR-Code anzeigen',
  Close: 'Schließen',
  Customize_QR_Code: 'QR-Code anpassen',
  no_users_yet: 'Noch keine Benutzer',
  plz_invite_users: 'Bitte laden Sie Benutzer ein',
  invite_organizer: 'Organisator einladen',
  invite: 'Einladen',
  settings: 'Einstellungen',
  invite_owners: 'Besitzer einladen',
  register: 'Registrieren',
  password: 'Passwort',
  confirm_password: 'Passwort bestätigen',
  email_adress: 'E-Mail-Adresse',
  division_agent: 'Agent',
  division_promoter: 'Promoter',
  iam_owner: 'Ich bin ein Besitzer',
  iam_owner_desc: 'Verwalten Sie Ihre Organisationen, Restaurants und Veranstaltungen',
  iam_amb: 'Ich bin ein Botschafter',
  iam_amb_desc: 'Fördern Sie Organisationen und verdienen Sie Provisionen',
  iam_ref: 'Ich bin ein PromoBooster',
  iam_ref_desc: 'Empfehlen Sie Veranstaltungen und verdienen Sie Provisionen',
  start_free: 'Kostenlos starten',
  no_credit_card: 'Keine Kreditkarte erforderlich',
  have_account: 'Haben Sie bereits ein Konto?',
  by_register_1: 'Mit der Registrierung stimme ich den I.D ',
  by_register_2: 'Nutzungsbedingungen',
  by_register_3: ' und der ',
  by_register_4: 'Datenschutzrichtlinie zu.',
  manager_better_register: 'Verwalten Sie Veranstaltungen effizienter mit I.D',
  referral: 'PromoBooster',
  first_name_required: 'Vorname erforderlich',
  first_name_short: 'Vorname ist zu kurz - sollte mindestens 3 Zeichen lang sein.',
  first_name_long: 'Vorname ist zu lang - sollte maximal 50 Zeichen lang sein.',
  first_name_alpha: 'Vorname darf nur alphabetische Zeichen enthalten.',
  last_name_required: 'Nachname erforderlich',
  last_name_short: 'Nachname ist zu kurz - sollte mindestens 3 Zeichen lang sein.',
  last_name_long: 'Nachname ist zu lang - sollte maximal 50 Zeichen lang sein.',
  last_name_alpha: 'Nachname darf nur alphabetische Zeichen enthalten.',
email_invalid: 'E-Mail muss eine gültige E-Mail-Adresse sein',
email_required: 'E-Mail ist erforderlich',
password_required: 'Passwort ist erforderlich',
password_short: 'Passwort ist zu kurz - sollte mindestens 8 Zeichen lang sein.',
password_alpha: 'Passwort darf nur lateinische Buchstaben enthalten.',
password_uppercase: 'Passwort muss mindestens einen Großbuchstaben enthalten.',
password_number: 'Passwort muss eine Zahl enthalten.',
confirm_password_match: 'Passwörter müssen übereinstimmen',
confirm_password_required: 'Passwort bestätigen ist erforderlich',
division_required: 'Abteilung ist erforderlich',
division_invalid: 'Abteilung muss entweder Promoter oder Agent sein',
role_required: 'Rolle ist erforderlich',
role_invalid_promoter: 'Ungültige Rolle für Promoter',
role_invalid_agent: 'Ungültige Rolle für Agent',
login: 'Anmelden',
fr: 'Französisch',
en: 'Englisch',
es: 'Spanisch',
pt: 'Portugiesisch',
lang: 'Sprache',
creating_account: 'Konto wird erstellt',
creating_account_desc: 'Abschließen Ihrer Kontoeinrichtung',
creating_account_info:
  'Ihr Konto wird derzeit eingerichtet. Bitte bleiben Sie auf dieser Seite, bis der Vorgang abgeschlossen ist. Wir danken Ihnen für Ihre Geduld!',
accept_invitation_info:
  'Bitte beachten Sie, dass Sie nach Annahme der unten stehenden Einladung eine Bestätigungs-E-Mail erhalten werden. Nach der Bestätigung können Sie Ihre Kontoeinrichtung abschließen, einschließlich der Möglichkeit, Ihren Namen und Ihre bevorzugte Sprache zu ändern.',
invitation_already_processed: "Diese Einladung wurde bereits verarbeitet. Bitte überprüfen Sie Ihr Event-Dashboard für weitere Informationen.",
verify_email: 'Bestätigen Sie Ihre E-Mail',
verify_email_desc: 'Konto erfolgreich erstellt',
account_created_check_email:
  'Konto erfolgreich erstellt. Bitte überprüfen Sie Ihre E-Mail, um Ihr Konto zu bestätigen und vollen Zugriff zu erhalten.',
thanks_join_invite: 'Vielen Dank, dass Sie unserer Community beigetreten sind!',
verify_before_expire: 'Bestätigen Sie Ihre E-Mail, bevor sie abläuft',
verify_email_info:
  'Herzlichen Glückwunsch zur Einrichtung Ihres Kontos! Bitte überprüfen Sie Ihren E-Mail-Posteingang, um Ihr Konto zu bestätigen und vollen Zugriff zu erhalten.',
wait_verify: 'Bitte warten Sie einen Moment, während wir Ihre E-Mail überprüfen.',
wait_redirect: 'Schließen Sie dieses Fenster nicht, bis Sie zum Dashboard weitergeleitet wurden.',
initialAccountHeader: 'Schließen Sie Ihr I.D-Konto ab',
initialAccountDescription:
  'Willkommen zurück! Legen Sie unten einfach Ihr Passwort fest, um die Einrichtung Ihres Kontos abzuschließen.',
initialAccountButton: 'Einrichtung abschließen',
processingAccountHeader: 'Ihr Konto wird eingerichtet...',
processingAccountDescription:
  'Bitte warten Sie einen Moment, während wir Ihre Daten bestätigen und Ihr Konto sichern.',
processingAccountButton: 'Wird abgeschlossen...',
successAccountHeader: 'Erfolg! Konto bereit',
successAccountDescription:
  'Ihr Konto wurde erfolgreich eingerichtet und Sie werden angemeldet. Sie werden in Kürze zum Dashboard weitergeleitet.',
successAccountButton: 'Weiterleitung...',
errorAccountHeader: 'Etwas ist schiefgelaufen',
errorAccountDescription:
  'Bei der Einrichtung Ihres Kontos ist ein Problem aufgetreten. Der Link ist möglicherweise ungültig oder abgelaufen, oder die E-Mail-Adresse könnte falsch sein.',
errorAccountButton: 'Erneut versuchen',
linkexpiredAccountHeader: 'Überprüfen Sie Ihre E-Mail',
linkexpiredAccountDescription: 'Bei der Einrichtung Ihres Kontos ist ein Problem aufgetreten. Der Link ist möglicherweise ungültig oder abgelaufen, oder die E-Mail-Adresse könnte falsch sein.',
verification_expired: 'Kontoeinrichtung fehlgeschlagen! Der Bestätigungslink für Ihr Konto ist abgelaufen.',
new_verification_link_sent: 'Ein neuer Bestätigungslink wurde an Ihre E-Mail-Adresse gesendet.',
login_welcome: 'Hallo, Willkommen',
login_connect: 'Bei I.D anmelden',
login_type: 'Geben Sie unten Ihre Daten ein.',
login_no_account: 'Sie haben noch kein Konto?',
login_register: 'Registrieren',
owner_total_part: 'Dies sind die Anteile des Besitzers am Verkauf der Event-Tickets.',
my_share: 'Mein Anteil',
Remember_password: 'Passwort merken',
forgot_password: 'Passwort vergessen?',
create_your_first_organization: 'Erstellen Sie Ihre erste Organisation',
create_organization_step_description:
  'Um mit der Verwaltung Ihrer Events zu beginnen. Erstellen Sie Ihre erste Organisation. Sie können später so viele Organisationen erstellen, wie Sie möchten.',
create_your_first_event: 'Erstellen Sie Ihr erstes Event',
create_event_step_description:
  'Nachdem Sie Ihre erste Organisation erstellt haben, ist es Zeit, kurz Ihr erstes Event zu erstellen. Sie können es später im Detail bearbeiten.',
congratulations: 'Herzlichen Glückwunsch!',
last_step_description: 'Sie können jetzt zur Eventliste navigieren und mit der Erstellung Ihres Events fortfahren',
create_your_first_restaurant: 'Erstellen Sie Ihr erstes Restaurant',
create_restaurant_step_description:
  'Um mit der Verwaltung Ihrer Tische zu beginnen. Erstellen Sie Ihr erstes Restaurant. Sie können später so viele Restaurants erstellen, wie Sie möchten.',
create_restaurant_last_step_description:
  'Sie können jetzt zur Restaurantliste navigieren und mit der Erstellung Ihrer Tische fortfahren',
Logout: 'Abmelden',
Settings: 'Einstellungen',
create_store: 'Shop erstellen',
add_category: 'Kategorie hinzufügen',
add_new_product: 'Neues Produkt hinzufügen',
add_new_category: 'Neue Kategorie hinzufügen',
products: 'Produkte',
categories: 'Kategorien',
unavailable: 'Nicht verfügbar',
composite: 'Zusammengesetzt',
alcoholic: 'Alkoholisch',
Available: 'Verfügbar',
Description: 'Beschreibung',
simple: 'Einfach',
search_product: 'Produkt suchen...',
min_price: 'Mindestpreis',
max_price: 'Höchstpreis',
add_product: 'Produkt hinzufügen',
edit_product: 'Produkt bearbeiten',
product_details: 'Produktdetails',
composition_items: 'Zusammensetzungselemente',
caption: 'Bildunterschrift',
extra: 'Extra',
max_quantity: 'Maximale Menge',
add_composition: 'Zusammensetzung hinzufügen',
items: 'Artikel',
item_name: 'Artikelname',
add_item: 'Artikel hinzufügen',
preparation_time: 'Zubereitungszeit',
Orders: 'Bestellungen',
current_orders: 'Aktuelle Bestellungen',
store: 'Shop',
no_stores_message: 'Sie haben keine Shops, um Bestellungen zu sehen. Bitte erstellen Sie zuerst einen',
Pending: 'Ausstehend',
in_preparation: 'In Vorbereitung',
Preparation: 'Vorbereitung',
accept_order: 'Bestellung annehmen',
order_is_ready: 'Bestellung ist fertig',
finish_order: 'Bestellung abschließen',
total: 'Gesamt',
order_of: 'Bestellung von',
Contact: 'Kontakt',
reservations_shares: 'Reservierungsanteile',
client_name: 'Kundenname',
total_amount: 'Gesamtbetrag',
owner_share: 'Besitzeranteil',
instadrink_share: 'I.D-Anteil',
rp_share: 'PromoBooster-Anteil',
ambassador_share: 'Botschafter-Anteil',
shares_distribution: 'Anteilsverteilung',
group_name: 'Gruppenname',
group_members: 'Gruppenmitglieder',
created_by: 'Erstellt von',
leave_group: 'Gruppe verlassen',
with_product: 'Mit Produkt',
all_categories: 'Alle Kategorien',
inclusives: 'Inklusives',
closing_Time: 'Schließzeit',
open: 'Offen',
Rush_hour: 'Stoßzeit',
preview_store: 'Shop-Vorschau',
Delete_account: 'Konto löschen',
Are_you_sure_you_want_to_delete_your_account: 'Sind Sie sicher, dass Sie Ihr Konto löschen möchten',
delete_account_page_message:
  'Wenn Sie sich für die Löschung Ihres Kontos entscheiden, wählen Sie die dauerhafte Entfernung aller Ihrer Daten aus unserem System.',
restaurants: 'Restaurants',
network: 'Netzwerk',
stores: 'Shops',
commandes: 'Bestellungen',
applications: 'Anwendungen',
inviteUsers: 'Benutzer einladen',
inviteAgents: 'Agenten einladen',
inviteStaff: 'Personal einladen',
invitationsList: 'Einladungsliste',
promotersTree: 'Einladungsbaum',
promotionGroups: 'PromoBooster-Gruppen',
onboarding: 'Einarbeitung',
if_mistake_contact_instadrink: 'Falls Sie einen Fehler haben, kontaktieren Sie bitte I.D',
invitation_rejected: 'Einladung abgelehnt',
invite_owner: 'Ich möchte einen Besitzer einladen',
invite_referrer: 'Ich möchte einen PromoBooster einladen',
invite_ambassador: 'Ich möchte einen Botschafter einladen',
your_organisation_staff: 'Ihr Organisationspersonal',
inv_org_desc: 'Der Organisator Ihres Events',
inv_bnc_desc: 'Der Zugangskontrolleur Ihres Events',
invite_more_staff: 'Mehr Personal einladen',
since: 'Seit',
delete_event_modal: 'Sind Sie sicher, dass Sie das Event löschen möchten',
publish_event_modal: 'Sind Sie sicher, dass Sie das Event veröffentlichen möchten',
cancel_event_modal: 'Sind Sie sicher, dass Sie das Event absagen möchten',
archive_event_modal: 'Sind Sie sicher, dass Sie das Event archivieren möchten',
duplicate_event: 'Event duplizieren',
duplicate: 'Duplizieren',
reservations: 'Reservierungen',
multiple_persons: 'Mehrere Personen',
persons_number: 'Anzahl der Personen',
vatRate: 'Mehrwertsteuersatz',
price_TTC: 'Preis inklusive Steuern',
Occupation: 'Beruf',
priceHt: 'Preis ohne Steuern',
promobooster_mode: 'PromoBooster-Modus',
promo_mode: 'Promo-Modus',
Amount: 'Betrag',
Percentage: 'Prozentsatz',
include_owners: 'Besitzer einschließen',
add_anonymous_promoter: 'SpyBooster hinzufügen',
Confirm_Name: 'Namen bestätigen',
Add_Anonymous_Name: 'SpyBooster hinzufügen',
ask_for_invoice: 'Nach Rechnung fragen',
add_invoice_button: 'Hinzufügen',
enter_valid_invoice: 'Bitte geben Sie eine gültige Rechnung ein',
Compan_Name_Last_Name: 'Firma/Name/Nachname',
tva: 'MwSt',
adresse: 'Adresse',
postal_code: 'Postleitzahl',
country: 'Land',
additional_information: 'Zusätzliche Informationen',
invoice_information: 'Rechnungsdaten',
survey_information: 'Umfrageinformationen',
know_from_invoice: 'Welche Informationen benötigen Sie von Ihrer Rechnung?',
know_from_attendees: 'Was müssen Sie von Ihren Teilnehmern wissen?',
add_invoice: 'Neues Feld hinzufügen',
active_invoice: 'Rechnung aktivieren',
actived_invoice: 'Die Rechnung ist aktiviert',
preview_your_share: 'Vorschau der Verteilung',
Number_of_event: 'Anzahl der Events',
time_of_creation: 'Erstellt am',
Owner_details: 'Besitzerdetails',
greetings: 'Grüße,',
you_have_been_invited: 'Sie wurden eingeladen, I.D beizutreten 🎉',
plz_wait_a_moment: 'Bitte warten Sie einen Moment',
thanks_time: 'Dankeszeit',
averagePreparationTime: 'Durchschnittliche Zubereitungszeit',
minutes: 'Minuten',
seconds: 'Sekunden',
select_menu: 'Menü auswählen',
capacity_warning_message: 'Die Kapazitäten sind im Vergleich zur Gesamtkapazität erschöpft.',
add_product_alert: 'Um Produkte zu Ihren Tickets hinzuzufügen, verknüpfen Sie bitte einen Shop mit Ihrem Event',
remaining_places: 'Verbleibende Plätze',
  navigate_to_event_menu: 'Zum Veranstaltungsmenü navigieren',
  you_have_no_store: 'Sie haben keinen Laden',
  reject_order: 'Bestellung ablehnen',
  add_Waiter: 'Kellner hinzufügen',
  add_room: "Raum hinzufügen",
  room_name: "Raumname",
  "campaigns": {
    "title": "E-Mail-Kampagnen",
    "audience": "Zielgruppe",
    segment: "Segment",
    "createNew": "Neue Kampagne erstellen",
    "name": "Name",
    "template": "Vorlage",
    "scheduledTime": "Geplante Zeit",
    "status": "Status",
    "actions": "Aktionen",
    "backToList": "Zurück zur Kampagnenliste",
    "info": "Kampagneninformationen",
    "mails": "E-Mails",
    "email": "E-Mail",
    "emailStatus": "E-Mail-Status",
    "confirmDeletion": "Löschen bestätigen",
    "deleteConfirmationMessage": "Sind Sie sicher, dass Sie diese E-Mail-Kampagne löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
    "cancel": "Abbrechen",
    "delete": "Löschen",
    "edit": "Kampagne bearbeiten",
    "create": "Neue Kampagne erstellen",
    "campaignName": "Kampagnenname",
    "save": "Speichern",
    "selectTemplate": "Vorlage auswählen",
    "createNewTemplate": "Neue Vorlage erstellen",
    "audienceType": {
      "title": "Zielgruppe",
      "all": "Alle Kontakte",
      "segment": "Spezifisches Segment",
      "eventBuyers": "Event-Ticketkäufer"
    },
    "emailstatus": {
      "email.sent": "Gesendet",
      "email.delivered": "Zugestellt",
      "email.delivery_delayed": "Zustellung Verzögert",
      "email.complained": "Beschwerde Eingegangen",
      "email.bounced": "Zurückgewiesen",
      "email.opened": "Geöffnet",
      "email.clicked": "Angeklickt",
      "email.unsubscribed": "Abgemeldet",
      "email.failed": "Versand Fehlgeschlagen",
      "email.blocked": "Blockiert",
      "email.dropped": "Verworfen",
      "email.deferred": "Verzögert",
      "email.rejected": "Abgelehnt"
    },
    "drawer": {
      "create": "Kampagne Erstellen",
      "edit": "Kampagne Bearbeiten",
      "name": "Kampagnenname",
      "audienceType": "Zielgruppentyp",
      "segment": "Segment Auswählen",
      "draftInfo": "Kampagne wird als Entwurf gespeichert"
    },
    "stats": {
        "title": "Kampagnenstatistiken",
        "delivered": "Zugestellt",
        "opened": "Geöffnet",
        "clicked": "Geklickt",
        "bounced": "Zurückgewiesen"
      },
    "campaignStatus": {
      "pending": "Ausstehend",
      "scheduled": "Geplant",
      "sending": "Wird gesendet",
      "launched": "Gesendet",
      "failed": "Fehlgeschlagen",
      "template-ready": "Vorlage bereit",
      "draft": "Entwurf",
    },
    "subject": "E-Mail-Betreff",
    "subjectHelperText": "Sie können den Vorlagenbetreff für diese Kampagne anpassen",
    "launchType": {
      "title": "Starttyp",
      "immediate": "Sofort starten",
      "scheduled": "Später planen"
    },
    "schedule": {
      action: "Planen",
      send: "Senden",
      "title": "Kampagne Planen",
      "info":  "Versand planen für Kampagne: {{name}}",
      scheduledTimeHelper: "Wählen Sie ein zukünftiges Datum und Uhrzeit für den Versand Ihrer Kampagne",
      "errors": {
        "scheduleTimeRequired": "Bitte wählen Sie eine Planungszeit",
        "testEmailRequired": "Bitte geben Sie eine Test-E-Mail-Adresse ein",
        "scheduleFailed": "Kampagnenplanung fehlgeschlagen",
        "testSendFailed": "Fehler beim Senden der Test-E-Mail"
      },
      "success": {
        "testSent": "Test-E-Mail erfolgreich gesendet",
        "launched": "Kampagne erfolgreich gestartet",
        "scheduled": "Kampagne erfolgreich geplant"
        
      }
    }
  },
  "Include_Platform_Fees": "Plattformgebühren einschließen",
  "Final_Price": "Endpreis",
  "Platform_Fee_Info": "Zum Ticketpreis wird eine Plattformgebühr von 2,5% hinzugefügt",
  Cost_to_buyers: "Kosten für den Kunde",
  "contacts": {
    "totalContacts": "{{count}} Kontakte",
    "import": "Kontakte importieren",
    "export": "Als CSV exportieren",
    "deleteSelected": "Ausgewählte löschen",
    "search": "Suchen",
    "noContactsToExport": "Keine Kontakte zum Exportieren.",
    "errorDeletingContacts": "Fehler beim Löschen der Kontakte",
    "errorLoadingContacts": "Fehler beim Laden der Kontakte",
    "confirmDeletion": "Löschen bestätigen",
    "confirmDeletionMessage": "Sind Sie sicher, dass Sie die {count} ausgewählten Kontakte löschen möchten?",
    "cancel": "Abbrechen",
    "delete": "Löschen",
    "dragAndDropInstruction": "Ziehen Sie eine CSV- oder XLSX-Datei hierher oder klicken Sie zum Auswählen",
    "file": "Datei: {name}",
    "removeFile": "Datei entfernen",
    "informationMapping": "Informationszuordnung",
    "select": "Auswählen",
    "additionalFields": "Zusätzliche Felder",
    "errorParsingFile": "Fehler beim Analysieren der Datei. Bitte versuchen Sie es erneut.",
    "contactsImportedSuccessfully": "Kontakte erfolgreich importiert",
    "errorImportingContacts": "Fehler beim Importieren der Kontakte. Bitte versuchen Sie es erneut.",
    "fields": {
      "email": "E-Mail",
      "firstName": "Vorname",
      "lastName": "Nachname",
      "phone": "Telefon",
      "age": "Alter",
      "gender": "Geschlecht",
      "country": "Land",
      "zone": "Zone",
      "postalCode": "Postleitzahl"
    }
  },
  "no_confirmed_tickets_to_export": "Keine bestätigten Tickets zum Exportieren",
  "export_confirmed_ticket_ids_tooltip": "Bestätigte Ticket-IDs exportieren",
  "loading": "Wird geladen...",
  "export_confirmed_tickets": "Bestätigte Tickets exportieren ({{count}})",
  check_invitation_list: "Kontaktliste prüfen",
  "segments": {
      "create": {
        "title": "Neues Segment erstellen",
        "selectType": "Wählen Sie einen Segmenttyp",
        "dynamicSegment": {
          "title": "Dynamisches Filtersegment",
          "description": "Wenden Sie eine Liste von Attributen an, um einen Teil Ihrer Kontakte auszuwählen. Ihre Kontakte werden basierend auf den gewählten Filtern automatisch zu diesem Segment hinzugefügt oder daraus entfernt."
        },
        "manualSegment": {
          "title": "Manuelles Segment über CSV",
          "description": "Sie können eine Liste von Kontakten aus einer CSV-Datei importieren, um sie in einem Segment zu gruppieren. Sie können diese Funktion verwenden, um bestehende Kontakte als neues Segment erneut zu importieren."
        },
        "step": "Schritt",
        "of": "von",
        removeFile: "Datei entfernen",
        "newsletterContacts": "{{count}} / 2000 Newsletter-Abonnenten bereits importiert.",
        "selectAgeRange": "Wählen Sie den Altersbereich aus, den Sie filtern möchten:",
        "averageAge": "Durchschnitt: {{age}} Jahre",
        "infoKnownFor": "Diese Information ist für {{count}} Kontakte bekannt",
        "selectCountries": "Ein oder mehrere Länder auswählen",
        "selectZones": "Zonen auswählen",
        "addPostalCodes": "Postleitzahlen hinzufügen (durch Komma getrennt)",
        "selectLanguage": "Sprache auswählen",
        "whenAdded": "Wann dieser Kontakt zu Ihrer Community hinzugefügt wurde",
        "contactsImported": "Kontakte, die bereits für Ihren Newsletter importiert wurden.",
        "dropFileHere": "Legen Sie Ihre CSV-, XLS- oder XLSX-Datei hier ab",
        "fileRequirements": "Ihre Datei muss mindestens eine Spalte mit der E-Mail-Adresse der Kontakte enthalten. Um ihnen Newsletter zu senden, muss sie eine Spalte für das Newsletter-Abonnement (ja/nein) haben.",
        "removeFilters": "Filter entfernen",
        "contactInformation": "KONTAKTINFORMATIONEN",
        "marketing": "MARKETING",
        "history": "VERLAUF",
        "fieldMapping": "Feldzuordnung",
        "additionalFields": "Zusätzliche Felder",
        "segmentName": "Segmentname",
        "description": "Beschreibung",
        "matchingContacts": "passende Kontakte",
        "back": "Zurück",
        "cancel": "Abbrechen",
        "next": "Weiter",
        "createSegment": "Segment erstellen",
        "importContacts": "Kontakte als Segment importieren"
      },
      "fieldsMapping": {
          "name": "Name",
          "email": "E-Mail",
          "firstName": "Vorname",
          "lastName": "Nachname",
          "phone": "Telefon",
          "age": "Alter",
          "gender": "Geschlecht",
          "country": "Land",
          "zone": "Zone",
          "postalCode": "Postleitzahl"
        },
      "filters": {
        activeFilters: "Aktive Filter",
        "hasEmail": "Hat eine E-Mail",
        "emailVerified": "E-Mail verifiziert",
        "hasPhone": "Hat eine Telefonnummer",
        "age": "Alter",
        "gender": "Geschlecht",
        "location": "Standort",
        "language": "Sprache",
        "dateAdded": "Hinzugefügt am",
        "selectCountries": "Wählen Sie ein oder mehrere Länder",
        "selectZones": "Zonen auswählen",
        "postalCodes": "Postleitzahlen hinzufügen (durch Kommas getrennt)",
        "selectLanguage": "Sprache auswählen",
        "allTime": "Alle Zeit",
        "today": "Heute",
        "yesterday": "Gestern",
        "last7days": "Letzte 7 Tage",
        "last14days": "Letzte 14 Tage",
        "last30days": "Letzte 30 Tage",
        "last90days": "Letzte 90 Tage",
        "customDates": "Benutzerdefinierte Daten",
        "from": "Von",
        "to": "Bis",
        "events": {
        "title": "Event-Teilnahme",
        "hasAttendedEvents": "Besuchte Events",
        "attendanceInfo": "Hat {{count}} oder mehr Events besucht",
        "selectRange": "Wählen Sie die Mindestanzahl besuchter Events",
        "infoTooltip": "Dies wird Benutzer anzeigen, die mindestens diese Anzahl von Events besucht haben"
      }
      }
    },
    "segment": {
      "Create": {
        "matchingContacts": "übereinstimmende Kontakte",
        "back": "Zurück",
        "cancel": "Abbrechen",
        "next": "Weiter",
        "importContacts": "Kontakte importieren",
        "title": "Segment erstellen"
      }
    },
    "stripe_connect": {
    "setup_stripe_account": "Stripe-Konto einrichten",
    "complete_stripe_setup": "Einrichtung abschließen",
    "alert_stripe_incomplete_setup": "Die Einrichtung Ihres Stripe-Kontos ist unvollständig. Bitte schließen Sie den Verifizierungsprozess ab, um Zahlungen für Ihre Veranstaltungen zu erhalten.",
    "error_creating_stripe_account": "Fehler beim Erstellen des Stripe-Kontos. Bitte versuchen Sie es später erneut.",
    "error_creating_stripe_link": "Fehler beim Generieren des Stripe-Einrichtungslinks. Bitte versuchen Sie es später erneut.",
    "legal_verification": "Rechtliche Überprüfung erforderlich",
    "alert_stripe_configuration_title": "Um Zahlungen für Ihre Veranstaltungen zu erhalten, müssen Sie Ihr Stripe Connect-Konto einrichten. Dieser Schritt ist für die rechtliche Konformität und sichere Zahlungsabwicklung erforderlich."
  },
  "templates": {
    "createNew": "Neue Vorlage erstellen",
    "subject": "E-Mail-Betreff",
    "subjectRequired": "E-Mail-Betreff ist erforderlich",
    "createSuccess": "Vorlage erfolgreich erstellt",
    "createError": "Fehler beim Erstellen der Vorlage"
  },
  "survey": {
    "title": "Event-Umfrage-Editor",
    "enable": "Umfrage aktivieren",
    "noResponses": "Noch keine Antworten",
    "totalResponses": "Gesamtantworten: {{count}}",
    "noSurveyData": "Keine Umfragedaten verfügbar",
    "errorLoading": "Fehler beim Laden der Umfragedaten",
    "question": {
      "title": "Frage {{number}}",
      "required": "Erforderlich",
      "text": "Fragentext",
      "type": "Fragetyp",
      "types": {
        "shortText": "Kurzer Text",
        "longText": "Langer Text",
        "radio": "Optionsfelder",
        "checkbox": "Kontrollkästchen"
      },
      "options": "Optionen:",
      "addOption": "Option hinzufügen",
      "submit": "Frage speichern",
      "validation": {
        "emptyText": "Der Fragentext darf nicht leer sein.",
        "radioOptions": "Die Einzelauswahlfrage muss mindestens eine Option haben.",
        "checkboxOptions": "Die Mehrfachauswahlfrage muss mindestens eine Option haben."
      }
    },
    "addQuestion": "Frage hinzufügen",
    "answersSummary": "Antwortenzusammenfassung"
  },
  promoboosters: {
    "referral_sales": "Empfehlungsverkäufe",
    "other_sales": "Andere Verkäufe",
    "available": "Verfügbar",
    "No_tickets_sold_yet": "Noch keine Tickets verkauft",
    "ticket_category": "Ticketkategorie",
    "total_sold": "Gesamt Verkauft",
    "initial_quantity": "Anfangsmenge"
  },
  guests: {
    guestLists: 'Gästelisten',
    addList: 'Liste hinzufügen',
    addGuest: 'Gast hinzufügen',
    searchGuests: 'Gäste suchen',
    addNewList: 'Neue Liste hinzufügen',
    listName: 'Listenname',
    guestList: 'Gästeliste',
    sendEmailsToNewGuests: 'E-Mails an neue Gäste senden',
    single: 'Einzeln',
    import: 'Importieren',
    text: 'Text',
    name: 'Name',
    email: 'E-Mail',
    totalTickets: 'Gesamttickets',
    freeTickets: 'Freie Tickets',
    dragDropCsv: 'CSV-Datei hier ablegen oder zum Auswählen klicken',
    guestDetailsPlaceholder: 'Gästedetails eingeben (eine pro Zeile): Name, GesamtTickets+FreieTickets, E-Mail',
    importGuests: 'Gäste importieren',
    editGuest: 'Gast bearbeiten',
    update: 'Aktualisieren',
    delete: 'Löschen',
    confirmDelete: 'Löschen bestätigen',
    confirmDeleteMessage: 'Sind Sie sicher, dass Sie diesen Gast löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
    cancel: 'Abbrechen',
    mapColumns: 'Spalten zuordnen',
    emailLabel: 'E-Mail',
    ticketsInfo: 'Tickets',
  },
  "confirm_refund": "Rückerstattung bestätigen",
  "refund_confirmation_message": "Sind Sie sicher, dass Sie dieses Ticket zurückerstatten möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
  "refund_amount": "Rückerstattungsbetrag",
  "processing": "Verarbeitung...",
  "only_confirmed_tickets_can_be_refunded": "Nur bestätigte Tickets können erstattet werden",
  "refund_ticket": "Ticket erstatten",
  send_confirmation_email: "Best&auml;tigungs-E-Mail senden",
  can_only_send_email_for_confirmed_or_validated: "Kann nur E-Mails f&uuml;r best&auml;tigte oder validierte Tickets senden",
  "deduct_platform_fee": "Plattformgebühr Abziehen",
  "platform_fee_toggle_description": "Wenn aktiviert, wird die Plattformgebühr vom Erstattungsbetrag abgezogen",
  'Remove Deformation': 'Remove Deformation',
  "original_price": "Originalpreis",
    "platform_fee": "Plattformgebühr",
    "platform_fee_deduction": "Abzug der Plattformgebühr",
    "final_refund_amount": "Endgültiger Erstattungsbetrag",
  'Deformed Price': 'Deformed Price',
  'Final Price': 'Final Price',
  'No deformations available': 'No price deformations available',
  'Price deformation added': 'Price deformation added successfully',
  'Price deformation removed': 'Price deformation removed',
  'Price Deformations Management': 'Price Deformations Management',
  'Add Price Deformation': 'Add Price Deformation',
  'No price deformations defined yet': 'No price deformations defined yet',
  'Inactive': 'Inactive',
  'off': 'off',
  'Priority': 'Priority',
  'Domains': 'Domains',
  'Are you sure you want to delete this price deformation?': 'Are you sure you want to delete this price deformation?',
  'Price deformation deleted successfully': 'Price deformation deleted successfully',
  'Failed to delete price deformation': 'Failed to delete price deformation',
  "Ticket Restrictions": "Ticket Restrictions",
    "Add Access Restriction": "Add Access Restriction",
    "Access Restriction": "Access Restriction",
    "Allowed Domains": "Allowed Domains",
    "Restriction Rules": "Restriction Rules",
    "A ticket can have either multiple price deformations OR one access restriction, but not both.": "A ticket can have either multiple price deformations OR one access restriction, but not both.",
    "Adding an access restriction will remove all price deformations. Continue?": "Adding an access restriction will remove all price deformations. Continue?",
    "Access_Restriction_Description": "Der Zugang zu Tickets kann anhand Ihrer E-Mail-Domäne eingeschränkt sein.",
    total_tickets_sold: 'Gesamttickets verkauft',
    total_revenue: 'Gesamtumsatz',
    eventTicketStatistics: 'Ticketstatistiken der Veranstaltung',
    eventCapacity: 'Veranstaltungskapazität',
    totalTickets: 'Gesamtanzahl Tickets',
    totalReservations: 'Gesamtanzahl Reservierungen',
    ticketName: 'Ticketname',
    initialQuantity: 'Anfängliche Menge',
    soldQuantity: 'Verkaufte Menge',
    noTicketData: 'Keine Ticketdaten verfügbar',
    errorLoadingTickets: 'Fehler beim Laden der Ticketdaten',
    na: 'N/V',
    "confirm_send_email": "E-Mail-Versand bestätigen",
    "send_email_confirmation_message": "Sind Sie sicher, dass Sie die Bestätigungs-E-Mail an diesen Empfänger senden möchten?",
    "recipient": "Empfänger",
    "sending": "Wird gesendet...",
    "send": "Senden",
    "ticketInvitation": {
    "import": {
      "csvMode": "CSV-Import",
 "singleMode": "Einzelimport",
 "missingFields": "Bitte füllen Sie alle erforderlichen Felder aus",
 "invalidEmail": "Bitte geben Sie eine gültige E-Mail-Adresse ein",
 "success.single": "Einladung erfolgreich versendet",
 "sendInvitation": "Einladung senden", 
 "numTickets": "Anzahl der Tickets",
      "steps": {
        "setup": "Einrichtung",
        "preview": "Vorschau & Import"
      },
      "next": "Weiter",
      "back": "Zurück",
      "summary": "Import-Zusammenfassung",
      "totalRecords": "Gesamtanzahl der Datensätze",
      "selectedTicket": "Ausgewählte Ticket-Kategorie",
      "title": "Einladungen importieren",
      "progress": "Verarbeite {{current}}/{{total}} ({{success}} erfolgreich, {{failed}} fehlgeschlagen)",
      "dragOrSelect": "CSV-Datei hier ablegen oder klicken zum Auswählen",
      "dropHere": "CSV-Datei hier ablegen",
      "mapColumns": "CSV-Spalten zuordnen",
      "previewTitle": "Vorschau",
      "records": "Einträge",
      "firstThreeRecords": "Erste drei Einträge",
      "importButton": "{{count}} Einladungen importieren",
      "errors": {
        "unknown": "Ein unbekannter Fehler ist aufgetreten",
        "csvEmpty": "Die CSV-Datei ist leer",
        "invalidFormat": "Ungültiges CSV-Format",
        "parseError": "Fehler beim Analysieren der CSV-Datei",
        "fileTooLarge": "Datei zu groß (max 5MB)",
        "invalidFileType": "Nur CSV-Dateien sind erlaubt",
        "invalidDataRows": "{{count}} Zeilen enthalten ungültige Daten",
        "missingMapping": "Bitte ordnen Sie alle erforderlichen Spalten zu",
        "creationFailed": "Fehler beim Erstellen der Einladungen"
      },
      "success": {
        "partial": "Import abgeschlossen: {{success}} erfolgreich, {{failed}} fehlgeschlagen.\nDetails:\n{{details}}",
        "complete": "{{count}} Einladungen erfolgreich erstellt"
      },
      "explanation": {
        "main": "Importieren Sie Tickets für mehrere Teilnehmer über eine CSV-Datei. Perfekt für die Migration von anderen Ticketsystemen oder die Erstellung von Tickets für eine vordefinierte Gästeliste.",
        "features": "Dieses Import-Tool wird:",
        "feature1": "Digitale Tickets mit einzigartigen QR-Codes für jeden Teilnehmer erstellen",
        "feature2": "Bestätigungs-E-Mails mit Ticket-QR-Codes an die Teilnehmer senden",
        "feature3": "Ihnen ermöglichen, spezifische Ticket-Kategorien allen importierten Gästen zuzuweisen"
      }
    }
  },
  "tickets": {
    "validation": {
      "titleRequired": "Titel ist erforderlich",
      "descriptionRequired": "Beschreibung ist erforderlich",
      "priceZero": "Der Preis kann nicht null sein",
      "priceRequired": "Preis ist erforderlich",
      "initialQuantityZero": "Anfangsmenge kann nicht null sein",
      "maxQuantityLimit": "Maximalmenge muss kleiner als Anfangsmenge sein",
      "maxQuantityZero": "Maximalmenge kann nicht null sein",
      "minQuantityLimit": "Mindestmenge muss kleiner als Maximalmenge und Anfangsmenge sein",
      "minQuantityZero": "Mindestmenge kann nicht null sein",
      "categoryRequired": "Kategorie ist erforderlich",
      "productRequired": "Produkt ist erforderlich",
      "quantityRequired": "Menge ist erforderlich",
      "quantityGreaterThanZero": "Menge muss größer als null sein",
      "withProductRequired": "Produkt ist erforderlich wenn iscomposite wahr ist",
      "fieldRequired": "Dieses Feld ist erforderlich",
      "vatRateRange": "Der Mehrwertsteuersatz muss zwischen 1 und 100 liegen"
    }
  },
  "accessRequests": {
    "request_access": "Zugang Anfordern",
    "request_access_title": "Admin-Zugang Anfordern",
    "full_name": "Vollständiger Name",
    "email": "E-Mail",
    "company_name": "Firmenname",
    "position": "Position",
    "reason": "Warum benötigen Sie Zugang?",
    "cancel": "Abbrechen",
    "submit": "Anfrage Senden",
    "submitting": "Wird gesendet...",
    "please_fill_all_fields": "Bitte füllen Sie alle erforderlichen Felder aus",
    "invalid_email": "Bitte geben Sie eine gültige E-Mail-Adresse ein",
    "error_submitting_request": "Fehler beim Senden der Anfrage. Bitte versuchen Sie es erneut.",
    "phone": "Telefonnummer",
    "phone_optional": "Optional - Ländervorwahl eingeben",
    "company_optional": "Optional",
    "position_optional": "Optional",
    "source": "Wie haben Sie uns gefunden?",
    "invalid_phone": "Bitte geben Sie eine gültige Telefonnummer ein",
    "source_options": {
      "search_engine": "Suchmaschine (Google, Bing, etc.)",
      "social_media": "Soziale Medien",
      "friend": "Freund oder Kollege",
      "advertisement": "Werbung",
      "other": "Andere"
    }
  },
  "Service_Fees": "Servicegebühren",
  "Per_Ticket": "pro Ticket"
  
};

export default gr;
