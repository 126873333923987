// ----------------------------------------------------------------------
import { SHA256 } from 'crypto-js';
export  function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}


export  function generateContactId(email: string) {
  const normalizedEmail = email.trim().toLowerCase();
  const hash = SHA256(normalizedEmail).toString();
  return hash.substring(0, 20); // Use first 20 characters to mimic Firestore ID length
};
