// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormHelperText } from '@mui/material';
//
import Editor, { Props as EditorProps } from '../editor';

// ----------------------------------------------------------------------

interface Props extends EditorProps {
  name: string;
  maxLength?: number;
}

export default function RHFEditor({ name, maxLength, ...other }: Props) {
  const { control } = useFormContext();

  const getTextLimit = (text: string) => {
    var strippedHtml = text.replace(/<[^>]+>/g, '');
    const currentLength = strippedHtml.length;
    return currentLength;
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Editor
          id={name}
          value={field.value}
          onChange={field.onChange}
          error={!!error}
          helperText={
            <FormHelperText error={!!error} sx={{ px: 2, textTransform: 'capitalize' }}>
              {error ? error?.message : `${getTextLimit(field.value)} characters`}
            </FormHelperText>
          }
          {...other}
        />
      )}
    />
  );
}
