import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { collectionGroup, getDocs, query, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { Attendee } from 'src/@types/attendee';
import { DB, FUNCTIONS } from 'src/contexts/FirebaseContext';

interface RefundRequest {
  amount: number;
  ticketid: string;
  eventid: string;
  reservationid: string;
  pi: string;
  userid: string;
}

interface RefundResponse {
  id: string;
  object: 'refund';
  status: string;
  // Add other Stripe refund response fields as needed
}

export const ReservationsApi = createApi({
    reducerPath: 'ReservationsApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Attendees', 'Tickets', 'Reservations'],
  endpoints: (builder) => ({
    getAttendees: builder.query<Attendee[], { eventId: string; status: string; outputFormat: string }>({
      async queryFn(args) {
        try {
          const fetchReservationsCallable = httpsCallable<
            { eventId: string; status: string; outputFormat: string },
            { attendees: Attendee[] }
          >(FUNCTIONS, 'reservations-fetchReservationsCallable');
          
          const result = await fetchReservationsCallable(args);
          return { data: result.data.attendees };
        } catch (error: any) {
          console.error(error);
          return { error: error.message };
        }
      },
      providesTags: ['Attendees'],
    }),
    getConfirmedTicketIds: builder.query<string[], string>({
      async queryFn(eventId) {
        try {
          const ticketsRef = collectionGroup(DB, 'tickets');
          const q = query(
            ticketsRef, 
            where('eventid', '==', eventId),
            where('status', '==', 'confirmed')
          );
          const querySnapshot = await getDocs(q);
          const ticketIds: string[] = [];
          querySnapshot.forEach((doc) => {
            ticketIds.push(doc.id);
          });
          return { data: ticketIds };
        } catch (error: any) {
          return { error: error.message };
        }
      },
    }),
    refundTicket: builder.mutation<RefundResponse, RefundRequest>({
      async queryFn(refundData) {
        try {
          const getStripeRefund = httpsCallable<RefundRequest, RefundResponse>(
            FUNCTIONS, 
            'getStripeRefund'
          );

          const response = await getStripeRefund({
            amount: refundData.amount,
            ticketid: refundData.ticketid,
            eventid: refundData.eventid,
            reservationid: refundData.reservationid,
            pi: refundData.pi,
            userid: refundData.userid,
          });
          console.log(response.data);

          return { data: response.data };
        } catch (error: any) {
          console.error(error.message);
          console.error(error.code);
          return {
            error: {
              status: error?.code || 'UNKNOWN_ERROR',
              data: error?.message || 'An unknown error occurred',
            },
          };
        }
      },
      // Invalidate relevant cache after refund
      invalidatesTags: ['Tickets', 'Reservations'],
    }),
    sendConfirmationEmail: builder.mutation<void, { mode: string, reservationid: string }>({
      async queryFn({ mode, reservationid }) {
        try {
          const createMailTask = httpsCallable(FUNCTIONS, 'createMailTask');
          await createMailTask({ mode, reservationid });
          return { data: undefined };
        } catch (error: any) {
          return { error: error.message };
        }
      }
    }),
    
  }),
});

export const { useGetAttendeesQuery, 
  useGetConfirmedTicketIdsQuery,
  useSendConfirmationEmailMutation,
  useRefundTicketMutation 
} = ReservationsApi;