import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { DB } from 'src/contexts/FirebaseContext';
import { 
  collection, 
  query, 
  orderBy, 
  getDocs, 
  addDoc, 
  doc,
  updateDoc,
  Timestamp 
} from 'firebase/firestore';

export interface AccessRequest {
  id: string;
  fullName: string;
  email: string;
  companyName: string;
  position: string;
  reason: string;
  status: 'pending' | 'approved' | 'rejected';
  createdAt: Date;
  updatedAt: Date;
}

export const AccessRequestsApi = createApi({
  reducerPath: 'accessRequestsApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['AccessRequests'],
  endpoints: (builder) => ({
    getAccessRequests: builder.query<AccessRequest[], void>({
      queryFn: async () => {
        try {
          const accessRequestsRef = collection(DB, "accessRequests");
          const q = query(accessRequestsRef, orderBy("createdAt", "desc"));
          const snapshot = await getDocs(q);

          const requests = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt?.toDate(),
            updatedAt: doc.data().updatedAt?.toDate()
          })) as AccessRequest[];

          return { data: requests };
        } catch (error) {
          return { error: "Failed to fetch access requests" };
        }
      },
      providesTags: ["AccessRequests"]
    }),

    submitAccessRequest: builder.mutation<{ success: boolean }, Omit<AccessRequest, 'id' | 'status' | 'createdAt' | 'updatedAt'>>({
      queryFn: async (request) => {
        try {
          const accessRequestsRef = collection(DB, "accessRequests");
          await addDoc(accessRequestsRef, {
            ...request,
            status: "pending",
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now()
          });
          return { data: { success: true } };
        } catch (error) {
          console.log(error);
          return { error: "Failed to submit access request" };
        }
      },
      invalidatesTags: ["AccessRequests"]
    }),

    updateRequestStatus: builder.mutation<{ success: boolean }, { id: string; status: AccessRequest['status'] }>({
      queryFn: async ({ id, status }) => {
        try {
          const docRef = doc(DB, "accessRequests", id);
          await updateDoc(docRef, {
            status,
            updatedAt: Timestamp.now()
          });
          return { data: { success: true } };
        } catch (error) {
          return { error: "Failed to update request status" };
        }
      },
      invalidatesTags: ["AccessRequests"]
    })
  })
});

export const {
  useGetAccessRequestsQuery,
  useSubmitAccessRequestMutation,
  useUpdateRequestStatusMutation
} = AccessRequestsApi;