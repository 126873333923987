import Contact from "src/pages/Contact";

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  completeSignup: path(ROOTS_AUTH, '/complete-signup'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  deleteAccount: '/delete-account',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    //general
    app: path(ROOTS_DASHBOARD, '/app'),
    welcome: path(ROOTS_DASHBOARD, '/welcome'),
    notifications: path(ROOTS_DASHBOARD, '/notifications'),
  },
  evenementiel: {
    organisations: path(ROOTS_DASHBOARD, '/evenementiel/orgs'),
    newOrg: path(ROOTS_DASHBOARD, '/evenementiel/orgs/new'),
    editOrg: (id: string) => path(ROOTS_DASHBOARD, `/evenementiel/orgs/${id}/edit`),
    viewOrg: (id: string) => path(ROOTS_DASHBOARD, `/evenementiel/orgs/${id}/view`),
    events: path(ROOTS_DASHBOARD, '/evenementiel/events'),
    eventsList: path(ROOTS_DASHBOARD, '/evenementiel/my-events'),
    ambassadorEvents: path(ROOTS_DASHBOARD, '/evenementiel/ambassador-events'),
    newEvent: path(ROOTS_DASHBOARD, '/evenementiel/events/new'),
    editEvent: (id: string) => path(ROOTS_DASHBOARD, `/evenementiel/events/${id}/edit`),
    publishEvent: (id: string) => path(ROOTS_DASHBOARD, `/evenementiel/events/${id}/publish`),
    createEvent: (id: string) => path(ROOTS_DASHBOARD, `/evenementiel/events/${id}/create`),
    viewEvent: (id: string) => path(ROOTS_DASHBOARD, `/evenementiel/events/${id}/view`),
    dashboardEvent: (id: string) => path(ROOTS_DASHBOARD, `/evenementiel/events/${id}/dashboard`),
    seatMapperEvent: (id: string) => path(ROOTS_DASHBOARD, `/evenementiel/events/${id}/seatmapper`),
    reservationsEvent: (id: string) =>
      path(ROOTS_DASHBOARD, `/evenementiel/events/${id}/reservations`),
    eventStats: (id: string) => path(ROOTS_DASHBOARD, `/evenementiel/events/${id}/event-stats`),
    eventRequests: path(ROOTS_DASHBOARD, `/evenementiel/my-events/requests`),
    eventDetails: (id: string) =>
      path(ROOTS_DASHBOARD, `/evenementiel/my-events/${id}/event-details`),
    searchEvents: path(ROOTS_DASHBOARD, '/evenementiel/search-for-events'),
  },
  restauration: {
    restaurants: path(ROOTS_DASHBOARD, '/restauration/restaurants'),
    newRestaurant: path(ROOTS_DASHBOARD, '/restauration/restaurants/new'),
    editRestaurant: (id: string) => path(ROOTS_DASHBOARD, `/restauration/restaurants/${id}/edit`),
    viewRestaurant: (id: string) => path(ROOTS_DASHBOARD, `/restauration/restaurants/${id}/view`),
    commandes: path(ROOTS_DASHBOARD, '/restauration/commandes'),
    stores: path(ROOTS_DASHBOARD, '/restauration/stores'),
    storesList: path(ROOTS_DASHBOARD, '/restauration/store-list'),
    applications: path(ROOTS_DASHBOARD, '/restauration/applications'),
    createStore: path(ROOTS_DASHBOARD, '/restauration/store-create'),
    eventsOrders: path(ROOTS_DASHBOARD, '/restauration/orders'),
    menusList: path(ROOTS_DASHBOARD, '/restauration/menus'),
    menusAdd: path(ROOTS_DASHBOARD, '/restauration/menus/new'),
    viewMenu: (id: string) => path(ROOTS_DASHBOARD, `/restauration/menus/menu-details/${id}/view`),
    tablesList: path(ROOTS_DASHBOARD, '/restauration/tables-list'),
  },
  network: {
    root: path(ROOTS_DASHBOARD, '/network'),
    inviteUsers: path(ROOTS_DASHBOARD, '/network/invite-users'),
    inviteAgents: path(ROOTS_DASHBOARD, '/network/invite-agents'),
    inviteStaff: path(ROOTS_DASHBOARD, '/network/invite-staff'),
    invitationsList: path(ROOTS_DASHBOARD, '/network/invitations-list'),
    promotersTree: path(ROOTS_DASHBOARD, '/network/promoters-tree'),
    referralDetails: (id: string) => path(ROOTS_DASHBOARD, `/network/${id}/referral-details`),
    ownerDetails: (id: string) => path(ROOTS_DASHBOARD, `/network/${id}/owner-details`),
    ambassadorInvites: path(ROOTS_DASHBOARD, '/ambassador-invites'),
    staffList: path(ROOTS_DASHBOARD, '/network/staff-list'),
    orgsRequests: path(ROOTS_DASHBOARD, `/network/promotion-requests`),
    promotionGroups: path(ROOTS_DASHBOARD, `/network/promotion-groups`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    onboarding: path(ROOTS_DASHBOARD, '/user/onboarding'),
    mails: path(ROOTS_DASHBOARD, '/user/mails'),
    contacts: path(ROOTS_DASHBOARD, '/user/contacts'),
    mailedit: (id: string) => path(ROOTS_DASHBOARD, `/user/${id}/mailedit`),
    billing: path(ROOTS_DASHBOARD, '/user/billing'),
    seatmapper: path(ROOTS_DASHBOARD, '/user/seatmapper'),
    // deleteAccount: path(ROOTS_DASHBOARD, '/user/delete-account'),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
};

export const PATH_DOCS = 'https://docs-Instadrink/introduction';
