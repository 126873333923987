import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// config
import {
  PATH_AFTER_AMBASSADOR_LOGIN,
  PATH_AFTER_LOGIN,
  PATH_AFTER_REFERRAL_LOGIN,
  PATH_AFTER_WAITER_LOGIN,
} from '../config/config';
// components
import LoadingScreen from '../components/LoadingScreen';
import { PATH_DASHBOARD } from './paths';
import useSettings from 'src/hooks/useSettings';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

//const isShowTemplate = false;

export default function Router() {
  const { user } = useAuth();

  const { fieldType } = useSettings();

  const pathAfterLog =
    user?.role === 'Referral'
      ? PATH_AFTER_REFERRAL_LOGIN
      : user?.role === 'Ambassador'
      ? PATH_AFTER_AMBASSADOR_LOGIN
      : user?.role === 'Waiter'
      ? PATH_AFTER_WAITER_LOGIN
      : user?.isNewUser
      ? PATH_DASHBOARD.general.welcome
      : fieldType === 'event'
      ? PATH_DASHBOARD.evenementiel.events
      : PATH_DASHBOARD.restauration.menusList;

  const pathHome = user?.role ? pathAfterLog : '/';

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'complete-signup', element: <CompleteSignup /> },
        { path: 'accept-invitation', element: <AcceptInvitationPage /> },
        { path: 'complete-invitation', element: <CompleteInvitation /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={pathAfterLog} replace />, index: true },
        //general
        { path: 'app', element: <></> },
        { path: 'welcome', element: <WelcomePage /> },
        { path: 'notifications', element: <PromotionRequest /> },

        // events
        {
          path: 'evenementiel',
          children: [
            { element: <Navigate to="/dashboard/evenementiel/orgs" replace />, index: true },
            {
              path: 'orgs',
              children: [
                {
                  element: <Navigate to="/dashboard/evenementiel/orgs/list" replace />,
                  index: true,
                },
                { path: 'list', element: <OrgsListPage /> },
                { path: 'new', element: <OrgAddPage /> },
                { path: ':id/view', element: <OrgViewPage /> },
                { path: ':id/edit', element: <OrgEditPage /> },
              ],
            },
            {
              path: 'events',
              children: [
                {
                  element: <Navigate to="/dashboard/evenementiel/events/list" replace />,
                  index: true,
                },
                { path: 'list', element: <EventsListPage /> },
                { path: 'new', element: <EventAddPage /> },
                { path: 'analytics', element: <EventAnalytics /> }, 
                { path: 'attendees', element: <AttendeesAnalytics /> },            
                { path: ':id/view', element: <EventViewPage /> },
                { path: ':id/dashboard', element: <EventDashboardPage /> },
                { path: ':id/preview', element: <EventPreviewPage /> },
                { path: ':id/edit', element: <EventEditPage /> },
                { path: ':id/create', element: <EventFullCreatePage /> },
                { path: ':id/seatmapper', element: <SeatMapper /> },
                { path: ':id/reservations', element: <EventReservationsPage /> },
                { path: ':id/event-stats', element: <EventStats /> },
              ],
            },
            {
              path: 'ambassador-events',
              children: [
                {
                  element: <Navigate to="/dashboard/evenementiel/ambassador-events/list" replace />,
                  index: true,
                },
                { path: 'list', element: <AmbassadorEvents /> },
                { path: ':id/event-dashborad', element: <AmbassadorEventDashboard /> },
              ],
            },
            {
              path: 'search-for-events',
              element: <SearchForEvents />,
            },
            {
              path: 'my-events',
              children: [
                {
                  element: <Navigate to="/dashboard/evenementiel/my-events/list" replace />,
                  index: true,
                },
                { path: 'list', element: <RpEventsList /> },
                { path: ':id/event-stats', element: <EventStats /> },
                { path: ':id/event-details', element: <EventDetailsPage /> },
              ],
            },
          ],
        },
        //restauration
        {
          path: 'restauration',
          children: [
            { element: <Navigate to="/dashboard/restauration/restaurants" replace />, index: true },
            {
              path: 'restaurants',
              children: [
                {
                  element: <Navigate to="/dashboard/restauration/restaurants/list" replace />,
                  index: true,
                },
                { path: 'list', element: <RessListPage /> },
                { path: 'new', element: <ResAddPage /> },
                { path: ':id/view', element: <ResViewPage /> },
                { path: ':id/edit', element: <ResEditPage /> },
              ],
            },
            { path: 'commandes', element: <OrdersListPage /> },
            { path: 'stores', element: <StoresPage /> },
            { path: 'store-list', element: <StoresListPage /> },
            { path: 'store-create', element: <StoreCreate /> },
            { path: 'orders', element: <EventsOrders /> },
            {
              path: 'applications',
              children: [
                {
                  element: <Navigate to="/dashboard/restauration/applications/list" replace />,
                  index: true,
                },
                { path: 'list', element: <ApplicationListPage /> },
                { path: 'zelty', element: <ZeltyPage /> },
              ],
            },
            {
              path: 'menus',
              children: [
                {
                  element: <Navigate to="/dashboard/restauration/menus/list" replace />,
                  index: true,
                },
                { path: 'list', element: <MenusListPage /> },
                { path: 'new', element: <MenuAdd /> },
                { path: 'menu-details/:id/view', element: <MenuView /> },
              ],
            },
            { path: 'tables-list', element: <WaitersTablesList /> },
          ],
        },
        //network
        {
          path: 'network',
          children: [
            { element: <Navigate to="/dashboard/network/invite-users" replace />, index: true },
            { path: 'invite-users', element: <InviteUsersPage /> },
            { path: 'invite-agents', element: <InviteAgentsPage /> },
            { path: 'invite-staff', element: <InviteStaffPage /> },
            { path: 'invitations-list', element: <InvitationsListPage /> },
            { path: 'promoters-tree', element: <PromotersTreePage /> },
            { path: 'staff-list', element: <StaffListPage /> },
            { path: 'ambassador-invites', element: <AmbasInvitesListPage invitingUser={true} /> },
            { path: 'promotion-requests', element: <OrgsRequests /> },
            { path: ':id/referral-details', element: <ReferralDetails /> },
            { path: ':id/owner-details', element: <OwnerDetails /> },
            { path: 'promotion-groups', element: <PromotionGroupsPage /> },
          ],
        },
        // user
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
            { path: 'billing', element: <BillingPage /> },
            { path: 'seatmapper', element: <SeatMapper /> },
            { path: 'onboarding', element: <></> },
            { path: 'mails', element: <UserMails /> },
            { path: 'contacts', element: <UserContacts /> },
            { path: ':id/mailedit', element: <MailEditor /> },
            // { path: 'delete-account', element: <DeleteAccount /> },
          ],
        },
        // more
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
        {
          path: 'invitations',
          children: [
            { element: <Navigate to="/dashboard/invitations/list" replace />, index: true },
            { path: 'list', element: <OrgsRequests /> },
            { path: ':id/referral-details', element: <ReferralDetails /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
        { path: 'delete-account', element: <DeleteAccount /> },
      ],
    },
    {
      path: '/',
      element: (
        <>
          <MainLayout />
          <HomePage />
        </>
      ),
      children: [
        { element: <Navigate to={pathHome} replace />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const CompleteSignup = Loadable(lazy(() => import('../pages/auth/completeSignup')));
const AcceptInvitationPage = Loadable(lazy(() => import('../pages/auth/acceptInvitationPage')));
const CompleteInvitation = Loadable(lazy(() => import('../pages/auth/completeInvitationPage')));

// DASHBOARD

// GENERAL

export const UserCardsPage = Loadable(lazy(() => import('../pages/z_more/UserCards')));
//Events
const EventAddPage = Loadable(lazy(() => import('../pages/events/eventAddPage')));
const EventEditPage = Loadable(lazy(() => import('../pages/events/eventEditPage')));
const EventDashboardPage = Loadable(lazy(() => import('../pages/events/eventDashboardPage')));
const EventPreviewPage = Loadable(lazy(() => import('../pages/events/eventPreviewPage')));
const EventFullCreatePage = Loadable(lazy(() => import('../pages/events/eventFullCreatePage')));
const EventViewPage = Loadable(lazy(() => import('../pages/events/eventViewPage')));
const EventsListPage = Loadable(lazy(() => import('../pages/events/eventsListPage')));
const RpEventsList = Loadable(lazy(() => import('../pages/events/rpEventsList')));
const EventReservationsPage = Loadable(lazy(() => import('../pages/events/eventReservationPage')));
const EventStats = Loadable(lazy(() => import('../pages/events/eventStats')));
const EventDetailsPage = Loadable(lazy(() => import('../pages/events/eventDetailsPage')));
const SearchForEvents = Loadable(lazy(() => import('../pages/events/searchForEvents')));
const ReferralDetails = Loadable(
  lazy(() => import('../pages/organisations/requests/referralDetails'))
);
const OwnerDetails = Loadable(lazy(() => import('../pages/organisations/requests/ownerDetails')));
const PromotionGroupsPage = Loadable(lazy(() => import('../pages/network/PromotionGroupsPage')));
const AmbassadorEvents = Loadable(lazy(() => import('../pages/events/ambassadorEventsPage')));
const AmbasInvitesListPage = Loadable(
  lazy(() => import('../pages/ambassador/invites/ambasInvitesListPage'))
);
const AmbassadorEventDashboard = Loadable(
  lazy(() => import('../pages/events/ambassadorEventDashboard'))
);
const EventsOrders = Loadable(lazy(() => import('../pages/events/eventsOrdersPage')));
const BillingPage = Loadable(lazy(() => import('../pages/BillingPage')));

// ORG
const OrgAddPage = Loadable(lazy(() => import('../pages/organisations/orgAddPage')));
const OrgEditPage = Loadable(lazy(() => import('../pages/organisations/orgEditPage')));
const OrgViewPage = Loadable(lazy(() => import('../pages/organisations/orgViewPage')));
const OrgsListPage = Loadable(lazy(() => import('../pages/organisations/orgsListPage')));
const OrgsRequests = Loadable(lazy(() => import('../pages/organisations/orgsRequestsPage')));

// Restaurant
const ResAddPage = Loadable(lazy(() => import('../pages/restaurants/orgAddPage')));
const ResEditPage = Loadable(lazy(() => import('../pages/restaurants/orgEditPage')));
const ResViewPage = Loadable(lazy(() => import('../pages/restaurants/orgViewPage')));
const RessListPage = Loadable(lazy(() => import('../pages/restaurants/orgsListPage')));
// const RessRequests = Loadable(lazy(() => import('../pages/restaurants/orgsRequestsPage')));
const MenusListPage = Loadable(lazy(() => import('../pages/restaurants/menus/menusListPage')));
const MenuAdd = Loadable(lazy(() => import('../pages/restaurants/menus/menuAdd')));
const MenuView = Loadable(lazy(() => import('../pages/restaurants/menus/menuView')));
const WaitersTablesList = Loadable(
  lazy(() => import('../pages/restaurants/waitersTables/waitersTablesList'))
);

//welcome page
const WelcomePage = Loadable(lazy(() => import('../pages/welcome/welcome')));
//Stores
const StoresPage = Loadable(lazy(() => import('../pages/stores/storesPage')));
//Stores list
const StoresListPage = Loadable(lazy(() => import('../pages/storeList/storesListPage')));
//Stores create
const StoreCreate = Loadable(lazy(() => import('../pages/stores/createStore')));
// Orders
const OrdersListPage = Loadable(lazy(() => import('../pages/orders/ordersPage')));
// Applications
const ApplicationListPage = Loadable(lazy(() => import('../pages/application/applicationPage')));
const ZeltyPage = Loadable(lazy(() => import('../pages/application/Apps/zelty')));

const InviteUsersPage = Loadable(lazy(() => import('../pages/network/inviteUsersPage')));
const InviteAgentsPage = Loadable(lazy(() => import('../pages/network/inviteAgentsPage')));
const InviteStaffPage = Loadable(lazy(() => import('../pages/network/inviteStaffPage')));
const InvitationsListPage = Loadable(lazy(() => import('../pages/network/invitationsListPage')));
const PromotersTreePage = Loadable(
  lazy(() => import('../sections/@dashboard/network/PromotersTree'))
);
const StaffListPage = Loadable(lazy(() => import('../sections/@dashboard/network/StaffList')));
const PromotionRequest = Loadable(
  lazy(() => import('../pages/organisations/requests/promotionRequest'))
);

/* const EcommerceProductEdit = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductEdit'))
); */

// INVOICE

// BLOG

// USER

const UserAccount = Loadable(lazy(() => import('../pages/UserAccount')));
const UserMails = Loadable(lazy(() => import('../pages/Mails')));
const EventAnalytics = Loadable(lazy(() => import('../sections/@dashboard/events/analytics/EventAnalytics')));
const AttendeesAnalytics = Loadable(lazy(() => import('../sections/@dashboard/events/analytics/AttendeesAnalytics')));
const UserContacts = Loadable(lazy(() => import('../sections/@dashboard/contacts/ContactsManager')));
const MailEditor = Loadable(lazy(() => import('../pages/MailEditor')));
const UserCreate = Loadable(lazy(() => import('../pages/z_more/UserCreate')));
const DeleteAccount = Loadable(lazy(() => import('../pages/DeleteAccount')));
const SeatMapper = Loadable(lazy(() => import('../pages/SeatMapper')));
// APP

const Calendar = Loadable(lazy(() => import('../pages/calendar/CalendarPage')));
const Kanban = Loadable(lazy(() => import('../pages/z_more/Kanban')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/PermissionDenied')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
