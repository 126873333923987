// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps, styled } from '@mui/material';

// ----------------------------------------------------------------------

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    transform: 'translate(14px, -9px) scale(0.75)',
    '&.Mui-focused': {
      transform: 'translate(14px, -9px) scale(0.75)',
    },
    '&.MuiFormLabel-filled': {
      transform: 'translate(14px, -9px) scale(0.75)',
    },
  },
  '& .MuiSelect-select': {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(14px, -9px) scale(0.75)',
  },
}));

type IProps = {
  name: string;
  children: React.ReactNode;
  label?: string;
};

type Props = IProps & Omit<TextFieldProps, 'children'>;

export default function RHFSelect({ name, children, label, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledTextField
          {...field}
          select
          label={label}
          fullWidth
          SelectProps={{
            native: true,
            displayEmpty: true,
          }}
          error={!!error}
          helperText={error?.message}
          {...other}
        >
          {children}
        </StyledTextField>
      )}
    />
  );
}